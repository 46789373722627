import {Link, useParams} from "react-router-dom";
import React, {ChangeEvent, useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import Loader from "../../../components/Loader";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import PageHeading from "../../../components/page-heading/PageHeading";
import {getContentUrl, getFormattedDate, getPriceInFormatted,} from "../../../utils/Common";
import TextField from "@mui/material/TextField";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {useAppDispatch} from "../../../app/hooks";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import ReadMore from "../../../components/read-more/ReadMore";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RejectParticipation from "./popups/RejectParticipation";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CloseIcon from "@mui/icons-material/Close";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AddIcon from "@mui/icons-material/Add";
import AddLink from "../../../components/package-tabs/popup/AddLink";
import AddComment from "../../../components/package-tabs/popup/AddComment";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

const styles = {
    leftBorder: {
        p: 2,
        borderLeft: 1,
        borderColor: "divider",
        height: "100%",
    },
    rightBorder: {
        p: 2,
        borderRight: 1,
        borderColor: "divider",
        height: "100%",
    },
    xBorder: {
        p: 2,
        borderRight: 1,
        borderLeft: 1,
        borderColor: "divider",
        height: "100%",
    },
};

const VendorEventDetails = () => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const termFile = `${getContentUrl()}/innov8/terms-and-conditions-for-use-of-website.pdf`;

    const [event, setEvent] = useState<any>({});
    const [vendorEventDetail, setVendorEventDetail] = useState<any>({});
    const [vendorResponded, setVendorResponded] = useState(false);
    const [openTermPopup, setOpenTermPopup] = useState(false);
    const dispatch = useAppDispatch();
    const [accepted, setAccepted] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState<any>("");
    const [isEventExpired, setIsEventExpired] = useState(false);
    const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false);
    const [headerButtons, setHeaderButtons] = useState<any>([]);

    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState<any>(null);
    const [selectedExistingDocumentIndex, setSelectedExistingDocumentIndex] = useState<any>(null);
    const [openAddLinkPopup, setOpenAddLinkPopup] = useState(false);
    const [openAddCommentPopup, setOpenAddCommentPopup] = useState(false);
    const [openAddCommentToExistingPopup, setOpenAddCommentToExistingPopup] = useState(false);
    const [link, setLink] = useState<any>(null);
    const [documents, setDocuments] = useState<any>([]);
    const [existingDocuments, setExistingDocuments] = useState<any>([]);

    const handleAddLink = () => {
        setOpenAddLinkPopup(true);
    }

    const handleDocumentUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files: any[] = [];
            Array.from(event.target.files).map((uploadedFile: any) => {
                files.push({document: uploadedFile, comment: ""});
            });
            if (documents.length > 0) {
                setDocuments([...documents, ...files]);
            } else {
                setDocuments(files);
            }
        }
    }

    const removeDocument = (index: number) => {
        let newDocuments: any = [...documents];
        newDocuments.splice(index, 1);
        setDocuments(newDocuments);
    }

    const removeFromExistingDocument = (index: number) => {
        let newDocuments: any = [...existingDocuments];
        newDocuments.splice(index, 1);
        setExistingDocuments(newDocuments);
    }

    const removeLink = () => {
        setLink(null);
    }

    const handleAddCommentToExisting = (index: number) => {
        setSelectedExistingDocumentIndex(index);
        setOpenAddCommentToExistingPopup(true);
    }

    const handleAddComment = (index: number) => {
        setSelectedDocumentIndex(index);
        setOpenAddCommentPopup(true);
    }

    const handleAddLinkPopupCallback = (callBack: any) => {
        if (callBack.event == "add") {
            setLink(callBack.data);
        }
        setOpenAddLinkPopup(false);
    }

    const handleAddCommentPopupCallback = (callBack: any) => {
        if (callBack.event == "add") {
            const allDocuments = [...documents];
            allDocuments[selectedDocumentIndex].comment = callBack.data;
            setDocuments(allDocuments);
        }
        setSelectedDocumentIndex(null);
        setOpenAddCommentPopup(false);
    }

    const handleAddCommentToExistingPopupCallback = (callBack: any) => {
        if (callBack.event == "add") {
            let allDocuments: any[] = [...existingDocuments];
            let currentDocument: any = {...allDocuments[selectedExistingDocumentIndex]};
            currentDocument['comment'] = callBack.data;
            allDocuments[selectedExistingDocumentIndex] = currentDocument;
            setExistingDocuments(allDocuments);
        }
        setSelectedExistingDocumentIndex(null);
        setOpenAddCommentToExistingPopup(false);
    }

    const loadEvent = () => {
        setIsLoading(true);
        api
            .get(`/procurement/event/details-for-vendor/${id}`)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                setEvent(response);
                setVendorEventDetail(response.eventVendor);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let errorFlag = false;

        const data: any = new FormData(e.target);

        const formData: any = new FormData();

        let answers: any = {};
        let vendorPrices: any = [];
        for (let [key, val] of data.entries()) {
            event?.eventPackages.map((eventPackage: any) => {
                eventPackage.packageItems.map((packageItem: any) => {
                    if (key == packageItem.id) {
                        vendorPrices.push({itemId: key, price: val});
                    }
                });
            });

            if (event?.questions.length > 0) {
                event?.questions.map((clientQuestion: any) => {
                    if (key == clientQuestion.id && val != "") {
                        if (answers.hasOwnProperty(key)) {
                            let allAnswers: any[] = answers[key];
                            allAnswers.push(val);
                            answers[key] = allAnswers;
                        } else {
                            answers[key] = [val];
                        }
                    }
                });
            }
        }

        if (event?.questions.length > 0) {
            event?.questions.map((clientQuestion: any) => {
                if (clientQuestion.mandatory && !answers.hasOwnProperty(clientQuestion.id)) {
                    errorFlag = true;
                    dispatch(
                        openSnackbar({
                            message: "Question: " + clientQuestion.question + " is mandatory",
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                }
            });
        }

        if (!errorFlag) {
            const questionData: any[] = [];
            for (let key in answers) {
                questionData.push({
                    questionId: key,
                    answers: answers[key]
                });
            }
            formData.append("questions", JSON.stringify(questionData));
        }

        if (vendorPrices.length == 0) {
            dispatch(
                openSnackbar({
                    message: "Please submit at least one item price",
                    backgroundColor: ERROR_COLOR,
                })
            );
        }

        if (errorFlag) {
            return;
        }


        Array.from(documents).map((selectedDocument: any) => {
            formData.append("documents[]", selectedDocument.document);
            formData.append("comments[]", selectedDocument.comment);
        });

        formData.append("description", data.get("description"));
        formData.append("data", JSON.stringify(vendorPrices));
        formData.append("eventVendorId", vendorEventDetail.id);

        setIsLoading(true);
        api.post(`/procurement/event/vendor-bidding/${id}`, formData)
            .then((response) => {
                return response.data;
            }).then((res) => {
            if (res) {
                setEvent({});
                setVendorEventDetail({});
                setExistingDocuments([]);
                setDocuments([]);
                dispatch(
                    openSnackbar({
                        message: "Successfully submitted the bid",
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                loadEvent();
            }
        }).catch((ex) => {
            dispatch(
                openSnackbar({
                    message: "Oops. Something went wrong, please try again",
                    backgroundColor: ERROR_COLOR,
                })
            );
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleHeaderButtonClick = (data: any) => {
        if (data === 'participate') {
            markParticipation();
        }

        if (data == 'reject') {
            setOpenRejectConfirmation(true);
        }
    }

    const handleRejectPopupCallback = (callBack: any) => {
        if (callBack.event == "reject") {
            const form = {
                reason: callBack.data
            };
            api.post(`/procurement/event/reject-participate/${vendorEventDetail.id}`, form).then((response) => {
                return response.data;
            }).then((res) => {
                setHeaderButtons([]);
                setEvent(res);
                setVendorEventDetail(res.eventVendor);
            }).catch((ex) => {
                dispatch(
                    openSnackbar({
                        message: ex?.response.data,
                        backgroundColor: ERROR_COLOR,
                    })
                );
                console.log(ex);
            }).finally(() => {
                setIsLoading(false);
            });
        }

        setOpenRejectConfirmation(false);
    }

    const markParticipation = () => {
        api.get(`/procurement/event/mark-participate/${vendorEventDetail.id}`).then((response) => {
            return response.data;
        }).then((res) => {
            setHeaderButtons([]);
            setEvent(res);
            setVendorEventDetail(res.eventVendor);
        }).catch((ex) => {
            dispatch(
                openSnackbar({
                    message: ex?.response.data,
                    backgroundColor: ERROR_COLOR,
                })
            );
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleAccept = () => {
        setIsLoading(true);
        const form = {termFile, eventVendorId: vendorEventDetail.id};
        api
            .post(`/procurement/event/accept-terms/${id}`, form)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                if (res) {
                    setIsLoading(false);
                    setOpenTermPopup(false);
                    loadEvent();
                }
            })
            .catch((ex) => {
                dispatch(
                    openSnackbar({
                        message: ex?.response.data,
                        backgroundColor: ERROR_COLOR,
                    })
                );
                console.log(ex);
                setIsLoading(false);
            });
    };

    const handleTermCheckbox = (event: any) => {
        const checked = event.target.checked;
        if (checked) {
            setAccepted(true);
        } else {
            setAccepted(false);
        }
    };

    useEffect(() => {
        if (Object.keys(event).length > 0) {
            const endDate = new Date(event.endDate);
            const today = new Date();
            endDate.setHours(23, 59, 59, 0);
            today.setHours(23, 59, 59, 0);
            if (today > endDate) {
                setIsEventExpired(true);
            }
        }
    }, [event]);

    useEffect(() => {
        if (Object.keys(vendorEventDetail).length > 0) {
            if (vendorEventDetail.status == "CREATED") {
                const buttons: any[] = [];
                buttons.push({
                    id: 'reject', label: 'Not Interested', variant: 'contained', color: 'error'
                });

                buttons.push({
                    id: 'participate', label: 'Participate', variant: 'contained'
                });
                setHeaderButtons(buttons);
            }

            if (!vendorEventDetail.termAccepted) {
                setOpenTermPopup(true);
            }

            if (vendorEventDetail.vendorDocuments.length > 0) {
                setExistingDocuments(vendorEventDetail.vendorDocuments);
            }

            vendorEventDetail.hasOwnProperty("eventVendorDetails") &&
            vendorEventDetail?.eventVendorDetails.length > 0 ? setVendorResponded(true) : setVendorResponded(false);
        }
    }, [vendorEventDetail]);

    useEffect(() => {
        if (vendorEventDetail.status !== "REJECTED") {
            const timer = setInterval(() => {
                const now = new Date();
                const endDate = new Date(event.endDate);
                const timeDifference = endDate.getTime() - now.getTime();

                if (timeDifference > 0) {
                    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                    setTimeRemaining({days, hours, minutes, seconds,});
                } else {
                    setIsEventExpired(true);
                    setTimeRemaining({days: 0, hours: 0, minutes: 0, seconds: 0});
                    clearInterval(timer);
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [event.endDate]);

    useEffect(() => {
        loadEvent();
    }, [id]);

    if (isLoading) {
        return <Loader/>;
    }

    const TimeBox = ({value, label}: any) => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "8px",
                border: "2px solid #8BB8E0",
                borderRadius: "8px",
                minWidth: "50px",
                minHeight: "60px",
                marginRight: "8px",
                backgroundColor: "background.paper",
            }}
        >
            <Typography sx={{fontSize: "20px", fontWeight: 600, color: "#8BB8E0"}}>
                {value}
            </Typography>
            <Typography sx={{fontSize: "12px", color: "#8BB8E0"}}>{label}</Typography>
        </Box>
    );

    return (
        <Box className={"common-space-container"}>
            {event.hasOwnProperty("eventPackages") && !openTermPopup && (
                <>
                    <PageHeading
                        title={`Event Details: ${event.name}`}
                        showButtons={true}
                        buttons={headerButtons}
                        handleHeaderButtonClick={handleHeaderButtonClick}
                    />
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box sx={{width: "60%"}}>
                                <Grid
                                    container
                                    sx={{
                                        backgroundColor: "primary.light",
                                        borderTop: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <Grid item xs>
                                        <Typography sx={styles.leftBorder}>Package Name</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.leftBorder}>Created On</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={{p: 2}}>Start Date</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={{p: 2}}>End Date</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>Responded?</Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    sx={{borderBottom: 1, borderColor: "divider"}}
                                >
                                    <Grid item xs>
                                        <Typography sx={styles.xBorder}>
                                            {event?.eventPackages[0].name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.xBorder}>
                                            {getFormattedDate(event?.startDate)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>
                                            {getFormattedDate(event?.startDate)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>
                                            {getFormattedDate(event?.endDate)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={styles.rightBorder}>
                                            {vendorResponded ? "Yes" : "No"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            {vendorEventDetail.status !== "REJECTED" && (
                                <Grid container
                                      sx={{width: "40%", display: "flex", justifyContent: "flex-end", alignItems: "center",}}
                                >
                                    <Box sx={{display: "flex"}}>
                                        <TimeBox value={timeRemaining.days} label="Days"/>
                                        <TimeBox value={timeRemaining.hours} label="Hours"/>
                                        <TimeBox value={timeRemaining.minutes} label="Mins"/>
                                        <TimeBox value={timeRemaining.seconds} label="Secs"/>
                                    </Box>
                                </Grid>
                            )}
                        </Box>

                        <Box sx={{py: 4}}>
                            <Box component={"form"} onSubmit={handleSubmit}>
                                {(vendorEventDetail.status == "PARTICIPATED" || vendorEventDetail.status == "SUBMITTED") && (
                                    <>
                                        <Box sx={{py: 2}}>
                                            <Box className={"space-between"}>
                                                <InputLabel sx={{pb: 1, fontSize: "0.8rem"}}>
                                                    Any Bidding Comments
                                                </InputLabel>
                                            </Box>
                                            <Box>
                                                <TextField
                                                    fullWidth size={"small"} variant={"outlined"} className={"inputBox"}
                                                    name="description"
                                                    defaultValue={vendorEventDetail.description || ""}
                                                    multiline rows={10}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "5px",
                                                            borderColor: 'primary.light',
                                                            fontSize: "0.8rem"
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{width: '60%'}}>
                                            <Box sx={{py: 2, width: '90%'}}>
                                                {event?.documents.length > 0 && (
                                                    <Box sx={{py: 2}}>
                                                        <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                                            Documents From Client
                                                            ({event?.link == "" ? event?.documents?.length : (event?.documents?.length + 1)})
                                                        </Typography>
                                                        {event?.link !== "" && (
                                                            <Box className={"space-between"}
                                                                 sx={{border: 1, p: 1, mt: 1}}>
                                                                <Box className={"vertically-center-aligned"}
                                                                     sx={{pr: 1}}>
                                                                    <InsertLinkIcon/>
                                                                </Box>
                                                                <Typography className={"vertically-center-aligned"}
                                                                            sx={{wordBreak: "break-all", px: 1}}>{event?.link}</Typography>
                                                                <Link to={event?.link} target={"_blank"}
                                                                      className={"vertically-center-aligned"}
                                                                      style={{paddingRight: 1}}>
                                                                    View
                                                                </Link>
                                                            </Box>
                                                        )}

                                                        {event.documents.map((clientDocument: any, docIndex: number) => (
                                                            <Box key={"client-document-" + docIndex}
                                                                 className={"space-between"}>
                                                                <Box className={"space-between"}
                                                                     sx={{border: 1, p: 1, mt: 1, width: '65%'}}>
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{pr: 1}}>
                                                                        <InsertDriveFileOutlinedIcon/>
                                                                    </Box>
                                                                    <Typography
                                                                        className={"vertically-center-aligned"}
                                                                        sx={{wordBreak: "break-all"}}>{clientDocument.name}</Typography>
                                                                    <Link to={clientDocument?.documentLink}
                                                                          target={"_blank"}
                                                                          className={"vertically-center-aligned"}
                                                                          style={{paddingRight: 1}}>
                                                                        View
                                                                    </Link>
                                                                </Box>
                                                                {clientDocument.comment !== "" && (
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{px: 1, width: '35%'}}>
                                                                        <ReadMore
                                                                            text={"Comment: " + clientDocument.comment}
                                                                            maxChars={20}/>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}
                                                <Box>
                                                    <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                                        Attach Documents
                                                    </Typography>

                                                    {link != null && (
                                                        <Box className={"space-between"} sx={{border: 1, p: 1, mt: 1}}>
                                                            <Box className={"vertically-center-aligned"} sx={{pr: 1}}>
                                                                <InsertLinkIcon/>
                                                            </Box>
                                                            <Typography className={"vertically-center-aligned"}
                                                                        sx={{wordBreak: "break-all"}}>{link}</Typography>
                                                            <Box className={"vertically-center-aligned"} sx={{pr: 1}}>
                                                                <CloseIcon sx={{cursor: 'pointer'}}
                                                                           onClick={removeLink}/>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    {existingDocuments.length > 0 && (
                                                        <Box>
                                                            {existingDocuments.map((existingDocument: any, docIndex: number) => (
                                                                <Box key={"existing-document-" + docIndex}
                                                                     className={"space-between"}>
                                                                    <Box className={"space-between"}
                                                                         sx={{border: 1, p: 1, mt: 1, width: '65%'}}>
                                                                        <Box className={"vertically-center-aligned"}
                                                                             sx={{pr: 1}}>
                                                                            <TextSnippetIcon/>
                                                                        </Box>
                                                                        <Typography
                                                                            className={"vertically-center-aligned"}
                                                                            sx={{wordBreak: "break-all"}}>{existingDocument.name}</Typography>
                                                                        <Box className={"vertically-center-aligned"}
                                                                             sx={{pr: 1}}>
                                                                            <CloseIcon sx={{cursor: 'pointer'}}
                                                                                       onClick={() => removeFromExistingDocument(docIndex)}/>
                                                                        </Box>
                                                                    </Box>
                                                                    {existingDocument.comment == "" ? (
                                                                        <Button variant={"text"}
                                                                                onClick={() => handleAddCommentToExisting(docIndex)}>
                                                                            Add Comment
                                                                        </Button>
                                                                    ) : (
                                                                        <Box className={"vertically-center-aligned"}
                                                                             sx={{px: 1, width: '35%'}}>
                                                                            <ReadMore text={existingDocument.comment}
                                                                                      maxChars={20}/>
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    )}
                                                    {documents.length > 0 && (
                                                        <Box>
                                                            {Array.from(documents).map((selectedDocument: any, docIndex: number) => {
                                                                return (
                                                                    <Box key={"document-" + docIndex}
                                                                         className={"space-between"}>
                                                                        <Box className={"space-between"}
                                                                             sx={{border: 1, p: 1, mt: 1, width: '70%'}}>
                                                                            <Box className={"vertically-center-aligned"}
                                                                                 sx={{pr: 1}}>
                                                                                <TextSnippetIcon/>
                                                                            </Box>
                                                                            <Typography
                                                                                className={"vertically-center-aligned"}
                                                                                sx={{wordBreak: "break-all"}}>{selectedDocument.document?.name}</Typography>
                                                                            <Box className={"vertically-center-aligned"}
                                                                                 sx={{pr: 1}}>
                                                                                <CloseIcon sx={{cursor: 'pointer'}}
                                                                                           onClick={() => removeDocument(docIndex)}/>
                                                                            </Box>
                                                                        </Box>
                                                                        {selectedDocument.comment == "" ? (
                                                                            <Button variant={"text"}
                                                                                    onClick={() => handleAddComment(docIndex)}>
                                                                                Add Comment
                                                                            </Button>
                                                                        ) : (
                                                                            <Box className={"vertically-center-aligned"}
                                                                                 sx={{px: 2, width: '30%'}}>
                                                                                <ReadMore
                                                                                    text={selectedDocument.comment}
                                                                                    maxChars={20}/>
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                )
                                                            })}
                                                        </Box>
                                                    )}
                                                    <Box sx={{py: 2}}>
                                                        <Button component="label"
                                                                sx={{borderColor: '#6C63FF', color: '#6C63FF'}}
                                                                variant={"outlined"} endIcon={<AddIcon/>}>
                                                            {documents.length > 0 ? "Add More Files" : "Add Files"}
                                                            <input name="documents" onChange={handleDocumentUpload}
                                                                   hidden
                                                                   multiple type="file"/>
                                                        </Button>
                                                        <Button sx={{borderColor: '#6C63FF', color: '#6C63FF', ml: 2}}
                                                                onClick={handleAddLink} variant={"outlined"}
                                                                endIcon={<AddIcon/>}>
                                                            Add Link
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {event.questions.length > 0 && (
                                            <Box sx={{width: '60%'}}>
                                                <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                                    Please Answer This
                                                </Typography>
                                                <Typography sx={{py: 1, fontSize: '12px'}}>
                                                    By responding to all the questions your partner will have a better
                                                    understanding of your services. Mandatory questions are marked with
                                                    *.
                                                </Typography>
                                                {event.questions.map((clientQuestion: any, questionIndex: number) => {
                                                        let submittedAnswers: any = [];
                                                        if (vendorEventDetail.vendorQuestions.length > 0) {
                                                            vendorEventDetail.vendorQuestions.map((vendorQuestion: any) => {
                                                                if (vendorQuestion.eventQuestionnaireId == clientQuestion.id) {
                                                                    submittedAnswers = vendorQuestion.answers;
                                                                }
                                                            });
                                                        }
                                                        return (
                                                            <Box key={"question-" + questionIndex}>
                                                                <Typography sx={{py: 2}}>
                                                                    {questionIndex + 1}. {clientQuestion.question}
                                                                    {clientQuestion.mandatory && (
                                                                        <sup>*</sup>
                                                                    )}
                                                                </Typography>
                                                                <Box sx={{width: '60%', px: 2}}>
                                                                    {clientQuestion.questionType == "radio" ? (
                                                                        <FormControl>
                                                                            <RadioGroup
                                                                                row
                                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                name={clientQuestion.id}
                                                                                defaultValue={submittedAnswers.length > 0 ? submittedAnswers[0] : ""}
                                                                            >
                                                                                {
                                                                                    clientQuestion.answers.map((option: any, index: number) => (
                                                                                        <FormControlLabel
                                                                                            key={`radio-button-form-${index}-${clientQuestion.id}`}
                                                                                            value={option}
                                                                                            control={<Radio/>}
                                                                                            label={option}/>
                                                                                    ))
                                                                                }
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    ) : (
                                                                        clientQuestion.questionType == "checkbox" ? (
                                                                            clientQuestion.answers.map((option: any, answerIndex: number) => {
                                                                                return (
                                                                                    <FormControlLabel
                                                                                        key={`checkbox-form-${clientQuestion.id}-` + answerIndex}
                                                                                        label={option}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={submittedAnswers.length > 0 && submittedAnswers.some((obj: any) => {
                                                                                                    return obj === option
                                                                                                })}
                                                                                                onChange={(e: any) => {
                                                                                                    if (e.target.checked) {
                                                                                                        submittedAnswers.push(option);
                                                                                                    } else {
                                                                                                        submittedAnswers.splice(submittedAnswers.indexOf(option), 1);
                                                                                                    }
                                                                                                }}
                                                                                                name={clientQuestion.id}
                                                                                                value={option}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <TextField
                                                                                fullWidth
                                                                                size={"small"}
                                                                                variant={"outlined"}
                                                                                className={"inputBox"}
                                                                                name={clientQuestion.id}
                                                                                required={clientQuestion.mandatory}
                                                                                defaultValue={submittedAnswers.length > 0 ? submittedAnswers[0] : ""}
                                                                                InputProps={{
                                                                                    style: {
                                                                                        borderRadius: "5px",
                                                                                        borderColor: 'primary.light',
                                                                                        fontSize: "0.8rem",
                                                                                    },
                                                                                }}
                                                                            />
                                                                        )
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                )}
                                            </Box>
                                        )}
                                    </>
                                )}
                                <Box sx={{py: 2}}>
                                    <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                        Package Item Details
                                    </Typography>
                                    {event?.eventPackages.map(
                                        (eventPackage: any, index: number) => {
                                            return (
                                                <Box key={"event-package-" + index}>
                                                    <Typography variant={"h2"} sx={{pb: 2}}>
                                                        {event?.eventPackage?.name}
                                                    </Typography>
                                                    <Grid
                                                        container
                                                        sx={{
                                                            backgroundColor: "primary.light",
                                                            borderTop: 1,
                                                            borderColor: "divider",
                                                        }}
                                                    >
                                                        <Grid item xs={1.3}>
                                                            <Typography
                                                                sx={{
                                                                    p: 2,
                                                                    borderLeft: 1,
                                                                    borderColor: "divider",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                Item Category
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1.3}>
                                                            <Typography sx={{p: 2, fontWeight: 700}}>
                                                                Selected Item
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2.4}>
                                                            <Typography sx={{p: 2, fontWeight: 700}}>
                                                                Sku Description
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Typography sx={{p: 2, fontWeight: 700}}>
                                                                UOM
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <Typography sx={{p: 2, fontWeight: 700}}>
                                                                Quantity
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1.4}>
                                                            <Typography sx={{p: 2, fontWeight: 700}}>
                                                                Rate Per UOM
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1.4}>
                                                            <Typography sx={{p: 2, fontWeight: 700}}>
                                                                Total
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                sx={{
                                                                    p: 2,
                                                                    borderRight: 1,
                                                                    borderColor: "divider",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                Your Bid (Per Unit)
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {eventPackage.packageItems.map(
                                                        (packageItem: any, index: number) => {
                                                            let price: any = "";
                                                            ((vendorEventDetail.status == "PARTICIPATED" || vendorEventDetail.status == "SUBMITTED") && vendorEventDetail?.eventVendorDetails.length > 0) && vendorEventDetail?.eventVendorDetails.map((eventDetail: any) => {
                                                                if (eventDetail.packageItemId == packageItem.id) {
                                                                    price = eventDetail.price;
                                                                }
                                                            });
                                                            return (
                                                                <Grid
                                                                    key={"vendor-event-details-" + index}
                                                                    container
                                                                    sx={{borderBottom: 1, borderColor: "divider"}}
                                                                >
                                                                    <Grid item xs={1.3}>
                                                                        <Typography sx={styles.xBorder}>
                                                                            {
                                                                                packageItem?.itemDetail?.itemCategory
                                                                                    ?.name
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1.3}>
                                                                        <Typography sx={styles.rightBorder}>
                                                                            <Link
                                                                                to={`/vendor/catalogue/skus/details/${packageItem.itemDetail.sku.id}`}
                                                                            >
                                                                                {packageItem?.itemDetail.sku.name}
                                                                            </Link>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2.4}>
                                                                        <Box sx={styles.rightBorder}>
                                                                            <ReadMore
                                                                                text={
                                                                                    packageItem?.itemDetail?.sku.description
                                                                                }
                                                                                maxChars={200}
                                                                                className={"sku-desc"}
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Typography sx={styles.rightBorder}>
                                                                            {packageItem?.itemDetail?.sku?.uom}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1.2}>
                                                                        <Typography sx={styles.rightBorder}>
                                                                            {packageItem?.totalQuantity}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1.4}>
                                                                        <Typography sx={styles.rightBorder}>
                                                                            {getPriceInFormatted(
                                                                                packageItem?.itemDetail?.targetPrice,
                                                                                ""
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1.4}>
                                                                        <Typography sx={styles.rightBorder}>
                                                                            {getPriceInFormatted(
                                                                                packageItem?.totalQuantity *
                                                                                packageItem?.itemDetail?.targetPrice,
                                                                                ""
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Box sx={styles.rightBorder}>
                                                                            {(vendorEventDetail.status == "PARTICIPATED" || vendorEventDetail.status == "SUBMITTED") && (
                                                                                <TextField
                                                                                    fullWidth
                                                                                    size={"small"}
                                                                                    variant={"outlined"}
                                                                                    className={"inputBox"}
                                                                                    type={"number"}
                                                                                    id={packageItem?.id}
                                                                                    name={packageItem?.id}
                                                                                    placeholder={"Price"}
                                                                                    defaultValue={price}
                                                                                    onWheel={(e: any) => e.target.blur()}
                                                                                    InputProps={{
                                                                                        style: {
                                                                                            borderRadius: "5px",
                                                                                            borderColor: "primary.light",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }
                                                    )}
                                                </Box>
                                            );
                                        }
                                    )}
                                </Box>
                                {!isEventExpired && (vendorEventDetail.status == "PARTICIPATED" || vendorEventDetail.status == "SUBMITTED") && (
                                    <Box sx={{py: 2}} className={"right-align-content"}>
                                        <Button variant={"contained"} type={"submit"}>
                                            Submit Bids
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

            {openRejectConfirmation && (
                <RejectParticipation openPopup={openRejectConfirmation}
                                     handlePopupCallback={handleRejectPopupCallback}/>
            )}

            {openAddLinkPopup && (
                <AddLink openPopup={openAddLinkPopup} handleAddLinkPopupCallback={handleAddLinkPopupCallback}/>
            )}
            {openAddCommentPopup && (
                <AddComment openPopup={openAddCommentPopup}
                            handleAddCommentPopupCallback={handleAddCommentPopupCallback}/>
            )}
            {openAddCommentToExistingPopup && (
                <AddComment openPopup={openAddCommentToExistingPopup}
                            handleAddCommentPopupCallback={handleAddCommentToExistingPopupCallback}/>
            )}

            {openTermPopup && (
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box sx={{p: 2}}>
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant={"h5"} sx={{px: 0.4}}>
                                Accept Terms and Conditions
                            </Typography>
                        </DialogTitle>
                        <DialogContent sx={{pb: 0}}>
                            <DialogContentText id="alert-dialog-description">
                                <Box>
                                    <Typography sx={{lineHeight: 1.24, px: 0.4}}>
                                        All documents and information that follow are the sole
                                        property of the organiser (Space Matrix), and can not be
                                        copied, distributed or used for purposes other than the
                                        request or auction where you have been invited. By accepting
                                        to participate in the bidding process you confirm to
                                        maintain the confidentiality. You also understand and agree
                                        that your participation to the request or auction does not
                                        commit Space Matrix in anyway.
                                    </Typography>
                                    <Box sx={{pt: 2}}>
                                        <Typography className={"vertically-center-aligned"}>
                                            <ArticleOutlinedIcon color={"primary"}/>
                                            <Link
                                                style={{marginLeft: 10, color: "primary"}}
                                                to={termFile}
                                                target={"_blank"}
                                            >
                                                View Terms and Conditions
                                            </Link>
                                        </Typography>
                                    </Box>

                                    <FormControlLabel
                                        sx={{px: 0.2}}
                                        label={
                                            <Typography sx={{color: "common.black"}}>
                                                I agree to Space Matrix terms of use and privacy policy
                                            </Typography>
                                        }
                                        control={
                                            <Checkbox
                                                onChange={(e: any) => handleTermCheckbox(e)}
                                                name={"terms"}
                                                value={1}
                                            />
                                        }
                                    />
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{px: 3}}>
                            <Button
                                onClick={() => handleAccept()}
                                color="primary"
                                variant={"contained"}
                                disabled={!accepted}
                            >
                                Agree
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            )}
        </Box>
    );
};

export default VendorEventDetails;
