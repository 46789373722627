import React, {useEffect, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import Loader from "../../Loader";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {api} from "../../../utils/Axios";
import {debounce} from "@mui/material/utils";
import CloseIcon from "@mui/icons-material/Close";
import {useAppDispatch} from "../../../app/hooks";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import {getStyle} from "../../../utils/ModalUtils";

interface Vendor {
    id: string;
    name: string;
}

const MapProjectPopup = (props: any) => {
    console.log(props);
    const style = getStyle(60, 70,0,0);
    const today = dayjs()
    const nextWeek = today.add(7, 'day')

    const dispatch = useAppDispatch();
    const [size, setSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(1);
    const [open, setOpen] = useState(false);
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [radioValue, setRadioValue] = useState("");
    const [selectedVendor, setSelectedVendor] = useState<any>({});
    const [vendors, setVendors] = useState([]);
    
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<readonly Vendor[]>([]);
    const [value, setValue] = useState<Vendor | null>(null);

    

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
            loadProjects();
        }
    }, [props.openPopup]);

    const fetchProjects = useMemo(
        () =>
            debounce(
                (query: string, callback: (results?: readonly Vendor[]) => void) => {
                    api
                        .get(`/catalogue/projects`)
                        .then((response) => response.data)
                        .then((data) => callback(data))
                        .catch(() => callback([]));
                },
                400
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            //setOptions(value ? [value] : []);
            return undefined;
        }
        if (inputValue.length >= 3) {
            fetchProjects(inputValue, (results?: readonly Vendor[]) => {
                if (active) {
                    let newOptions: readonly Vendor[] = [];

                    if (value) {
                        //newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchProjects]);

    const handleMapProject = () => {
        if (Object.keys(selectedVendor).length === 0) {
            dispatch(
                openSnackbar({
                    message: "Please select project to proceed",
                    backgroundColor: ERROR_COLOR,
                })
            );
            return;
        }

        const projectMapData = {
            projectId: props.projectId,
            bigFishProjectId: selectedVendor.id,
            customerName: selectedVendor.customerName,
            bigFishCustomerId: selectedVendor.customerId,
        };

        
        if (Object.keys(selectedVendor).length > 0) {
            setIsLoading(true);
            api
                .put(`procurement/project/map-big-fish-project/${props.projectId}`, projectMapData)
                .then((response) => response.data)
                .then((response: any) => {
                    if(response?.bigFishProjectId) {
                        props.updateProjectDetails(response);
                    }
                    props.handlePopupCallBack({event: "close", prPackage: "event"});

                    dispatch(
                        openSnackbar({
                            message: "Project mapped successfully. Now you can approve the estimation.",
                            backgroundColor: SUCCESS_COLOR,
                        })
                    );

                    setIsLoading(false);
                    return response;
                })
                .catch((ex) => {
                    console.log(ex);
                    dispatch(
                        openSnackbar({
                            message: "Something went wrong!",
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                    setIsLoading(false);
                });
        }
    };

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallBack({event: "close"});
    };

    const loadProjects = () => {
        setIsLoading(true);
        api
            .get(`/catalogue/projects`)
            .then((response) => response.data)
            .then((response: any) => {
                setVendors(response);
                let newOptions: readonly Vendor[] = [];

                if (response) {
                    newOptions = [...newOptions, ...response];
                }

                setOptions(newOptions);

                setIsLoading(false);
                console.log(newOptions);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Map Project
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            {isLoading &&
                                <Loader/>
                            }

                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            id="vendor-autocomplete"
                                            sx={{width: 300}}
                                            getOptionLabel={(option: Vendor) => option.name}
                                            filterOptions={(x) => x}
                                            options={options}
                                            autoComplete
                                            includeInputInList
                                            filterSelectedOptions
                                            value={value}
                                            noOptionsText="No Projects"
                                            onChange={(event: any, newValue: any) => {
                                                setValue(newValue);
                                                if(newValue == null) {
                                                    setSelectedVendor({});
                                                } else {
                                                    setSelectedVendor(newValue);
                                                }
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select a project"
                                                    fullWidth
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <Box sx={{fontWeight: "regular"}}>
                                                        {option.name}
                                                    </Box>
                                                </li>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{marginTop: "60px"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        {Object.keys(selectedVendor).length > 0 && <Box sx={{display: "flex", flexDirection: "column", border: "1px solid #999", verticalAlign: "center", lineHeight: "50px"}}>
                                            <Box sx={{display: "flex", flexDirection: "row", borderBottom: "1px solid #999", height: "50px"}}>
                                                <Typography sx={{minWidth: "170px", padding:"0 20px", borderRight: "1px solid #999", lineHeight: "50px", fontWeight: "600"}}>Project Name </Typography> <Typography sx={{padding:"0 20px", lineHeight: "50px"}}>{selectedVendor.name}</Typography>
                                            </Box>
                                            <Box sx={{display: "flex", flexDirection: "row", borderBottom: "1px solid #999", height: "50px"}}>
                                                <Typography sx={{minWidth: "170px", padding:"0 20px", borderRight: "1px solid #999", lineHeight: "50px", fontWeight: "600"}}>Customer Name </Typography> <Typography sx={{padding:"0 20px", lineHeight: "50px"}}>{selectedVendor?.customerName}</Typography>
                                            </Box>
                                            <Box sx={{display: "flex", flexDirection: "row", borderBottom: "1px solid #999", height: "50px"}}>
                                                <Typography sx={{minWidth: "170px", padding:"0 20px", borderRight: "1px solid #999", lineHeight: "50px", fontWeight: "600"}}>Studio Name </Typography> <Typography sx={{padding:"0 20px", lineHeight: "50px"}}>{selectedVendor?.studioName}</Typography>
                                            </Box>
                                            <Box sx={{display: "flex", flexDirection: "row", height: "50px"}}>
                                                <Typography sx={{minWidth: "170px", padding:"0 20px", borderRight: "1px solid #999", lineHeight: "50px", fontWeight: "600"}}>Project Status </Typography> <Typography sx={{padding:"0 20px", lineHeight: "50px"}}>{selectedVendor?.projectStatus}</Typography>
                                            </Box>
                                        </Box>}
                                    </Grid>
                                </Grid>
                            </Box>


                        </Box>
                    </Box>
                    <Box sx={style.footer}>
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                            <Grid item>
                                <Box>
                                    <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}} onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        sx={{fontSize: "0.8rem"}}
                                        onClick={handleMapProject}
                                    >
                                        {"Add Project"}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default MapProjectPopup;