import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material";
import {getPriceInFormatted} from "../../utils/Common";

const SkuTable = (props: any) => {
    const {skuItems} = props;
    return (
        <Box
            sx={{py: 1}}
            width="100%"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <TableContainer
                component="div"
                sx={{
                    mx: 0.5,
                    border: "1px solid",
                    borderColor: "divider",
                }}
            >
                <Table aria-label="simple table">
                    <TableHead sx={{}}>
                        <TableRow sx={{backgroundColor: "info.contrastText", borderBottom: 1, borderColor: 'divider'}}>
                            <TableCell
                                align="left"
                                sx={{py: 1, border: 0, px: 0}}
                            >
                                <Typography
                                    sx={{borderRight: 1, borderColor: 'common.white', p: 0.5, fontWeight: '600', fontSize: "13px"}}>
                                    Sku Name
                                </Typography>
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{py: 1, border: 0, px: 0}}
                            >
                                <Typography
                                    sx={{borderRight: 1, borderColor: 'common.white', p: 0.5, fontWeight: '600', fontSize: "13px"}}>
                                    ID
                                </Typography>
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{py: 1, border: 0, px: 0}}
                            >
                                <Typography
                                    sx={{borderRight: 1, borderColor: 'common.white', p: 0.5, fontWeight: '600', fontSize: "13px"}}>
                                    Qty
                                </Typography>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{py: 1, border: 0, px: 0}}
                            >
                                <Typography
                                    sx={{borderRight: 1, borderColor: 'common.white', py: 0.5, fontWeight: '600', fontSize: "13px"}}>
                                    UOM
                                </Typography>
                            </TableCell>
                            {!props.showUpdatedCharges &&
                                <TableCell
                                    colSpan={2}
                                    align="center"
                                    sx={{py: 1, border: 0, px: 0}}
                                >
                                    <Typography
                                        sx={{borderRight: 1, borderColor: 'common.white', py: 0.5, fontWeight: '600', fontSize: "13px"}}>
                                        COGS
                                    </Typography>
                                </TableCell>
                            }
                            <TableCell
                                colSpan={2}
                                align="center"
                                sx={{py: 1, border: 0, px: 0}}
                            >
                                <Typography
                                    sx={{borderRight: 1, borderColor: 'common.white', py: 0.5, fontWeight: '600', fontSize: "13px"}}>
                                    GSV
                                </Typography>
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                align="center"
                                sx={{py: 1, border: 0, px: 0}}
                            >
                                <Typography
                                    sx={{py: 0.5, fontWeight: '600', fontSize: "13px"}}>
                                    NSV
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{backgroundColor: '#ececed'}}>
                            <TableCell sx={{p: 0.4}} colSpan={4}/>
                            {!props.showUpdatedCharges &&
                                <>
                                    <TableCell sx={{p: 0.4, fontSize: '11px', textAlign: 'center'}}>
                                        Unit Rate
                                    </TableCell>
                                    <TableCell sx={{p: 0.4, fontSize: '11px', textAlign: 'center'}}>
                                        Amount
                                    </TableCell>
                                </>
                            }
                            <TableCell sx={{p: 0.4, fontSize: '11px', textAlign: 'center'}}>
                                Unit Rate
                            </TableCell>
                            <TableCell sx={{p: 0.4, fontSize: '11px', textAlign: 'center'}}>
                                Amount
                            </TableCell>
                            <TableCell sx={{p: 0.4, fontSize: '11px', textAlign: 'center'}}>
                                Amount
                            </TableCell>
                            <TableCell sx={{p: 0.4, fontSize: '11px', textAlign: 'center'}}>
                                %age
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {skuItems && skuItems.length > 0 ? (
                            skuItems.map((skuItem: any, index: number) => {
                                let sellingPrice = skuItem.sellingPrice + skuItem.gsvDomainChargesPerUnit?.total;

                                const nsvPercentage = ((sellingPrice - skuItem.targetPrice) / sellingPrice) * 100;
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={{px: 0.4, py: 1.5}}>{skuItem.sku.name}</TableCell>
                                        <TableCell align="left" sx={{px: 0.4, py: 1.5}}>{skuItem.sku.code}</TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{fontSize: "11px", fontWeight: "600", px: 0.4, py: 1.5}}
                                        >
                                            {skuItem.totalQuantity}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{fontSize: "11px", fontWeight: "600", px: 0.4, py: 1.5}}
                                        >
                                            {skuItem.sku.uom}
                                        </TableCell>
                                        {!props.showUpdatedCharges &&
                                            <>
                                                <TableCell
                                                    align="center"
                                                    sx={{fontSize: "10px", fontWeight: "600", px: 0.4, py: 1.5}}
                                                >
                                                    {getPriceInFormatted(
                                                        skuItem.targetPrice,
                                                        ""
                                                    ) || 0}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{fontSize: "10px", fontWeight: "600", px: 0.4, py: 1.5}}
                                                >
                                                    {getPriceInFormatted(
                                                        skuItem.targetPrice * skuItem.totalQuantity,
                                                        ""
                                                    ) || 0}
                                                </TableCell>
                                            </>
                                        }
                                        <TableCell
                                            align="center"
                                            sx={{fontSize: "10px", fontWeight: "600", px: 0.4, py: 1.5, borderLeft: 1, borderColor: 'divider', backgroundColor: '#F9F9F9'}}
                                        >
                                            {getPriceInFormatted(
                                                sellingPrice,
                                                ""
                                            ) || 0}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{fontSize: "10px", fontWeight: "600", px: 0.4, py: 1.5, borderRight: 1, borderColor: 'divider', backgroundColor: '#F9F9F9'}}
                                        >
                                            {getPriceInFormatted(
                                                sellingPrice * skuItem.totalQuantity,
                                                ""
                                            ) || 0}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{fontSize: "10px", fontWeight: "600", px: 0.4, py: 1.5}}
                                        >
                                            {getPriceInFormatted(
                                                (sellingPrice * skuItem.totalQuantity) - (skuItem.targetPrice * skuItem.totalQuantity),
                                                ""
                                            ) || 0}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{fontSize: "10px", fontWeight: "600", px: 0.4, py: 1.5}}
                                        >
                                            {!isNaN(nsvPercentage) ? nsvPercentage.toFixed(2) : Number.parseFloat("0").toFixed(2)}%
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <Typography>Nothing found</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SkuTable;
