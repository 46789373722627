import {getStyle} from "../../../utils/ModalUtils";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../../../app/hooks";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {SUCCESS_COLOR} from "../../../constants";
import {api} from "../../../utils/Axios";
import {Box, Modal, Typography} from "@mui/material";
import Loader from "../../Loader";
import CloseIcon from "@mui/icons-material/Close";
import BuildForm from "../../input-form/BuildForm";

const EditEstimationRevision = (props: any) => {
    const style = getStyle(30, 40, 0, 0);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch: any = useAppDispatch();

    const handleClose = (data: any) => {
        setOpen(false);
        props.handleEditPopupCallBack(data);
    }

    const onSubmitHandler = (form: any, callback: any) => {
        setIsLoading(true);
        api.post(`/procurement/estimation/revision/edit-name/${props.revision.id}`, form).then((response) => {
            return response.data;
        }).then(res => {
            dispatch(
                openSnackbar({
                    message: "Revision updated successfully",
                    backgroundColor: SUCCESS_COLOR,
                })
            );
            setIsLoading(false);
            handleClose({event: 'save', revision: res});
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
        callback();
    }

    useEffect(() => {
        if (props.openPopup == true) {
            formFields.map((formField: any) => {
                if (formField.name == 'name') {
                    formField.value = props.revision.name;
                }
            })
            setOpen(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            open={open}
            onClose={() => handleClose({event: 'close'})}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                {isLoading &&
                    <Loader/>
                }

                <Box sx={style.header} style={{height: "15%"}}>
                    <Box
                        sx={style.headerContainer}>
                        <Box>
                            <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                Create New Estimation Revision
                            </Typography>
                        </Box>
                        <Box sx={{ml: 'auto'}}>
                            <CloseIcon sx={{cursor: "pointer"}} onClick={() => handleClose({event: 'close'})}/>
                        </Box>
                    </Box>
                </Box>
                <Box className={"modal-body"} sx={style.body}>
                    <Box sx={style.bodyContainer}>
                        <Box className={"common-space-container"}>
                            <BuildForm
                                buttons={[
                                    {
                                        type: 'submit',
                                        title: 'Save',
                                        permissions: ['edit-vendors']
                                    },
                                ]}
                                formArr={formFields}
                                onSubmit={onSubmitHandler}
                                handleFormChange={(form: any) => console.log(form)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}


const formFields = [
    {
        element: "input",
        label: "Estimation Name",
        placeholder: "Estimation Name",
        name: "name",
        type: "text",
        required: true,
        fullWidth: true
    }
];

export default EditEstimationRevision;