import {Box, Button, FormControl, Grid, InputLabel, Modal, TextField, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";
import { getStyle } from "../../../utils/ModalUtils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { openSnackbar } from "../../../components/common/features/snackbarSlice";
import { ERROR_COLOR, RC_PRICE_UPDATED_FAILURE, RC_PRICE_UPDATED_SUCCESSFULLY, SUCCESS_COLOR } from "../../../constants";
import Loader from "../../../components/Loader";
import { updateRateContractPrice } from "../features/actions";


const VendorRateContractForm = (props: any) => {
    const style = getStyle(25, 40,0,0);
    const [open, setOpen] = useState(props.openPopup);
    const isUpdatingCogs = useAppSelector((state) => state.vendor.loading)
    const [formData, setFormData] = useState<any>({cogs: "", nsv: ""});
    const dispatch = useAppDispatch();
    const handleClose = (event: string) => {
        setOpen(false);
        props.handleCloseCogsModal(event);
    }

    const handleOnCogsChange = (event: any) => {
        const {name, value} = event.target;
        setFormData((prevState: any) => ({...prevState, [name]: value}));
    };

    const handleSaveCogs = async (e: any) => {
        e.preventDefault();
        const response = await dispatch(updateRateContractPrice({skuId: props.skuId, rcPayload: formData}));
        if (response.payload) {
            dispatch(
                openSnackbar({
                    message: RC_PRICE_UPDATED_SUCCESSFULLY,
                    backgroundColor: SUCCESS_COLOR,
                })
            );
            handleClose('save');
        } else {
            openSnackbar({
                message: RC_PRICE_UPDATED_FAILURE,
                backgroundColor: ERROR_COLOR,
            })
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose('close')}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main} style={{minHeight: '100px'}} component={"form"} onSubmit={e => handleSaveCogs(e)}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header} style={{height: "20%"}}>
                        <Box sx={style.headerContainer}>
                            <Typography sx={{fontSize: '15px', fontWeight: 700}}>
                                Update RC Price
                            </Typography>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon
                                    sx={{cursor: "pointer"}}
                                    onClick={() => handleClose("close")}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body} style={{height: '60%'}}>
                        <Box sx={style.bodyContainer}>
                            {isUpdatingCogs && <Loader />}
                            <Box sx={{}}>
                                <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                    Rate Contract Price
                                </InputLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        required
                                        type={"number"}
                                        size={"small"}
                                        variant={"outlined"}
                                        className={"inputBox"}
                                        name="cogs"
                                        value={formData.cogs}
                                        onWheel={(e: any) => e.target.blur()}
                                        onChange={handleOnCogsChange}
                                        InputProps={{
                                            style: {
                                                borderRadius: "5px",
                                                borderColor: 'primary.light',
                                                fontSize: "0.8rem",
                                            },
                                            inputProps: {min: 0},
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{}}>
                                <InputLabel required={true} sx={{py: 1, fontSize: "0.8rem"}}>
                                    NSV
                                </InputLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        required
                                        type={"number"}
                                        size={"small"}
                                        variant={"outlined"}
                                        className={"inputBox"}
                                        name="nsv"
                                        value={formData.nsv}
                                        onWheel={(e: any) => e.target.blur()}
                                        onChange={handleOnCogsChange}
                                        InputProps={{
                                            style: {
                                                borderRadius: "5px",
                                                borderColor: 'primary.light',
                                                fontSize: "0.8rem",
                                            },
                                            inputProps: {min: 0},
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={style.footer} style={{height: '20%'}} className="sticky-footer">
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                            <Grid item>
                                <Button variant="outlined" onClick={() => handleClose('close')}
                                        sx={{marginRight: '4px'}}>
                                    Cancel
                                </Button>
                                <Button variant="contained" type={"submit"} sx={{marginLeft: '4px'}}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default VendorRateContractForm;