import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";

const ProjectDetailsRow = (props: any) => {
    return (
        <Box>
            <Typography variant={"h4"} sx={{pb: 1}}>Project Details</Typography>
            <TableContainer
                component={"div"}
                sx={{
                    border: "1px solid",
                    borderColor: "divider",
                    borderBottom: 0,
                }}
            >
                <Table
                    sx={{minWidth: 650}}
                    aria-label="project details table"
                >
                    <TableHead sx={{backgroundColor: "info.contrastText"}}>
                        <TableRow>
                            <TableCell sx={{fontWeight: 600}}>Project Name</TableCell>
                            <TableCell sx={{fontWeight: 600}}>Project Studio</TableCell>
                            <TableCell sx={{fontWeight: 600}}>Location</TableCell>
                            <TableCell sx={{fontWeight: 600}}>Carpet Area</TableCell>
                            <TableCell sx={{fontWeight: 600}}>Estimation Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{textTransform: "capitalize"}}>
                                {props?.project?.name || "NA"}
                            </TableCell>
                            <TableCell sx={{textTransform: "capitalize"}}>
                                {props?.project?.projectStudio || "NA"}
                            </TableCell>
                            <TableCell sx={{textTransform: "capitalize"}}>
                                {props?.project?.location || "NA"}
                            </TableCell>
                            <TableCell sx={{textTransform: "capitalize"}}>
                                {props?.project?.carpetArea + " Sqft" || "NA"}
                            </TableCell>
                            <TableCell sx={{textTransform: "capitalize"}}>
                                <Typography
                                    sx={{
                                        textTransform: "capitalize",
                                        border: 1,
                                        borderColor: `rgba(${props.color}, 1)`,
                                        borderRadius: 1,
                                        p: 0.6,
                                        backgroundColor: `rgba(${props.color}, 0.5)`,
                                        minWidth: "100px",
                                        textAlign: "left",
                                        width: "45%",
                                    }}
                                >
                                    {props?.project?.estimationType || "NA"}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default ProjectDetailsRow;