import {Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import EventVendorDetailsPopup from "./EventVendorDetailsPopup";

const EventVendorDetails = (props: any) => {
    const {eventDetails, eventVendors} = props;
    const [selectedEventVendor, setSelectedEventVendor] = useState<any>({});
    const [openVendorDetails, setOpenVendorDetails] = useState<any>(false);

    const handleViewVendorDetails = (eventVendor: any) => {
        setSelectedEventVendor(eventVendor);
        setOpenVendorDetails(true);
    }

    const handlePopupCallback = () => {
        setSelectedEventVendor({});
        setOpenVendorDetails(false);
    }

    return (
        <Box mt={4}>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant={"h4"} sx={{pb: 1}}>Vendor Details</Typography>
            </Box>
            <TableContainer
                component={"div"}
                sx={{border: "1px solid", borderColor: "divider", borderBottom: 0}}
            >
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead sx={{backgroundColor: "info.contrastText"}}>
                        <TableRow>
                            <TableCell width={"6%"} align={"left"}>Sr. No.</TableCell>
                            <TableCell align={"left"}>Vendor Name</TableCell>
                            <TableCell align={"left"}>Vendor Active?</TableCell>
                            <TableCell align={"left"}>Vendor Event Status</TableCell>
                            <TableCell align={"left"}>Documents Added</TableCell>
                            <TableCell align={"left"}>Questions Answered</TableCell>
                            <TableCell align={"left"}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventVendors.map((eventVendor: any, index: number) => {
                            let statusLabel = "Rejected";
                            let statusColor = "#1565c0";
                            if (eventVendor?.status == "REJECTED") {
                                statusColor = "#d32f2f";
                            }
                            if(eventVendor.status == "CREATED" || eventVendor.status == "PARTICIPATED") {
                                statusLabel = "Not Submitted";
                            }
                            if(eventVendor.status == "SUBMITTED") {
                                statusLabel = "Bid Submitted";
                            }
                            return (
                                <TableRow key={eventVendor.vendorId}>
                                    <TableCell align={"left"}>{index + 1}</TableCell>
                                    <TableCell align={"left"}>
                                        <Link to={`/vendors/details/${eventVendor.vendorId}`} target={"_blank"}>
                                            {eventVendor.vendor.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        <Typography
                                            sx={{textTransform: 'capitalize'}}>{eventVendor?.vendor.status.toLowerCase()}</Typography>
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        <Typography
                                            sx={{
                                                textTransform: "capitalize",
                                                border: 1,
                                                color:'common.white',
                                                borderColor: `${statusColor}`,
                                                borderRadius: 1,
                                                p: 0.6,
                                                backgroundColor: `${statusColor}`,
                                                minWidth: "100px",
                                                textAlign: "center",
                                                width: "60%",
                                            }}
                                        >
                                            {statusLabel}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        {(eventVendor.link != "" && eventVendor.link != null) ? (eventVendor.vendorDocuments.length + 1) : eventVendor.vendorDocuments.length}
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        {eventVendor.vendorQuestions.length}
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        <Button variant={"text"} sx={{minHeight: '10px', m: 0, p: 0}}
                                                onClick={() => handleViewVendorDetails(eventVendor)}>View
                                            Details</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {openVendorDetails && (
                <EventVendorDetailsPopup openPopup={openVendorDetails} eventVendor={selectedEventVendor}
                                         questions={eventDetails.questions} handlePopupCallback={handlePopupCallback}/>
            )}
        </Box>
    )
}
export default EventVendorDetails;