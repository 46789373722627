import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {getStyle} from "../../../utils/ModalUtils";
import AddIcon from '@mui/icons-material/Add';
import {openSnackbar} from "../../common/features/snackbarSlice";
import {ERROR_COLOR} from "../../../constants";
import {useAppDispatch} from "../../../app/hooks";

const QuestionnairePopup = (props: any) => {
    const style = getStyle(45, 65, 0, 14);
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const [questionType, setQuestionType] = useState("text");
    const [answers, setAnswers] = useState<any>([]);
    const [selectedQuestion, setSelectedQuestion] = useState<any>({});
    const [mandatory, setMandatory] = useState(false);

    const handleAnswerChange = (event: any, index: number) => {
        let newAnswerValues: any = [...answers];
        newAnswerValues[index][event.target.name] = event.target.value;
        setAnswers(newAnswerValues);
    }

    const onMandatoryChange = (event: any) => {
        const {checked} = event.target;
        setMandatory(checked);
    }

    const removeAnswer = (index: number) => {
        let newAnswerValues: any = [...answers];
        newAnswerValues.splice(index, 1);
        setAnswers(newAnswerValues);
    }

    const saveQuestion = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const selectedAnswers: any = [];
        if (questionType != "text") {
            let isAnswerEmpty = false;
            answers.map((addedAnswer: any) => {
                if (addedAnswer.answer == "" || addedAnswer.answer == null) {
                    isAnswerEmpty = true;
                } else {
                    selectedAnswers.push(addedAnswer.answer);
                }
            });
            if (isAnswerEmpty) {
                dispatch(
                    openSnackbar({
                        message: "One or more answer fields are empty",
                        backgroundColor: ERROR_COLOR,
                    })
                );
                return;
            }
        }

        let form: any = {
            question: data.get("question"),
            questionType: questionType,
            mandatory: mandatory,
            answers: selectedAnswers
        };

        if (props.selectedQuestionIndex != null && !isNaN(props.selectedQuestionIndex)) {
            form['id'] = props.questions[props.selectedQuestionIndex].id;
            props.handleQuestionPopupCallback({event: 'edit', data: form, index: props.selectedQuestionIndex});
        } else {
            props.handleQuestionPopupCallback({event: 'add', data: form});
        }
    }

    const handleQuestionTypeChange = (event: any) => {
        setQuestionType(event.target.value);
    }

    let addAnswerField = () => {
        setAnswers([...answers, {answer: ""}]);
    }

    const handleClose = () => {
        setOpen(false);
        props.handleQuestionPopupCallback({event: 'close'});
    }

    useEffect(() => {
        if (Object.keys(selectedQuestion).length > 0 && selectedQuestion.questionType != "text") {
            const allAnswers: any[] = [];
            if (Array.isArray(selectedQuestion.answers)) {
                selectedQuestion.answers.map((answer: any) => {
                    allAnswers.push({answer: answer});
                });
                setAnswers(allAnswers);
            } else {
                setAnswers([{answer: ""}]);
            }
        } else {
            if (questionType != 'text') {
                setAnswers([{answer: ""}]);
            }
        }
    }, [questionType, selectedQuestion]);

    useEffect(() => {
        if (props.openPopup === true) {
            if (props.selectedQuestionIndex != null && !isNaN(props.selectedQuestionIndex)) {
                const questionDetails = props.questions[props.selectedQuestionIndex];
                setQuestionType(questionDetails.questionType);
                setMandatory(questionDetails.mandatory == 1);
                setSelectedQuestion(questionDetails);
            }
            setOpen(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}} component={"form"} onSubmit={saveQuestion}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Add Question
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box sx={{width: "40%", py: 1}}>
                                <InputLabel required={true} sx={{pb: 0.5, fontSize: "0.8rem"}}>
                                    Question Type
                                </InputLabel>
                                <Box sx={{width: '60%'}}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name={"questionType"}
                                        required={true}
                                        value={questionType}
                                        onChange={handleQuestionTypeChange}
                                        style={{height: '40px', width: '100%'}}
                                    >
                                        <MenuItem value={"radio"}>Single Choice</MenuItem>
                                        <MenuItem value={"checkbox"}>Multiple Choice</MenuItem>
                                        <MenuItem value={"text"}>Text</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <Box sx={{width: '70%', py: 1}}>
                                <InputLabel required={true} sx={{pb: 0.5, fontSize: "0.8rem"}}>
                                    Question Title
                                </InputLabel>
                                <Box>
                                    <TextField
                                        fullWidth
                                        size={"small"}
                                        variant={"outlined"}
                                        className={"inputBox"}
                                        name="question"
                                        required={true}
                                        defaultValue={selectedQuestion.hasOwnProperty("question") ? selectedQuestion.question : ""}
                                        InputProps={{
                                            style: {
                                                borderRadius: "5px",
                                                borderColor: 'primary.light',
                                                fontSize: "0.8rem",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                            {questionType !== "text" && (
                                <Box sx={{width: '70%'}}>
                                    <InputLabel required={true} sx={{pb: 0.5, fontSize: "0.8rem"}}>
                                        Answers
                                    </InputLabel>
                                    <Box>
                                        {answers.map((addedAnswer: any, index: number) => {
                                            return (
                                                <Box key={"answer-field" + index} sx={{pb: 1}}
                                                     className={"space-between"}>
                                                    <Box sx={{width: '80%'}}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                fullWidth
                                                                size={"small"}
                                                                variant={"outlined"}
                                                                className={"inputBox"}
                                                                name="answer"
                                                                placeholder={"Add Answer Here"}
                                                                defaultValue={addedAnswer.answer || ""}
                                                                onChange={e => handleAnswerChange(e, index)}
                                                                InputProps={{
                                                                    style: {
                                                                        borderRadius: "5px",
                                                                        borderColor: "primary.light",
                                                                        fontSize: "0.8rem",
                                                                    },
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                    {index > 0 && (
                                                        <Button onClick={() => removeAnswer(index)} variant={"text"}
                                                                sx={{ml: 1}}>
                                                            <CloseIcon/>
                                                        </Button>
                                                    )}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box className={"right-align-content"}>
                                        <Button variant={"text"} endIcon={<AddIcon/>} onClick={addAnswerField}>Add
                                            Answer</Button>
                                    </Box>
                                </Box>
                            )}
                            <Box>
                                <FormControlLabel
                                    label={"Mark Mandatory"}
                                    control={
                                        <Checkbox
                                            checked={mandatory}
                                            onChange={onMandatoryChange}
                                            name={"mandatory"}
                                            value={1}
                                        />
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={style.footer}>
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                            <Grid item>
                                <Box>
                                    <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}} onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button variant={"contained"} sx={{fontSize: "0.8rem"}} type={"submit"}>
                                        Add
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default QuestionnairePopup;