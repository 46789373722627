export const formatEstimationWithRevisionData = (revisionEstimations: any, showUpdatedCharges: any) => {
    const formattedData: any = [];
    revisionEstimations.forEach((revisionEstimation: any) => {
        revisionEstimation?.estimations.forEach((item: any) => {
            item.estimationItems.forEach((estimationItem: any) => {
                estimationItem.estimationItemSkus.forEach((sku: any) => {
                    const row = {
                        "REVISION NAME": revisionEstimation.name,
                        "ITEM CATEGORY NAME": estimationItem.itemCategory.name ?? "",
                        "ITEM CATEGORY CODE": estimationItem.itemCategory.code ?? "",
                        "ITEM NAME": estimationItem.item.name ?? "",
                        "ITEM CODE": estimationItem.item.code ?? "",
                        "SKU NAME": sku.sku.name ?? "",
                        "SKU CODE": sku.sku.code ?? "",
                        "QUANTITY": sku.quantity ?? "",
                        "BUFFER QUANTITY": sku.bufferQuantity ?? "",
                        "TOTAL QUANTITY": sku.totalQuantity ?? "",
                        "TARGET PRICE": sku.targetPrice ?? "",
                        "SELLING PRICE": sku.sellingPrice ?? "",
                        "NSV": sku.nsvPercentage
                            ? `${(sku.nsvPercentage / 100) * 100}%`
                            : "",
                        "TOTAL COGS": sku.targetPrice ? sku.targetPrice * sku.totalQuantity : '',
                        "TOTAL GSV": sku.sellingPrice ? sku.sellingPrice * sku.totalQuantity : '',
                        "TOTAL NSV": estimationItem.total
                            ? estimationItem.total - estimationItem.estimationTotalAmount
                            : "",
                    };
                    formattedData.push(row);
                });
            });
        });
    });
    return formattedData;
}

export const getCSVFormatRevisionEstimations = (data: any, showUpdatedCharges: any) => {
    let header = [
        "REVISION NAME",
        "ITEM CATEGORY NAME",
        "ITEM CATEGORY CODE",
        "ITEM NAME",
        "ITEM CODE",
        "SKU NAME",
        "SKU CODE",
        "QUANTITY",
        "BUFFER QUANTITY",
        "TOTAL QUANTITY",
        "SELLING PRICE",
        "NSV",
        "TOTAL GSV",
        "TOTAL NSV",
    ];
    if(!showUpdatedCharges) {
        header = [
            "REVISION NAME",
            "ITEM CATEGORY NAME",
            "ITEM CATEGORY CODE",
            "ITEM NAME",
            "ITEM CODE",
            "SKU NAME",
            "SKU CODE",
            "QUANTITY",
            "BUFFER QUANTITY",
            "TOTAL QUANTITY",
            "TARGET PRICE",
            "SELLING PRICE",
            "NSV",
            "TOTAL COGS",
            "TOTAL GSV",
            "TOTAL NSV",
        ];
    }
    return convertToCSV(header, data);
}

export const getCSVFormatEstimation = (data: any, showUpdatedCharges: any) => {
    let header = [
        "ITEM CATEGORY NAME",
        "ITEM CATEGORY CODE",
        "ITEM NAME",
        "ITEM CODE",
        "SKU NAME",
        "SKU CODE",
        "QUANTITY",
        "BUFFER QUANTITY",
        "TOTAL QUANTITY",
        "SELLING PRICE",
        "NSV",
        "TOTAL GSV",
        "TOTAL NSV",
    ];
    if(!showUpdatedCharges) {
        header = [
            "ITEM CATEGORY NAME",
            "ITEM CATEGORY CODE",
            "ITEM NAME",
            "ITEM CODE",
            "SKU NAME",
            "SKU CODE",
            "QUANTITY",
            "BUFFER QUANTITY",
            "TOTAL QUANTITY",
            "TARGET PRICE",
            "SELLING PRICE",
            "NSV",
            "TOTAL COGS",
            "TOTAL GSV",
            "TOTAL NSV",
        ];
    }
    return convertToCSV(header, data);
}

const convertToCSV = (header: any[], data: any) => {
    const csvRows = data.map((row: any) =>
        header.map((fieldName) => JSON.stringify(row[fieldName] ?? "")).join(",")
    );

    csvRows.unshift(header.join(",")); // Add the header row

    return csvRows.join("\n");
};
