import {Box, CircularProgress} from "@mui/material";

export default function Loader() {
    return (
        <Box sx={{alignItems: "center", display: "flex", justifyContent: "center"}}>
            <Box sx={{justifyContent: "center", position: "fixed", top: "55%", zIndex:99}}>
                <Box className={"center-aligned"}>
                    <CircularProgress color="success" size={100}/>
                </Box>
                <Box sx={{pt: 2}}>
                    Loading... please wait
                </Box>
            </Box>
        </Box>
    );
}