import {Grid, Typography} from "@mui/material";
import {getPriceInFormatted, getSubCategoryRange} from "../../utils/Common";
import {useEffect, useState} from "react";

const BoqBreakupSubCategoryListingDetails = (props: any) => {
    const {project, category, subCategory, totalCogs, revisionDesignType} = props;
    const [color, setColor] = useState("#000");
    const [outOfRangeSummary, setOutOfRangeSummary] = useState(false);
    const [range, setRange] = useState<any>([]);
    const sqftRate = subCategory.total / project.projectDetails?.builtUpArea;

    useEffect(() => {
        const subCategoryDecidedRange = getSubCategoryRange(subCategory.code, props.revisionDesignType);
        if (subCategoryDecidedRange != null) {
            let maxRange = subCategoryDecidedRange[1] != undefined ? subCategoryDecidedRange[1] : subCategoryDecidedRange[0];
            if (sqftRate > maxRange) {
                setOutOfRangeSummary(true);
                setColor("#AA4A44")
                setRange(subCategoryDecidedRange);
            }
        }
    }, [subCategory]);

    return (
        <Grid container sx={{color: color}} title={outOfRangeSummary ? `Range details: ${range.join(", ")}` : ""}>
            <Grid item xs={!props.showUpdatedCharges ? 2.5 : 4.5}>
                <Typography sx={{fontSize: '10px', py: 0.5, color: 'inherit'}}>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography sx={{fontSize: '10px', py: 0.5, color: 'inherit'}}>
                    %age
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography sx={{fontSize: '10px', py: 0.5, color: 'inherit'}}>
                    Total SKU
                </Typography>
            </Grid>
            {!props.showUpdatedCharges &&
                <Grid item xs={2}>
                    <Typography sx={{fontSize: '10px', py: 0.5, color: 'inherit'}}>
                        Total COGS
                    </Typography>
                </Grid>
            }
            <Grid item xs={2}>
                <Typography sx={{fontSize: '10px', py: 0.5, color: 'inherit'}}>
                    Total GSV
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography sx={{fontSize: '10px', py: 0.5, color: 'inherit'}}>
                    Sqft Rate
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography sx={{fontSize: '10px', py: 0.5, color: 'inherit'}}>
                    NSV %age
                </Typography>
            </Grid>

            <Grid item xs={!props.showUpdatedCharges ? 2.5 : 4.5}>
                <Typography
                    sx={{fontSize: "14px", fontWeight: 600, py: 0.5, color: 'inherit'}}>
                    {subCategory.name}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography
                    sx={{fontSize: "14px", fontWeight: 600, py: 0.5, color: 'inherit'}}>
                    {((subCategory.total as number) / (category.total as number) * 100).toFixed(1)}%
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography
                    sx={{fontSize: "14px", fontWeight: 600, py: 0.5, color: 'inherit'}}>
                    {subCategory.estimationTotalSkus} skus
                </Typography>
            </Grid>
            {!props.showUpdatedCharges &&
                <Grid item xs={2}>
                    <Typography
                        sx={{fontSize: "14px", fontWeight: 600, py: 0.5, color: color}}>
                        {getPriceInFormatted(totalCogs, project.country)}
                    </Typography>
                </Grid>
            }
            <Grid item xs={2}>
                <Typography
                    sx={{fontSize: "14px", fontWeight: 600, py: 0.5, color: 'inherit'}}>
                    {getPriceInFormatted(subCategory.total, project.country)}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography
                    sx={{fontSize: "14px", fontWeight: 600, py: 0.5, color: 'inherit'}}>
                    {getPriceInFormatted(sqftRate, project.country)}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography
                    sx={{fontSize: "14px", fontWeight: 600, py: 0.5, color: 'inherit'}}>
                    {!isNaN(((subCategory.total - totalCogs) / subCategory.total) * 100) ? (((subCategory.total - totalCogs) / subCategory.total) * 100).toFixed(2) : Number.parseFloat("0").toFixed(2)}%
                </Typography>
            </Grid>
        </Grid>
    )
}

export default BoqBreakupSubCategoryListingDetails;