import {useEffect, useState} from "react";
import {api} from "../../../../../utils/Axios";
import {getStyle} from "../../../../../utils/ModalUtils";
import Loader from "../../../../../components/Loader";
import {Box, Modal, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ListPage from "../../../../../components/list-page/ListPage";
// import {config} from "./config";
import SearchFilter from "../../../../../components/filter/SearchFilter";
import { createConfig } from "./config";

const CloneProject = (props: any) => {
    const style = getStyle(70, 75, 0, 0);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, rowChange] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null)
    const [bufferValues, setBufferValues] = useState<any>({})
    const hideColumns = {id: false};

    const handleClose = (data: any) => {
        setOpen(false);
        props.handleCloneCreation(data);
    }
    
    const handleCallBack = (rowData: any, buttonData: any) => {
        console.log(rowData);
        if (buttonData.type === 'select-project') {
            const payload = {
                event: 'select',
                projectId: rowData.id,
                projectName: rowData.name,
                bufferPrice: bufferValues[rowData.id] || 0,
            };
            props.handleCloneCreation(payload);
        }
    };

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const handleSort = (sortModel: any) => {
        setSortColumn(sortModel.field);
        setSortDirection(sortModel?.sort);
        pageChange(0);
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    }

    useEffect(() => {
        loadProjects();
    }, [sortColumn, sortDirection, page, size, searchQuery]);

    const loadProjects = () => {
        setIsLoading(true);
        api.get(`/procurement/project/get-for-clone`, {
            params: {
                pageNo: page,
                pageSize: size,
                sort: sortDirection?.toUpperCase(),
                sortByColumn: sortColumn,
                searchQuery: searchQuery
            }
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            const initialBufferValues = response.content.reduce((acc: any, row: any) => {
                acc[row.id] = 0;
                return acc;
            }, {});
            rowChange(response.content);
            setBufferValues(initialBufferValues);
            setTotalElements(response.totalElements);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (props.openPopup == true) {
            setOpen(true);
        }
    }, [props.openPopup]);

    const handleCheckboxChange = (rowId: number) => {
        setSelectedRow((prevSelectedRow: any) => (prevSelectedRow === rowId ? null : rowId));
    }

    const handleBufferChange = (rowId: number, increment: boolean) => {
        setBufferValues((prevValues: any) => ({
            ...prevValues,
            [rowId]: increment ? prevValues[rowId] + 1 : Math.max(0, prevValues[rowId] - 1)
        }));
    };

    const config = createConfig(selectedRow, handleCheckboxChange, bufferValues, handleBufferChange);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Modal
            open={open}
            onClose={() => handleClose({event: 'close'})}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={style.header}>
                    <Box
                        sx={style.headerContainer}>
                        <Box>
                            <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                Clone Project
                            </Typography>
                        </Box>
                        <Box sx={{ml: 'auto'}}>
                            <CloseIcon sx={{cursor: "pointer"}} onClick={() => handleClose({event: 'close'})}/>
                        </Box>
                    </Box>
                </Box>
                <Box className={"modal-body"} sx={style.body}>
                    <Box sx={style.bodyContainer}>
                        {rows &&
                            <Box className={"common-space-container"}>
                                <Box display={"flex"} justifyContent={"space-between"} sx={{pb: 1}}>
                                    <Box>
                                        <Typography variant={"h1"}>Projects</Typography>
                                    </Box>
                                    <Box>
                                        <Box className={'right-align-content'}>
                                            <SearchFilter query={searchQuery} handleSearchFilter={handelSearchFilter}/>
                                        </Box>
                                    </Box>
                                </Box>
                                <ListPage
                                    rows={rows}
                                    columns={config}
                                    page={page}
                                    size={size}
                                    totalElements={totalElements}
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    handlePagination={handlePagination}
                                    handleSort={handleSort}
                                    handleCallBack={handleCallBack}
                                    hideColumns={hideColumns}
                                />
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default CloneProject;