import React, {SyntheticEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {getEstimationRevisions} from "./boq-tabs/features/actions";
import Loader from "../../../../components/Loader";
import {Box, Button, Grid, Tab, Tabs, Typography} from "@mui/material";
import {a11yProps, CustomTabPanel} from "../../../../utils/TabsUtils";
import EstimationRevisionList from "../../estimation/EstimationRevisionList";
import CompareRevisionMain from "../../../../components/estimation/revision/CompareRevisionMain";
import CreateEstimationRevision from "../../../../components/estimation/revision/CreateEstimationRevision";

const EstimationTab = (props: any) => {
    const dispatch: any = useAppDispatch();
    const isLoading = useAppSelector((state: any) => state.boqSummary.pending);
    const [open, setOpen] = useState(false);
    const [revisionTabValue, setRevisionTabValue] = useState("revision");
    const revisions: any = useAppSelector((state: any) => state.boqSummary.revisions);

    const [tabs, setTabs] = useState([
        {id: "revision", name: "All",},
        {id: "comparison", name: "Compare",}
    ]);

    const handleRevisionTabChange = (event: SyntheticEvent, newValue: string) => {
        setRevisionTabValue(newValue);
    };

    const handleCreateRevision = (e: any) => {
        setOpen(true);
    }

    const handleCreatePopupCallBack = (data: any) => {
        setOpen(false);
        if (data.event == 'save')
            refreshPage();
    }

    const refreshPage = () => {
        dispatch(getEstimationRevisions(props.project.id));
    }

    useEffect(() => {
        refreshPage();
    }, [props.project]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <>
            {revisions != null && revisions.length > 0 ? (
                    <Box sx={{position: 'relative'}}>
                        <Tabs
                            value={revisionTabValue}
                            onChange={handleRevisionTabChange}
                            indicatorColor="secondary"
                            variant={"fullWidth"}
                            sx={{
                                width: "16%",
                                fontFamily: 'Lato',
                                "& .Mui-selected": {
                                    backgroundColor: "primary.main",
                                    color: "#fff!important",
                                    fontWeight: 700
                                },
                            }}
                            aria-label="wrapped label tabs example"
                            TabIndicatorProps={{
                                sx: {
                                    backgroundColor: "primary.main",
                                },
                            }}
                        >
                            {tabs.map((tab: any, index: number) => (
                                <Tab
                                    key={tab.id + "_" + index}
                                    {...a11yProps(tab.id, true)}
                                    value={tab.id}
                                    label={tab.name}
                                    wrapped
                                    sx={{border: 1, borderColor: "divider"}}
                                />
                            ))}
                        </Tabs>
                        <Box sx={{py: 2}}>
                            <CustomTabPanel value={revisionTabValue} index={"revision"}>
                                <Grid container sx={{backgroundColor: '#E9EBF9', p: 2, fontWeight: 700}}>
                                    <Grid item xs={2.5}>Revision Name</Grid>
                                    <Grid item xs={1.5} sx={{px: 1}}>Created On</Grid>
                                    <Grid item xs={1.5} sx={{px: 1}}>Created By</Grid>
                                    <Grid item xs={3} sx={{px: 1}}>Last Modified</Grid>
                                    <Grid item xs={1.5} sx={{px: 1}}>Status</Grid>
                                    <Grid item xs={2} sx={{px: 1}}>Action</Grid>
                                </Grid>
                                <EstimationRevisionList
                                    showUpdatedCharges={props.showUpdatedCharges}
                                    revisions={revisions}
                                    project={props.project}
                                    categories={props.categories}
                                    refreshPage={refreshPage}
                                    proceedTabChange={props.proceedTabChange}
                                />
                            </CustomTabPanel>

                            <CustomTabPanel value={revisionTabValue} index={"comparison"}>
                                <CompareRevisionMain showUpdatedCharges={props.showUpdatedCharges} revisions={revisions} project={props.project}
                                                     categories={props.categories}/>
                            </CustomTabPanel>
                        </Box>


                    </Box>
                )  :
                (
                    <>
                        <Box>
                            <Box className={"center-aligned"} sx={{width: '100%'}}>
                                <Box component="img"
                                     src={`${process.env.REACT_APP_CONTENT_URL}/innov8/estimation.png`}
                                     sx={{maxHeight: '300px'}}/>
                            </Box>
                            <Box className={"center-aligned"} sx={{width: '100%'}}>
                                <Typography>No Estimation Created Yet !</Typography>
                            </Box>
                            <Box className={"center-aligned"} sx={{width: '100%', pt: 2}}>
                                <Button variant={"contained"} onClick={handleCreateRevision}>Create New
                                    Estimation</Button>
                            </Box>
                        </Box>
                        {open &&
                            <CreateEstimationRevision project={props.project} openPopup={open}
                                                      handleCreatePopupCallBack={handleCreatePopupCallBack}/>
                        }
                    </>
                )
            }
        </>
    )
}
export default EstimationTab;