import {getStyle} from "../../../utils/ModalUtils";
import React, {useEffect, useState} from "react";
import {Box, Modal, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {Link} from "react-router-dom";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ReadMore from "../../read-more/ReadMore";

const EventDocumentView = (props: any) => {
    const style = getStyle(40, 30, 15, 1);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallback();
    }

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Documents
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box>
                                <Box>
                                    <Box sx={{py: 2}}>
                                        <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                            Documents ({props?.totalDocuments})
                                        </Typography>
                                        {props?.link !== "" && (
                                            <Box className={"space-between"}
                                                 sx={{border: 1, p: 1, mt: 1}}>
                                                <Box className={"vertically-center-aligned"}
                                                     sx={{pr: 1}}>
                                                    <InsertLinkIcon/>
                                                </Box>
                                                <Typography className={"vertically-center-aligned"}
                                                            sx={{wordBreak: "break-all", px: 1}}>{props?.link}</Typography>
                                                <Link to={props?.link} target={"_blank"}
                                                      className={"vertically-center-aligned"}
                                                      style={{paddingRight: 1}}>
                                                    View
                                                </Link>
                                            </Box>
                                        )}

                                        {props.documents.map((clientDocument: any, docIndex: number) => (
                                            <Box key={"client-document-" + docIndex}
                                                 className={"space-between"}>
                                                <Box className={"space-between"}
                                                     sx={{border: 1, p: 1, mt: 1, width: '65%'}}>
                                                    <Box className={"vertically-center-aligned"}
                                                         sx={{pr: 1}}>
                                                        <InsertDriveFileOutlinedIcon/>
                                                    </Box>
                                                    <Typography
                                                        className={"vertically-center-aligned"}
                                                        sx={{wordBreak: "break-all"}}>{clientDocument.name}</Typography>
                                                    <Link to={clientDocument?.documentLink}
                                                          target={"_blank"}
                                                          className={"vertically-center-aligned"}
                                                          style={{paddingRight: 1}}>
                                                        View
                                                    </Link>
                                                </Box>
                                                {clientDocument.comment !== "" && (
                                                    <Box className={"vertically-center-aligned"}
                                                         sx={{px: 1, width: '35%'}}>
                                                        <ReadMore text={"Comment: " + clientDocument.comment}
                                                                  maxChars={20}/>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default EventDocumentView;