import {useEffect, useState} from "react";
import {api} from "../../utils/Axios";
import Loader from "../../components/Loader";
import {Box} from "@mui/material";
import PageHeading from "../../components/page-heading/PageHeading";
import SearchFilter from "../../components/filter/SearchFilter";
import RemoveFilters from "../../components/filter/RemoveFilters";
import ListPage from "../../components/list-page/ListPage";
import {itemCategoryPageConfig} from "../../components/item-category/Config";

const ItemCategory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, rowChange] = useState([]);
    const [page, pageChange] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState("totalRc");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const [appliedFilters, setAppliedFilters] = useState({});

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
    }

    const handleCallBack = (rowData: any, buttonData: any) => {
        console.log(rowData);
    };

    const handleSort = (sortModel: any) => {
        setSortColumn(sortModel.field);
        setSortDirection(sortModel?.sort);
        pageChange(0);
    };

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const loadItemCategories = () => {
        setIsLoading(true);
        const params = new URLSearchParams({
            pageNo: page.toString(),
            pageSize: size.toString(),
            sort: sortDirection?.toUpperCase(),
            sortByColumn: sortColumn,
            searchQuery: searchQuery,
        });
        if (Object.keys(appliedFilters).length > 0) {
            Object.entries(appliedFilters).map(([name, value]: any) => {
                params.append(name, value);
            });
        }

        api
            .get("/catalogue/item-category/all", {
                params: params,
            })
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                rowChange(response.content);
                setTotalElements(response.totalElements);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadItemCategories();
    }, [sortColumn, sortDirection, page, size, searchQuery, appliedFilters]);

    if (isLoading) {
        return <Loader/>;
    }
    return(
        <Box className={"common-space-container"}>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <PageHeading title={"Skus"} showButtons={false}/>
                <SearchFilter
                    handleFilterChange={handleFilterChange}
                    filters={filters}
                    appliedFilters={appliedFilters}
                    showFilters={true}
                    showSearch={true}
                    query={searchQuery}
                    handleSearchFilter={handelSearchFilter}
                />
            </Box>
            <Box>
                <RemoveFilters handleFilterChange={handleFilterChange} filters={filters}
                               appliedFilters={appliedFilters}/>
            </Box>

            <Box>
                <ListPage
                    rows={rows}
                    columns={itemCategoryPageConfig.columns}
                    page={page}
                    size={size}
                    totalElements={totalElements}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    handlePagination={handlePagination}
                    handleSort={handleSort}
                    handleCallBack={handleCallBack}
                    rowHeight={92}
                />
            </Box>
        </Box>
    )
}

export default ItemCategory;


const filters = {
    'name': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Name',
        label: 'Name',
        name: 'name'
    },
    'code': {
        element: 'input',
        type: 'text',
        placeholder: 'Search by code',
        label: 'Code',
        name: 'code'
    },
};