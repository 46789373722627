import {getStyle} from "../../../utils/ModalUtils";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Modal, Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const EventQuestionnaireView = (props: any) => {
    console.log(props);
    const style = getStyle(40, 30, 15, 1);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallback();
    }

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
        }
    }, [props.openPopup]);

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}} >
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Questionnaire List
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box>
                                {props.questions.length > 0 && (
                                    <Box>
                                        <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                            Questions
                                        </Typography>
                                        <Typography sx={{py: 1, fontSize: '12px'}}>
                                            Mandatory questions are marked with *.
                                        </Typography>
                                        {props.questions.map((clientQuestion: any, questionIndex: number) => {
                                                return (
                                                    <Box key={"question-" + questionIndex}>
                                                        <Typography sx={{py: 2}}>
                                                            {questionIndex + 1}. {clientQuestion.question}
                                                            {clientQuestion.mandatory && (
                                                                <sup>*</sup>
                                                            )}
                                                        </Typography>
                                                        <Box sx={{width: '60%', px: 2}}>
                                                            {clientQuestion.questionType == "radio" ? (
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name={clientQuestion.id}
                                                                        defaultValue={""}
                                                                    >
                                                                        {Array.isArray(clientQuestion.answers) && clientQuestion.answers.length > 0 && clientQuestion.answers.map((option: any, index: number) => (
                                                                                <FormControlLabel
                                                                                    key={`radio-button-form-${index}-${clientQuestion.id}`}
                                                                                    value={option}
                                                                                    control={<Radio disabled/>}
                                                                                    label={option}/>
                                                                            ))
                                                                        }
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            ) : (
                                                                clientQuestion.questionType == "checkbox" ? (
                                                                    Array.isArray(clientQuestion.answers) && clientQuestion.answers.length > 0 && clientQuestion.answers.map((option: any, answerIndex: number) => {
                                                                        return (
                                                                            <FormControlLabel
                                                                                key={`checkbox-form-${clientQuestion.id}-` + answerIndex}
                                                                                label={option}
                                                                                control={
                                                                                    <Checkbox
                                                                                        disabled
                                                                                        name={clientQuestion.id}
                                                                                        value={option}
                                                                                    />
                                                                                }
                                                                            />
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        disabled
                                                                        size={"small"}
                                                                        variant={"outlined"}
                                                                        className={"inputBox"}
                                                                        name={clientQuestion.id}
                                                                        required={clientQuestion.mandatory}
                                                                        defaultValue={""}
                                                                        InputProps={{
                                                                            style: {
                                                                                borderRadius: "5px",
                                                                                borderColor: 'primary.light',
                                                                                fontSize: "0.8rem",
                                                                            },
                                                                        }}
                                                                    />
                                                                )
                                                            )}
                                                        </Box>
                                                    </Box>
                                                )
                                            }
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default EventQuestionnaireView