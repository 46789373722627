import User from "../modules/user/User";
import React from "react";
import CreateUser from "../modules/user/create/CreateUser";
import EditUser from "../modules/user/edit/EditUser";
import Role from "../modules/user/role/Role";
import CreateRole from "../modules/user/role/create/CreateRole";
import EditRole from "../modules/user/role/edit/EditRole";
import VendorEvent from "../modules/vendor/event/VendorEvent";
import VendorEventDetails from "../modules/vendor/event/VendorEventDetails";
import SkuDetails from "../modules/catalogue/skus/details/SkuDetails";
import Home from "../modules/home/Home";
import Vendor from "../modules/vendor/Vendor";
import VendorUser from "../modules/vendor/user/VendorUser";
import CreateVendorUser from "../modules/vendor/user/CreateVendorUser";
import VendorDetails from "../modules/vendor/details/VendorDetails";
import EditVendor from "../modules/vendor/details/EditVendor";
import Project from "../modules/project/Project";
import CreateProject from "../modules/project/create/CreateProject";
import ProjectPlan from "../modules/project/plan/ProjectPlan";
import Brand from "../modules/brand/Brand";
import EditVendorUser from "../modules/vendor/user/EditVendorUser";
import Skus from "../modules/catalogue/skus/Skus";
import CatalogueMain from "../modules/catalogue/CatalogueMain";
import DomainDetails from "../modules/project/domain-details/DomainDetails";
import Reports from "../modules/report/Reports";
import VendorPackageDetails from "../modules/vendor/event/VendorPackageDetails";
import VendorCatalogue from "../modules/vendor/catalogue/VendorCatalogue";
import VendorSkuDetails from "../modules/vendor/catalogue/VendorSkuDetails"
import VendorCatalogueInternal from "../modules/vendor/catalogue/VendorCatalogueInternal";
import ItemCategory from "../modules/catalogue/ItemCategory";

export const AppPages = [
    {path: '/', element: <Home/>},
    {path: '/users', element: <User/>, permissions: ['view-users']},
    {path: '/users/create', element: <CreateUser/>, permissions: ['edit-users']},
    {path: '/users/edit/:id', element: <EditUser/>, permissions: ['edit-users']},
    {path: '/users/roles', element: <Role/>, permissions: ['edit-users']},
    {path: '/users/roles/create', element: <CreateRole/>, permissions: ['edit-users']},
    {path: '/users/roles/edit/:id', element: <EditRole/>, permissions: ['edit-users']},
    {path: '/catalogue', element: <CatalogueMain/>, permissions: ['edit-skus', 'view-skus']},
    {path: '/catalogue/item-category', element: <ItemCategory/>, permissions: ['edit-skus', 'view-skus']},
    {path: '/catalogue/skus', element: <Skus/>, permissions: ['edit-skus', 'view-skus']},
    {
        path: '/catalogue/skus/details/:id', element:
            <SkuDetails/>, permissions: ['edit-skus', 'view-skus', 'vendor-admin']
    },
    {
        path: '/vendor/catalogue/skus/details/:id', element:
            <VendorSkuDetails/>, permissions: ['vendor-super-admin', 'vendor-admin']
    },
    {path: '/vendor/events', element: <VendorEvent/>, permissions: ['vendor-super-admin', 'vendor-admin']},
    {path: '/vendor/catalogue', element: <VendorCatalogue/>, permissions: ['vendor-super-admin', 'vendor-admin']},
    {
        path: '/vendor/events/details/:id', element:
            <VendorEventDetails/>, permissions: ['vendor-super-admin', 'vendor-admin']
    },
    {
        path: '/vendor/package/details/:id', element:
            <VendorPackageDetails/>, permissions: ['vendor-super-admin', 'vendor-admin']
    },
    {path: '/vendors', element: <Vendor/>, permissions: ['view-vendors']},
    {path: '/vendors/user/:id', element: <VendorUser/>, permissions: ['edit-vendors']},
    {path: '/vendors/catalogue/:id', element: <VendorCatalogueInternal/>, permissions: ['edit-vendors']},
    {path: '/vendors/user/create/:id', element: <CreateVendorUser/>, permissions: ['edit-users']},
    {path: '/vendors/user/edit/:id', element: <EditVendorUser/>, permissions: ['edit-users']},
    {path: '/vendors/user/edit/:vendorId/:id', element: <EditVendorUser/>, permissions: ['edit-users']},
    {path: '/vendors/details/:id', element: <VendorDetails/>, permissions: ['view-vendors']},
    {path: '/vendors/edit/:id', element: <EditVendor/>, permissions: ['edit-vendors']},
    {path: '/brands', element: <Brand/>, permissions: ['view-brands', 'edit-brands']},
    {path: '/projects', element: <Project/>, permissions: ['view-projects']},
    {path: "/projects/domain-details", element: <DomainDetails/>, permissions: ['super-admin']},
    {path: '/projects/create', element: <CreateProject/>, permissions: ['edit-projects']},
    {path: '/projects/plan/:id', element: <ProjectPlan/>, permissions: ['edit-projects']},
    {path: '/reports/:id', element: <Reports/>, permissions: ['super-admin']},
];