import React, {useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import {getChartOptionsWithinDisplay} from "../../../utils/ChartUtils";
import Loader from "../../Loader";
import {Box, Button, FormControl, Grid, NativeSelect, Typography} from "@mui/material";
import {Doughnut} from "react-chartjs-2";
import {getPriceInFormatted} from "../../../utils/Common";
import {approveEstimationRevision} from "../../../modules/project/plan/tabs/boq-tabs/features/actions";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {APPROVE_ESTIMATION_ERROR, APPROVE_ESTIMATION_SUCCESS, ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import MapProjectPopup from "./MapProjectPopup";
import {getItem} from "../../../utils/LocalStorage";

const NewClientSummary = (props: any) => {
    const {project, revision, partBreakup} = props;
    let betaEnabled = getItem("beta_enabled");
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState<any>({});
    const [chartOptions, setChartOptions] = useState<any>({});
    const backgroundColors: string[] = [
        "#fbd474",
        "#A74462",
        "#048cdc",
        "#3F3B4E",
        "#fdb67a",
        "#5E33FF",
        "#33FF6B",
        "#FF5733",
    ];
    const [mainText, setMainText] = useState("Overall");
    const [summaryDetails, setSummaryDetails] = useState<any[]>([]);

    const [cogsCalculationType, setCogsCalculationType] = useState("built");
    const [gsvCalculationType, setGsvCalculationType] = useState("built");
    const [openMapProject, setOpenMapProject] = useState(false);

    const dispatch: any = useAppDispatch();
    const isError = useAppSelector((state: any) => state.boqSummary.error);

    const onGsvCalculationChangeHandler = (e: any) => {
        setGsvCalculationType(e.target.value);
    }

    const updateMapProject = () => {
        setOpenMapProject(true);
    }
    const handlePopupCallBack = (data: any) => {
        setOpenMapProject(false);
    }
    const approveEstimation = async () => {
        try {
            const response = await dispatch(
                approveEstimationRevision(revision.id)
            ).unwrap();

            if (response) {
                dispatch(
                    openSnackbar({
                        message: APPROVE_ESTIMATION_SUCCESS,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                props.refreshRevision();
                return response;
            } else if (isError) {
                dispatch(
                    openSnackbar({
                        message: APPROVE_ESTIMATION_ERROR,
                        backgroundColor: ERROR_COLOR,
                    })
                );
            }
        } catch (err) {
            dispatch(
                openSnackbar({
                    message: APPROVE_ESTIMATION_ERROR,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    }

    const loadProjectDetails = () => {
        setIsLoading(true);
        api
            .get(`/procurement/project/project-details/${project.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                project.projectDetails = res;
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };
    const updateProjectDetails = (project:any) => {
        project.bigFishProjectId = project.bigFishProjectId;
    };

    useEffect(() => {
        const projectCarpetArea = project.carpetArea;
        const projectBuiltUpArea = project.projectDetails?.builtUpArea;
        const charges: any[] = [];
        let overAllTotal = 0;
        let overAllTotalCogs = 0;
        let subTotalForTax = 0;

        const updatedChartData: any[] = [];

        let dactotal = project.carpetArea * project.projectDetails?.designConsultancyRate;

        let prelimTotal = project.carpetArea * project.projectDetails?.prelimsRate;

        overAllTotal += dactotal;
        overAllTotalCogs += dactotal;
        subTotalForTax += dactotal;
        updatedChartData.push({
            name: "Design and Consultancy Charges",
            total: dactotal,
        });

        let cogsDccTotalPerSqft = dactotal / projectCarpetArea;
        let gsvDccTotalPerSqft = dactotal / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsDccTotalPerSqft = dactotal / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvDccTotalPerSqft = dactotal / projectBuiltUpArea;
        }

        charges.push({
            id: 'dcc',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Design and Consultancy Charges",
            cogs: dactotal,
            nsv: "NA",
            gsv: dactotal,
            nsvPercentage: 0,
            cogsPercentage: cogsDccTotalPerSqft,
            gsvPercentage: gsvDccTotalPerSqft,
        });

        overAllTotal += prelimTotal;
        overAllTotalCogs += prelimTotal;
        subTotalForTax += prelimTotal;
        updatedChartData.push({name: "Prelims Charges", total: prelimTotal});

        let cogsPrelimTotalPerSqft = prelimTotal / projectCarpetArea;
        let gsvPrelimTotalPerSqft = prelimTotal / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsPrelimTotalPerSqft = prelimTotal / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvPrelimTotalPerSqft = prelimTotal / projectBuiltUpArea;
        }

        charges.push({
            id: 'pc',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Prelims Charges",
            nsv: "NA",
            cogs: prelimTotal,
            gsv: prelimTotal,
            nsvPercentage: 0,
            cogsPercentage: cogsPrelimTotalPerSqft,
            gsvPercentage: gsvPrelimTotalPerSqft,
        });

        let taxExcludingTotalCogs: number = dactotal + prelimTotal;// + workContractTotalCogs + supplyTotalCogs;
        let taxExcludingTotalNsv: number = 0; //(workContractTotal - workContractTotalCogs) + (supplyTotal - supplyTotalCogs);
        let taxExcludingTotalGsv: number = dactotal + prelimTotal;

        partBreakup.map((part: any) => {
            let partTotal: number = part.total;
            let partTotalCogs: number = part.totalCogs;

            let cogsPartTotalPerSqft = partTotalCogs / projectCarpetArea;
            let gsvPartTotalPerSqft = partTotal / projectCarpetArea;

            if (cogsCalculationType == 'built') {
                cogsPartTotalPerSqft = partTotalCogs / projectBuiltUpArea;
            }
            if (gsvCalculationType == 'built') {
                gsvPartTotalPerSqft = partTotal / projectBuiltUpArea;
            }

            charges.push({
                id: part.id,
                isCategory: true,
                backgroundColor: "info.contrastText",
                padding: "12px",
                margin: "16px 16px 0 16px",
                name: part.name,
                nsv: partTotal - partTotalCogs,
                cogs: partTotalCogs,
                gsv: partTotal,
                nsvPercentage: ((partTotal - partTotalCogs) / partTotal) * 100,
                cogsPercentage: cogsPartTotalPerSqft,
                gsvPercentage: gsvPartTotalPerSqft,
            });

            updatedChartData.push({
                name: part.name,
                total: partTotal,
            });
            overAllTotal += partTotal;
            overAllTotalCogs += partTotalCogs;
            subTotalForTax += partTotal;
            taxExcludingTotalGsv += partTotal;
            taxExcludingTotalCogs += partTotalCogs;
            taxExcludingTotalNsv += (partTotal - partTotalCogs);
        });

        let cogsTaxExcludingTotalPerSqft = taxExcludingTotalCogs / projectCarpetArea;
        let gsvTaxExcludingTotalPerSqft = taxExcludingTotalGsv / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsTaxExcludingTotalPerSqft = taxExcludingTotalCogs / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvTaxExcludingTotalPerSqft = taxExcludingTotalGsv / projectBuiltUpArea;
        }

        charges.push({
            id: 'excluding-tax',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px 16px 0 16px",
            name: "TOTAL COST FOR PROJECT (Excluding taxes)",
            nsv: taxExcludingTotalNsv,
            cogs: taxExcludingTotalCogs,
            gsv: taxExcludingTotalGsv,
            nsvPercentage: (taxExcludingTotalNsv / taxExcludingTotalGsv) * 100,
            cogsPercentage: cogsTaxExcludingTotalPerSqft,
            gsvPercentage: gsvTaxExcludingTotalPerSqft
        });

        let localAuthorityCharges = 0;
        if (
            project.projectDetails?.isAuthorityApprovalCharges &&
            project.projectDetails?.authorityApprovalCharges > 0
        ) {
            localAuthorityCharges = project.projectDetails?.authorityApprovalCharges;
            overAllTotal += localAuthorityCharges;
            overAllTotalCogs += localAuthorityCharges;
            subTotalForTax += localAuthorityCharges;
            updatedChartData.push({
                name: "Local Authority Charges",
                total: localAuthorityCharges,
            });
        }

        charges.push({
            id: 'local',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Local Authority Charges",
            nsv: "NA",
            cogs: localAuthorityCharges,
            gsv: localAuthorityCharges,
            nsvPercentage: 0,
            cogsPercentage: 0,
            gsvPercentage: 0
        });

        let cogsTotalLocalPerSqft = (taxExcludingTotalCogs + localAuthorityCharges) / projectCarpetArea;
        let gsvTotalLocalPerSqft = (taxExcludingTotalGsv + localAuthorityCharges) / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsTotalLocalPerSqft = (taxExcludingTotalCogs + localAuthorityCharges) / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvTotalLocalPerSqft = (taxExcludingTotalGsv + localAuthorityCharges) / projectBuiltUpArea;
        }

        charges.push({
            id: 'total-local',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Total cost with local authority charges",
            nsv: taxExcludingTotalNsv,
            cogs: taxExcludingTotalCogs + localAuthorityCharges,
            gsv: taxExcludingTotalGsv + localAuthorityCharges,
            nsvPercentage: (taxExcludingTotalNsv / (taxExcludingTotalGsv + localAuthorityCharges)) * 100,
            cogsPercentage: cogsTotalLocalPerSqft,
            gsvPercentage: gsvTotalLocalPerSqft
        });

        let bocwTotal = 0;
        let bocwName = "BOCW Charges";
        if (
            project.projectDetails?.bocwApplicable &&
            project.projectDetails?.bocwApplicableRate > 0
        ) {
            bocwName = "BOCW Charges @ " + project.projectDetails?.bocwApplicableRate + " %"
            bocwTotal = (overAllTotal * project.projectDetails?.bocwApplicableRate) / 100;
            overAllTotal += bocwTotal;
            overAllTotalCogs += bocwTotal;
            updatedChartData.push({
                name:
                    "BOCW Charges @ " +
                    project.projectDetails?.bocwApplicableRate +
                    "%",
                total: bocwTotal,
            });
        }

        charges.push({
            id: 'bocw',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: bocwName,
            nsv: "NA",
            cogs: bocwTotal,
            gsv: bocwTotal,
            nsvPercentage: 0,
            cogsPercentage: 0,
            gsvPercentage: 0
        });


        let cogsTotalWithoutTaxPerSqft = (taxExcludingTotalCogs + localAuthorityCharges + bocwTotal) / projectCarpetArea;
        let gsvTotalWithoutTaxPerSqft = (taxExcludingTotalGsv + localAuthorityCharges + bocwTotal) / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsTotalWithoutTaxPerSqft = (taxExcludingTotalCogs + localAuthorityCharges + bocwTotal) / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvTotalWithoutTaxPerSqft = (taxExcludingTotalGsv + localAuthorityCharges + bocwTotal) / projectBuiltUpArea;
        }

        charges.push({
            id: 'total-without-tax',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "TOTAL COST FOR PROJECT WITHOUT TAXES",
            nsv: taxExcludingTotalNsv,
            cogs: taxExcludingTotalCogs + localAuthorityCharges + bocwTotal,
            gsv: taxExcludingTotalGsv + localAuthorityCharges + bocwTotal,
            nsvPercentage: (taxExcludingTotalNsv / (taxExcludingTotalGsv + localAuthorityCharges + bocwTotal)) * 100,
            cogsPercentage: cogsTotalWithoutTaxPerSqft,
            gsvPercentage: gsvTotalWithoutTaxPerSqft
        });

        let taxTotal = (subTotalForTax * 18) / 100;
        overAllTotal += taxTotal;
        overAllTotalCogs += taxTotal;
        updatedChartData.push({name: "Total Taxes @ 18%", total: taxTotal});

        charges.push({
            id: 'taxes',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Taxes @ 18 %",
            nsv: "NA",
            cogs: taxTotal,
            gsv: taxTotal,
            nsvPercentage: 0,
            cogsPercentage: 0,
            gsvPercentage: 0
        });

        let cogsTotalPerSqft = overAllTotalCogs / projectCarpetArea;
        let gsvTotalPerSqft = overAllTotal / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsTotalPerSqft = overAllTotalCogs / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvTotalPerSqft = overAllTotal / projectBuiltUpArea;
        }

        charges.push({
            id: 'total',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Total Cost Of Projects With Taxes",
            nsv: overAllTotal - overAllTotalCogs,
            cogs: overAllTotalCogs,
            gsv: overAllTotal,
            nsvPercentage: ((overAllTotal - overAllTotalCogs) / overAllTotal) * 100,
            cogsPercentage: cogsTotalPerSqft,
            gsvPercentage: gsvTotalPerSqft
        });

        setChartData({
            labels: updatedChartData.map((chartData: any) => chartData.name),
            datasets: [
                {
                    data: updatedChartData.map((chartData: any) =>
                        (
                            ((chartData.total as number) / (overAllTotal as number)) *
                            100
                        ).toFixed(1)
                    ),
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 0.2,
                },
            ],
        });

        setSummaryDetails(charges);
    }, [project, cogsCalculationType, gsvCalculationType]);

    useEffect(() => {
        setChartOptions(getChartOptionsWithinDisplay());
    }, [chartData]);

    useEffect(() => {
        if (project.projectDetails == null) {
            loadProjectDetails();
        }
    }, [project]);

    if (isLoading) {
        return <Loader/>;
    }
    return (
        <Grid container spacing={2} alignItems={"flex-start"} className={"overall-summary"}>
            <Grid item xs={4}>
                {Object.keys(chartData).length > 0 && (
                    <Box
                        className="chart-container"
                        sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid lightgray",
                            borderRadius: "2px",
                            padding: "32px",
                            m: 0,
                            height: "95%",
                        }}
                    >
                        <Typography
                            style={{
                                textAlign: "center",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <Typography
                                component={"span"}
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    display: "inline-block",
                                    wordWrap: "break-word",
                                }}
                            >
                                {mainText}
                            </Typography>
                        </Typography>
                        <Doughnut
                            id={"sm-doughnut-chart-project"}
                            data={chartData}
                            options={chartOptions}
                        />
                    </Box>
                )}
            </Grid>

            <Grid item xs sx={{height: "80%"}}>
                <Box>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}/>
                                <Grid item xs={6}
                                      sx={{borderTop: 1, borderColor: '#E9EBF9', backgroundColor: '#E9EBF9'}}>
                                    <Typography sx={{textAlign: 'center', fontWeight: 600, py: 2}}>
                                        GSV
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}/>
                                <Grid item xs={3}
                                      sx={{py: 1, px: 0.5, borderBottom: 1, borderColor: '#E9EBF9', backgroundColor: '#E9EBF9'}}
                                      className={"vertically-center-aligned"}>
                                    Amount
                                </Grid>
                                <Grid item xs={3}
                                      sx={{py: 1, px: 0.5, borderBottom: 1, borderColor: '#E9EBF9', backgroundColor: '#E9EBF9'}}>
                                    <Typography>Rate / Sq Ft.</Typography>
                                    <FormControl fullWidth>
                                        <NativeSelect
                                            onChange={(event: any) => onGsvCalculationChangeHandler(event)}
                                            defaultValue={gsvCalculationType}
                                            className={"gsv-select"}
                                            inputProps={{
                                                name: 'age',
                                                id: 'uncontrolled-native',
                                            }}
                                            sx={{
                                                p: 0,
                                                borderBottom: '0', color: 'blue',
                                                '& .MuiSvgIcon-root': {
                                                    color: 'blue'
                                                },
                                                '&:before': {
                                                    border: 'none'
                                                },
                                                '&:after': {
                                                    border: 'none'
                                                },
                                                '&:not(.Mui-disabled):hover::before': {
                                                    border: 'none'
                                                },
                                            }}
                                        >
                                            <option value={"carpet"}>Carpet Area</option>
                                            <option value={"built"}>Built Up Area</option>
                                        </NativeSelect>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        {summaryDetails.map((summaryDetail: any, summaryIndex: number) => {
                            return (
                                <Grid item xs={12} key={"summary-charges-" + summaryIndex}>
                                    <Grid container alignItems={"stretch"}
                                          className={"vertically-center-aligned"}>
                                        <Grid className={"here"} item xs={6} sx={{p: 0.5}}/>
                                        <Grid item xs={3}
                                              sx={{p: 0.5, backgroundColor: '#E9EBF9'}}/>
                                        <Grid item xs={3} sx={{p: 0.5, backgroundColor: '#E9EBF9'}}/>
                                        <Grid item xs={6}
                                              sx={{backgroundColor: summaryDetail.id == 'total' ? '#E9EBF9' : '#F8F8F8'}}>
                                            <Typography
                                                sx={{fontSize: "12px", fontWeight: 600, textAlign: "start", py: 1, px: 0.5, height: '100%'}}>
                                                {summaryDetail.name}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={3}
                                              sx={{backgroundColor: '#E9EBF9'}}>
                                            <Typography sx={{py: 1, height: '100%', px: 1, fontSize: '12px'}}
                                                        className={"vertically-center-aligned"}>
                                                {getPriceInFormatted(summaryDetail.gsv, project.country)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}
                                              sx={{textAlign: 'center', backgroundColor: '#E9EBF9'}}>
                                            <Typography sx={{py: 1, height: '100%', px: 1, fontSize: '12px'}}
                                                        className={"vertically-center-aligned"}>
                                                {getPriceInFormatted(Number.parseFloat(summaryDetail.gsvPercentage), project.country)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
                {/*{!betaEnabled ? (*/}
                {/*    <>*/}
                {/*        {(props.project.bigFishProjectId && props.revision.status == 'PENDING' && props.revision.downloaded) &&*/}
                {/*            <Box className={"right-align-content"} sx={{py: 2}}>*/}
                {/*                <Button variant={"contained"} onClick={approveEstimation}>Approve Estimation</Button>*/}
                {/*            </Box>*/}
                {/*        }*/}
                {/*        {(!props.project.bigFishProjectId && props.revision.status == 'PENDING' && props.revision.downloaded) &&*/}
                {/*            <Box className={"right-align-content"} sx={{py: 2}}>*/}
                {/*                <Button variant={"contained"} onClick={updateMapProject}>Map Bigfish Project</Button>*/}
                {/*            </Box>*/}
                {/*        }*/}
                {/*    </>*/}
                {/*) : (*/}
                    <>
                        {(revision.status == 'PENDING' && revision.downloaded) &&
                            <Box className={"right-align-content"} sx={{py: 2}}>
                                <Button variant={"contained"} onClick={approveEstimation}>Approve Estimation</Button>
                            </Box>
                        }
                    </>
                {/*)}*/}
                {openMapProject &&
                <MapProjectPopup
                    handlePopupCallBack={handlePopupCallBack}
                    updateProjectDetails={updateProjectDetails}
                    openPopup={openMapProject}
                    packageTotal={0}
                    projectId={project.id}
                />
            }
            </Grid>
        </Grid>
    );
}

export default NewClientSummary;