import React, {useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FieldComparison from "./FieldComparison";
import {getParameterInputDetailsForComparison} from "./ParameterInputDetails";

const styles = {
    fieldComparison: {
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        py:2
    }
}
const ParameterCategoryComparison = (props: any) => {
    const [inputDetails, setInputDetails] = useState<any>([]);
    const [expandGroup, setExpandGroup] = useState<any>({});

    const handleExpand = (groupName: any) => {
        const newExpandGroup = {...expandGroup, [groupName]: !expandGroup[groupName]};
        setExpandGroup(newExpandGroup);
    }

    const allEqual = (arr: any[]) => arr.every(v => v === arr[0])

    useEffect(() => {
        if (inputDetails.length > 0) {
            let firstGroup = inputDetails[0].id;
            handleExpand(firstGroup);
        }
    }, [inputDetails]);

    useEffect(() => {
        const data = getParameterInputDetailsForComparison(props.category);
        if(data!=null) {
            data.map((groupDetails: any) => {
                if (groupDetails.hasOwnProperty("groupName")) {
                    groupDetails.id = groupDetails.groupName.replace(/[^A-Z0-9]/ig, "_").toLowerCase();
                }
                groupDetails.commonValues = true;
                groupDetails.fields.map((field: any) => {
                    let allFieldValues: any[] = [];
                    let fieldDetails: any = {};
                    Object.keys(props.selectedParameter).map((revisionId: string) => {
                        Object.keys(props.selectedParameter[revisionId]).map((parameterField: any) => {
                            if (parameterField == field.name) {
                                fieldDetails[revisionId] = props.selectedParameter[revisionId][parameterField];
                                allFieldValues.push(props.selectedParameter[revisionId][parameterField]);
                            }
                        });
                    });
                    field.revisions = fieldDetails;
                    field.commonValues = true;
                    if (!allEqual(allFieldValues)) {
                        field.commonValues = false;
                        groupDetails.commonValues = false;
                    }

                });
            });

            setInputDetails(data);
        }
    }, [props.selectedParameter]);

    return (
        <>
            {inputDetails.length > 0 && inputDetails.map((parameterDetails: any, index: number) => {
                if (!props.hideCommon || (props.hideCommon && !parameterDetails.commonValues)) {
                    if (parameterDetails.hasOwnProperty("groupName")) {
                        return (
                            <Box key={props.category + "-comparison-" + index}>
                                <Box onClick={() => handleExpand(parameterDetails.id)} className={"vertically-center-aligned"} sx={{
                                    p: 2,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor: "#E9EBF9",
                                    cursor: 'pointer'
                                }}>
                                    <Typography sx={{fontWeight: 700}}>{parameterDetails.groupName}</Typography>
                                    <Button sx={{py: 0}}>
                                        {expandGroup[parameterDetails.id] ? (
                                            <KeyboardArrowUpIcon/>
                                        ) : (
                                            <ExpandMoreIcon/>
                                        )}
                                    </Button>
                                </Box>
                                <Box sx={styles.fieldComparison} className={"field-comparison"}>
                                    {expandGroup[parameterDetails.id] && parameterDetails.fields.map((field: any, fieldIndex: number) => {
                                        return (
                                            <FieldComparison
                                                key={props.category + "-" + fieldIndex + "-field" + index}
                                                field={field} index={fieldIndex} hideCommon={props.hideCommon}
                                            />
                                        )

                                    })}
                                </Box>
                            </Box>
                        )
                    } else {
                        return (
                            <Box sx={styles.fieldComparison} key={props.category + "-comparison-" + index} className={"field-comparison"}>
                                {parameterDetails.fields.map((field: any, fieldIndex: number) => {
                                    return (
                                        <FieldComparison
                                            key={props.category + "-" + fieldIndex + "-field" + index}
                                            field={field} index={fieldIndex} hideCommon={props.hideCommon}
                                        />
                                    )
                                })}
                            </Box>
                        )
                    }
                }
            })}
        </>
    )
}
export default ParameterCategoryComparison;