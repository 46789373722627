import {Box, Typography} from "@mui/material";

export const pageConfig = {
    columns: [
        {field: 'id', headerName: '#', headerClassName: 'data-grid-header', sortable: false, flex: 1},
        {field: 'name', headerName: 'Vendor Name', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'owner', headerName: 'Owner Name', headerClassName: 'data-grid-header', flex: 1,},
        // {field: 'year', headerName: 'Incorporated Year', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'city', headerName: 'City', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'country', headerName: 'Country', headerClassName: 'data-grid-header', flex: 1},
        {field: 'status', type: 'status', headerName: 'Active?', headerClassName: 'data-grid-header', flex: 1},
        {
            field: 'action', headerName: 'Action',
            headerClassName: 'data-grid-header data-grid-right-aligned-header',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            flex: 1,
            show: 'button',
            buttons: [
                {
                    type: 'view',
                    title: 'View',
                    variant: 'text',
                    permissions: ['view-vendors'],
                    condition: (row: any) => true
                },
                {
                    type: 'edit',
                    title: 'Edit',
                    permissions: ['edit-vendors'],
                    condition: (row: any) => true 
                }
            ]
        }
    ]
};

export const vendorSkuConfig = {
        columns: [
            {
                field: 'imageWithName',
                headerName: 'Sku Name',
                headerClassName: 'data-grid-header',
                flex: 1.5,
                sortable: false,
                key: 'url',
                type: 'imageWithName'
            },
            {field: 'description', headerName: 'Description', headerClassName: 'data-grid-header', flex: 1,},
            {
                field: "specs",
                headerName: "Specifications",
                sortable: false,
                headerClassName: "data-grid-header",
                flex: 1,
                renderCell: (params: any) => {
                    return (
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                maxHeight: '100px', 
                                overflowY: 'auto', 
                                whiteSpace: 'normal',
                                display: 'block',
                            }}
                        >
                            {params.row.options && params.row.options.length > 0 ? (
                                params.row.options.map((option: any, idx: number) => {
                                    return (
                                        <Typography
                                            key={idx}
                                            variant={"body2"}
                                            sx={{
                                                fontSize: "0.8rem",
                                                padding: '0.4rem',
                                                wordWrap: 'break-word',
                                            }}
                                        >
                                            <strong>{option.name}:</strong> {option.value}
                                        </Typography>
                                    );
                                })
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: "0.8rem",
                                        padding: '0.4rem',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    NA
                                </Typography>
                            )}
                        </Box>
                    );
                }
                
            },
            {field: 'code', headerName: 'SKU Code', headerClassName: 'data-grid-header', flex: 1,},
            {field: 'uom', headerName: 'UOM', headerClassName: 'data-grid-header', flex: 0.5,},
            {field: 'status', type: 'status', headerName: 'Active?', headerClassName: 'data-grid-header', flex: 0.6,},
            {
                field: 'action', headerName: 'Action', headerClassName: 'data-grid-header', flex: 0.8, show: 'button', sortable: false, buttons: [
                    {
                        type: 'anchor',
                        title: 'View Details',
                        permissions: ['vendor-super-admin', 'vendor-admin'],
                        condition: (row: any) => true
                    },
                ]
            }
        ]
    
}