import {Box, Modal, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import CloseIcon from "@mui/icons-material/Close";
import BuildForm from "../../components/input-form/BuildForm";
import {api} from "../../utils/Axios";
import {getStyle} from "../../utils/ModalUtils";

const CreateBrandPopup = (props: any) => {
    const style = getStyle(50, 75, 0, 0);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [open, setOpen] = useState(false);

    const handleClose = (data: any) => {
        setOpen(false);
        props.handleCreatePopupCallBack(data);
    }

    const onSubmitHandler = (form: any, callback: any) => {
        setIsLoading(true);
        api.post("/procurement/brand/create", form).then((response) => {
            setIsLoading(false);
            handleClose({event: 'save'});
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
        callback();
    }

    useEffect(() => {
        if (props.openPopup == true) {
            setOpen(true);
        }
    }, [props.openPopup]);


    return (
        <Modal
            open={open}
            onClose={() => handleClose({event: 'close'})}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                {isLoading &&
                    <Loader/>
                }

                <Box sx={style.header}>
                    <Box
                        sx={style.headerContainer}>
                        <Box>
                            <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                Create New Brand
                            </Typography>
                        </Box>
                        <Box sx={{ml: 'auto'}}>
                            <CloseIcon sx={{cursor: "pointer"}} onClick={() => handleClose({event: 'close'})}/>
                        </Box>
                    </Box>
                </Box>
                <Box className={"modal-body"} sx={style.body}>
                    <Box sx={style.bodyContainer}>
                        <Box className={"common-space-container"}>
                            <BuildForm
                                buttons={[
                                    {
                                        type: 'submit',
                                        title: 'Save',
                                        permissions: ['edit-vendors']
                                    },
                                ]}
                                formArr={formFields}
                                onSubmit={onSubmitHandler}
                                handleFormChange={(form: any) => console.log(form)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
const formFields = [
    {
        element: "input",
        label: "Brand Name",
        placeholder: "Brand Name",
        name: "name",
        type: "text",
        required: true
    },
    {
        element: "input",
        label: "Contact Person Name",
        placeholder: "Contact Person Name",
        name: "contactPerson",
        type: "text",
        required: true
    },
    {
        element: "input",
        label: "Contact Person Email",
        placeholder: "Contact Person Email",
        name: "contactPersonEmail",
        type: "email",
        required: true
    },
    {
        element: "input",
        label: "Contact Person Mobile",
        placeholder: "Contact Person Mobile",
        name: "contactPersonMobile",
        type: "number",
        min: 10,
        max: 10,
        required: true
    },
    {
        element: "input",
        label: "City",
        placeholder: "City",
        name: "city",
        type: "text",
        required: true
    },
    {
        element: "input",
        label: "Country",
        placeholder: "Country",
        name: "country",
        type: "text",
        required: true
    },
    {
        element: "radio",
        label: "Status",
        name: "status",
        // value: 'ACTIVE',
        options: [
            {id: "ACTIVE", label: "ACTIVE"},
            {id: "INACTIVE", label: "INACTIVE"},
        ],
        required: true
    },
];
export default CreateBrandPopup;