import {getStyle} from "../../../utils/ModalUtils";
import React, {useEffect, useState} from "react";
import {Box, Button, Grid, InputLabel, Modal, TextField, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useAppDispatch} from "../../../app/hooks";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {ERROR_COLOR} from "../../../constants";

const AddLink = (props: any) => {
    const style = getStyle(40, 30, 15, 15);
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        props.handleAddLinkPopupCallback({event: 'close'});
    }

    const saveLink = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.target);
        if (isValidUrl(data.get("link"))) {
            props.handleAddLinkPopupCallback({event: 'add', data: data.get("link")});
        } else {
            dispatch(
                openSnackbar({
                    message: "Entered value is not valid URL or link",
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    }

    const isValidUrl = (urlString: any) => {
        let url;
        try {
            url = new URL(urlString);
        } catch (e) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}} component={"form"} onSubmit={saveLink}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Add Link
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box sx={{width: '70%', py: 1}}>
                                <InputLabel required={true} sx={{pb: 0.5, fontSize: "0.8rem"}}>
                                    Link
                                </InputLabel>
                                <Box>
                                    <TextField
                                        fullWidth
                                        size={"small"}
                                        variant={"outlined"}
                                        className={"inputBox"}
                                        name="link"
                                        required={true}
                                        defaultValue={""}
                                        InputProps={{
                                            style: {
                                                borderRadius: "5px",
                                                borderColor: 'primary.light',
                                                fontSize: "0.8rem",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={style.footer}>
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                            <Grid item>
                                <Box>
                                    <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}} onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button variant={"contained"} sx={{fontSize: "0.8rem"}} type={"submit"}>
                                        Add
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default AddLink;