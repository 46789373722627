export const allReports = {
    "catalogue": [
        {
            id: 'material-master-report',
            name: 'Download Material Master Report',
            api: '/catalogue/item-category/download/report',
            extention: '.csv',
            description: 'The SKU Report is a powerful tool designed to help businesses gain insights into individual product performance, optimize inventory management, and boost sales strategies',
            features: [
                {
                    title: 'Comprehensive SKU Overview',
                    'description': 'Get a detailed view of all your SKUs, including product name, category, stock levels, and pricing.'
                },
                {
                    title: 'Sales and Performance Tracking',
                    'description': 'Monitor each SKU\'s sales trends over specific time periods to identify high-performing products and those that need attention.'
                },
                {
                    title: 'Profitability Insights',
                    'description': 'Analyze profit margins per SKU, helping you identify which products contribute the most to your bottom line'
                },
                {
                    title: 'Actionable Insights',
                    'description': 'Use the data provided by SKU reports to make strategic decisions on pricing, product bundling, or promotional efforts.'
                },
            ]
        },
        {
            id: 'sku-report',
            name: 'Download Sku Report',
            api: '/catalogue/sku/download-all-skus',
            extention: '.csv',
            description: 'The SKU Report is a powerful tool designed to help businesses gain insights into individual product performance, optimize inventory management, and boost sales strategies',
            features: [
                {
                    title: 'Comprehensive SKU Overview',
                    'description': 'Get a detailed view of all your SKUs, including product name, category, stock levels, and pricing.'
                },
                {
                    title: 'Sales and Performance Tracking',
                    'description': 'Monitor each SKU\'s sales trends over specific time periods to identify high-performing products and those that need attention.'
                },
                {
                    title: 'Profitability Insights',
                    'description': 'Analyze profit margins per SKU, helping you identify which products contribute the most to your bottom line'
                },
                {
                    title: 'Actionable Insights',
                    'description': 'Use the data provided by SKU reports to make strategic decisions on pricing, product bundling, or promotional efforts.'
                },
            ]
        },
        {
            id: 'sku-cogs-and-gsv-price-report',
            name: 'Download Sku COGS and GSV Prices Report',
            api: '/catalogue/sku/download-cogs-and-gsv-price-report',
            extention: ".csv",
            description: 'A "SKU COGS and GSV Report" provides detailed insights into the cost and revenue performance of individual stock-keeping units (SKUs). The report typically breaks down the Cost of Goods Sold (COGS), which reflects the direct costs of producing or purchasing each SKU, alongside the Gross Sales Value (GSV), representing the total sales revenue before deductions like discounts or returns.',
            features: [
                {
                    title: 'Detailed SKU-Level Analysis',
                    'description': 'Breaks down COGS and GSV for each SKU, giving a clear picture of product profitability.'
                },
                {
                    title: 'Profit Margin Insights',
                    'description': 'Highlights profit margins by comparing COGS with GSV, helping businesses identify high-margin and underperforming products.'
                },
                {
                    title: 'Sales Performance Tracking',
                    'description': 'Track the gross sales value for each SKU, offering insights into revenue generation over a specific period.'
                },
                {
                    title: 'Inventory Insights',
                    'description': 'Tracks stock movement and inventory levels alongside sales to offer a comprehensive product performance overview.'
                },
            ]
        },
        {
            id: 'sku-e-catalog-report',
            name: 'Download Sku E Catalog Report',
            api: '/catalogue/sku/download-ecatalog-skus-price',
            extention: ".csv",
            description: 'The SKU Report is a powerful tool designed to help businesses gain insights into individual product performance, optimize inventory management, and boost sales strategies',
            features: [
                {
                    title: 'Comprehensive SKU Overview',
                    'description': 'Get a detailed view of all your SKUs, including product name, category, stock levels, and pricing.'
                },
                {
                    title: 'Sales and Performance Tracking',
                    'description': 'Monitor each SKU\'s sales trends over specific time periods to identify high-performing products and those that need attention.'
                },
                {
                    title: 'Profitability Insights',
                    'description': 'Analyze profit margins per SKU, helping you identify which products contribute the most to your bottom line'
                },
                {
                    title: 'Actionable Insights',
                    'description': 'Use the data provided by SKU reports to make strategic decisions on pricing, product bundling, or promotional efforts.'
                },
            ]
        },
        {
            id: 'sku-price-report',
            name: 'Download Sku Prices Report',
            api: '/catalogue/sku/download-price-report',
            extention: ".csv",
            description: 'This powerful tool allows you to generate a comprehensive report detailing the prices of all Stock Keeping Units (SKUs) within our catalogue. Designed to save you time and enhance your business operations, this feature offers several key benefits:',
            features: [
                {
                    title: 'Comprehensive Price Overview',
                    'description': 'Get a detailed list of prices for all SKUs for catalogue, allowing you to quickly assess and compare your pricing strategy.'
                },
                {
                    title: 'Easy Download',
                    'description': 'With just a few clicks, download the report in a convenient format (CSV) that you can easily integrate into your existing systems.'
                },
                {
                    title: 'Accurate and Up-to-Date',
                    'description': 'Ensure you\'re always working with the most current pricing information. The report pulls real-time data directly from our catalogue management system.'
                },
                {
                    title: 'Enhanced Decision Making',
                    'description': 'Use the insights gained from the report to make informed decisions about pricing adjustments, promotions, and inventory restocking.'
                },
            ]
        },
        {
            id: 'sku-max-price-report',
            name: 'Download Sku Max Prices Report',
            api: '/catalogue/sku/download-max-price-report',
            extention: '.csv',
            description: 'Effortlessly access comprehensive pricing data with "Download SKU Max Prices Report" feature. This tool enables you to download detailed reports on the maximum prices of SKUs across different regions, providing valuable insights into regional pricing strategies and market trends.',
            features: [
                {
                    title: 'Region-Specific Pricing',
                    'description': 'Obtain precise maximum price data for each SKU categorized by region, helping you identify regional pricing variations and trends.'
                },
                {
                    title: 'User-Friendly Format',
                    'description': 'Reports are available in an easy-to-read format, ensuring you can quickly analyze and utilize the data.'
                },
                {
                    title: 'Comprehensive Data Coverage',
                    'description': 'Access extensive data across all SKUs, ensuring you have a complete understanding of your pricing landscape.'
                },
                {
                    title: 'Actionable Insights',
                    'description': 'Use the detailed pricing information to make informed decisions on pricing strategies, marketing plans, and catalogue management.'
                },
            ]
        }
    ]
}