import {Box, Button, Grid, Menu, MenuItem, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import styles from "./EstimationSummary.module.css";
import SummaryContainer from "./SummaryContainer";
import {api} from "../../../utils/Axios";
import LeftContainer from "./LeftContainer";
import {ItemTypes} from "../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {
    DRAFT_PART_FAILED,
    DRAFT_PART_SUCCESSFULLY,
    DUPLICATE_PART_NAME,
    ERROR_COLOR,
    REMOVED_ITEM_FAILURE,
    REMOVED_ITEM_SUCCESS,
    REMOVED_PART_FAILURE,
    REMOVED_PART_SUCCESS,
    SUCCESS_COLOR
} from "../../../constants";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import BoqBreakupSummary from "../BoqBreakupSummary";
import PartBreakupSummary from "../PartBreakupSummary";
import OverallSummary from "../OverallSummary";
import EstimationLoader from "../EstimationLoader";
import NewClientSummary from "../client-summary/NewClientSummary";
import {MoreVert} from "@mui/icons-material";
import VendorAssignmentPopup from "../popups/vendor-assignment/VendorAssignmentPopup";

const EstimationSummary = (props: any) => {
    let counter = 0;
    const dispatch: any = useAppDispatch();
    const isError = useAppSelector((state: any) => state.boqSummary.error);
    const [boqBreakupDetails, setBoqBreakupDetails] = useState<any[]>([]);
    const [partBreakupDetails, setPartBreakupDetails] = useState<any[]>([]);
    const [assignVendorPopup, setAssignVendorPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);
    const [estimations, setEstimations] = useState<any>([]);
    const [estimationSummary, setEstimationSummary] = useState<any>([]);
    const [estimationError, setEstimationError] = useState(false);
    const [newSectionName, setNewSectionName] = useState("");
    const [sections, setSections] = useState<any>([]);
    const [publishedParts, setPublishedParts] = useState<any>([]);
    const [activeTab, setActiveTab] = useState({type: 'left', index: 0});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [rateCalculationType, setRateCalculationType] = useState("built");

    const onRateCalculationChangeHandler = (e: any) => {
        setRateCalculationType(e.target.value);
    }

    const leftAlignedTabs = [
        {
            label: 'Overall Summary',
            content: <OverallSummary
                project={props.project}
                boqBreakup={boqBreakupDetails}
                categories={props.categories}
                rateCalculationType={rateCalculationType}
                showUpdatedCharges={props.showUpdatedCharges}
                revisionDesignType={props.revisionDesignType}
                onRateCalculationChangeHandler={onRateCalculationChangeHandler}
            />
        },
        {
            label: 'BOQ Breakup',
            content: <BoqBreakupSummary
                project={props.project}
                boqBreakup={boqBreakupDetails}
                categories={props.categories}
                rateCalculationType={rateCalculationType}
                showUpdatedCharges={props.showUpdatedCharges}
                revisionDesignType={props.revisionDesignType}
            />
        },
    ];

    const rightAlignedTabs = [
        {
            label: 'Client Summary',
            content: <NewClientSummary
                project={props.project}
                revision={props.revision}
                publishedParts={publishedParts}
                partBreakup={partBreakupDetails}
                showUpdatedCharges={props.showUpdatedCharges}
                refreshRevision={props.refreshRevision}
            />
        },
        {
            label: 'Client Breakup',
            content: <PartBreakupSummary
                project={props.project}
                partBreakup={partBreakupDetails}
                showUpdatedCharges={props.showUpdatedCharges}
            />
        },
    ];

    const handleVendorMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleVendorClose = () => {
        setAnchorEl(null);
    }

    const handleAssignVendorRule = () => {
        setAssignVendorPopup(true);
    }

    const handleVendorPopupCallBack = (data: any) => {
        setAssignVendorPopup(false);
        if (data.vendorUpdated) {
            loadEstimationDetails();
        }
    }

    const handleDrop = async (itemType: any, section: any) => {
        const items = [...section.items];
        const data: any[] = [];

        switch (itemType.type) {
            case ItemTypes.CATEGORY:
                props.categories.map((category: any) => {
                    if (category.id == itemType.id) {
                        category.subCategories.map((subCategory: any) => {
                            estimations?.map((estimation: any) => {
                                if (estimation.materialSubCategoryId === subCategory.id) {
                                    estimation.estimationItems.map((estimationItem: any) => {
                                        const estimationItemSkuIds: any[] = [];
                                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                            let partAvailable = false;
                                            if (items.length > 0) {
                                                items.map((partItem: any) => {
                                                    if (partItem.id == estimationItemSku.id) {
                                                        partAvailable = true;
                                                    }
                                                });
                                            }
                                            if (!partAvailable) {
                                                estimationItemSkuIds.push(estimationItemSku.id);
                                                estimationItemSku.disabled = true;
                                                items.push({
                                                    ...estimationItemSku, materialSubCategoryId: subCategory.id
                                                });
                                            }
                                        });

                                        data.push({
                                            id: estimationItem.id,
                                            skus: estimationItemSkuIds
                                        });
                                    });
                                }
                            });
                        });
                    }
                });
                break;
            case ItemTypes.SUBCATEGORY:
                props.categories.map((category: any) => {
                    category.subCategories.map((subCategory: any) => {
                        if (subCategory.id == itemType.id) {
                            estimations?.map((estimation: any) => {
                                if (estimation.materialSubCategoryId === subCategory.id) {
                                    estimation.estimationItems.map((estimationItem: any) => {
                                        const estimationItemSkuIds: any[] = [];
                                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                            let partAvailable = false;
                                            if (items.length > 0) {
                                                items.map((partItem: any) => {
                                                    if (partItem.id == estimationItemSku.id) {
                                                        partAvailable = true;
                                                    }
                                                });
                                            }
                                            if (!partAvailable) {
                                                estimationItemSkuIds.push(estimationItemSku.id);
                                                estimationItemSku.disabled = true;
                                                items.push(estimationItemSku);
                                            }
                                        });
                                        data.push({
                                            id: estimationItem.id,
                                            skus: estimationItemSkuIds
                                        });
                                    });
                                }
                            });
                        }
                    });
                });
                break;
            case ItemTypes.ITEM:
                estimations?.map((estimation: any) => {
                    estimation.estimationItems.map((estimationItem: any) => {
                        if (estimationItem.id == itemType.id) {
                            const estimationItemSkuIds: any[] = [];
                            estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                let partAvailable = false;
                                if (items.length > 0) {
                                    items.map((partItem: any) => {
                                        if (partItem.id == estimationItemSku.id) {
                                            partAvailable = true;
                                        }
                                    });
                                }
                                if (!partAvailable) {
                                    estimationItemSkuIds.push(estimationItemSku.id);
                                    estimationItemSku.disabled = true;
                                    items.push(estimationItemSku);
                                }
                            });
                            data.push({
                                id: estimationItem.id,
                                skus: estimationItemSkuIds
                            })
                        }
                    });
                });
                break;
            case ItemTypes.SKU:
                estimations?.map((estimation: any) => {
                    estimation.estimationItems.map((estimationItem: any) => {
                        const estimationItemSkuIds: any[] = [];
                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                            if (estimationItemSku.id == itemType.id) {
                                let partAvailable = false;
                                if (items.length > 0) {
                                    items.map((partItem: any) => {
                                        if (partItem.id == estimationItemSku.id) {
                                            partAvailable = true;
                                        }
                                    });
                                }
                                if (!partAvailable) {
                                    estimationItemSkuIds.push(estimationItemSku.id);
                                    estimationItemSku.disabled = true;
                                    items.push(estimationItemSku);
                                }
                            }
                        });
                        if (estimationItemSkuIds.length > 0) {
                            data.push({
                                id: estimationItem.id,
                                skus: estimationItemSkuIds
                            });
                        }
                    });
                });
                break;
        }
        if (items.length > 0) {
            handleDropToSummary(section.id, section.name, data, items);
            // const currentSection = await handleSaveDraft(section.id, section.name, data);
            // fetchPartSummaryDetails();
            // const sectionIndex = sections.findIndex(
            //     (currentSection: any) => currentSection.id === section.id
            // );
            //
            // if (sectionIndex !== -1) {
            //     const updatedSections = [...sections];
            //     updatedSections[sectionIndex] = {
            //         id: currentSection.id,
            //         name: currentSection.name,
            //         items: items,
            //         status: currentSection.status
            //     };
            //     setSections(updatedSections);
            // }
        }
    }

    const handleRemoveSectionItem = async (itemType: any, section: any) => {
        let items = [...section.items];
        const data: any[] = [];
        switch (itemType.type) {
            case ItemTypes.CATEGORY:
                props.categories.map((category: any) => {
                    if (category.id == itemType.id) {
                        category.subCategories.map((subCategory: any) => {
                            estimations?.map((estimation: any) => {
                                if (estimation.materialSubCategoryId === subCategory.id) {
                                    estimation.estimationItems.map((estimationItem: any) => {
                                        const estimationItemSkuIds: any[] = [];
                                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                            if (items.length > 0) {
                                                items = items.filter((partItem: any) => {
                                                    if (partItem.id !== estimationItemSku.id) {
                                                        return true;
                                                    }
                                                    estimationItemSku.disabled = false;
                                                    estimationItemSku.estimationSummaryDetailId = null;
                                                    estimationItemSkuIds.push(estimationItemSku.id);
                                                    return false;
                                                });
                                            }
                                        });
                                        if (estimationItemSkuIds.length > 0) {
                                            data.push({
                                                id: estimationItem.id,
                                                skus: estimationItemSkuIds
                                            });
                                        }
                                    });
                                }
                            });
                        });
                    }
                });
                break;
            case ItemTypes.SUBCATEGORY:
                props.categories.map((category: any) => {
                    category.subCategories.map((subCategory: any) => {
                        if (subCategory.id == itemType.id) {
                            estimations?.map((estimation: any) => {
                                if (estimation.materialSubCategoryId === subCategory.id) {
                                    estimation.estimationItems.map((estimationItem: any) => {
                                        const estimationItemSkuIds: any[] = [];
                                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                            if (items.length > 0) {
                                                items = items.filter((partItem: any) => {
                                                    if (partItem.id !== estimationItemSku.id) {
                                                        return true;
                                                    }
                                                    estimationItemSku.disabled = false;
                                                    estimationItemSku.estimationSummaryDetailId = null;
                                                    estimationItemSkuIds.push(estimationItemSku.id);
                                                    return false;
                                                });
                                            }
                                        });
                                        if (estimationItemSkuIds.length > 0) {
                                            data.push({
                                                id: estimationItem.id,
                                                skus: estimationItemSkuIds
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                });
                break;
            case ItemTypes.ITEM:
                estimations?.map((estimation: any) => {
                    estimation.estimationItems.map((estimationItem: any) => {
                        if (estimationItem.id == itemType.id) {
                            const estimationItemSkuIds: any[] = [];
                            estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                if (items.length > 0) {
                                    items = items.filter((partItem: any) => {
                                        if (partItem.id !== estimationItemSku.id) {
                                            return true;
                                        }
                                        estimationItemSku.disabled = false;
                                        estimationItemSku.estimationSummaryDetailId = null;
                                        estimationItemSkuIds.push(estimationItemSku.id);
                                        return false;
                                    });
                                }
                            });
                            if (estimationItemSkuIds.length > 0) {
                                data.push({
                                    id: estimationItem.id,
                                    skus: estimationItemSkuIds
                                });
                            }
                        }
                    });
                });
                break;
            case ItemTypes.SKU:
                estimations?.map((estimation: any) => {
                    estimation.estimationItems.map((estimationItem: any) => {
                        const estimationItemSkuIds: any[] = [];
                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                            if (items.length > 0 && estimationItemSku.id == itemType.id) {
                                items = items.filter((partItem: any) => {
                                    if (partItem.id !== estimationItemSku.id) {
                                        return true;
                                    }
                                    estimationItemSku.disabled = false;
                                    estimationItemSku.estimationSummaryDetailId = null;
                                    estimationItemSkuIds.push(estimationItemSku.id);
                                    return false;
                                });
                            }
                        });
                        if (estimationItemSkuIds.length > 0) {
                            data.push({
                                id: estimationItem.id,
                                skus: estimationItemSkuIds
                            });
                        }
                    });
                });
                break;
        }

        handleItemRemoveFromSummary(section, data, items);
    }

    const handleItemRemoveFromSummary = (section: any, removedItemsData: any, items: any) => {
        setIsLoading(true);

        const form = {items: removedItemsData, name: ""};
        api.post(`/procurement/estimation/remove-from-estimation/${props.project.id}/${section.id}`, form).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response.hasOwnProperty("id") && response.id != null) {
                dispatch(
                    openSnackbar({
                        message: REMOVED_ITEM_SUCCESS,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                fetchPartSummaryDetails();
                const sectionIndex = sections.findIndex(
                    (currentSection: any) => currentSection.id === section.id
                );

                if (sectionIndex !== -1) {
                    const updatedSections = [...sections];
                    updatedSections[sectionIndex] = {
                        id: response.id,
                        name: response.name,
                        items: items,
                        status: response.status
                    };
                    setSections(updatedSections);
                }
            }
        }).catch(ex => {
            console.log(ex);
            dispatch(
                openSnackbar({
                    message: REMOVED_ITEM_FAILURE,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleDropToSummary = (sectionId: any, sectionName: string, data: any[], items: any) => {
        setIsLoading(true);

        const publishedPartPayload = {
            id: sectionId,
            name: sectionName,
            items: data,
        };
        api.post(`/procurement/estimation/draft-estimation/${props.revision.id}`, publishedPartPayload).then((response) => {
            return response.data;
        }).then((response: any) => {
            fetchPartSummaryDetails();
            const sectionIndex = sections.findIndex(
                (currentSection: any) => currentSection.id === sectionId
            );

            if (sectionIndex !== -1) {
                const updatedSections = [...sections];
                updatedSections[sectionIndex] = {
                    id: response.id,
                    name: response.name,
                    items: items,
                    status: response.status
                };
                setSections(updatedSections);
            }
            dispatch(
                openSnackbar({
                    message: DRAFT_PART_SUCCESSFULLY,
                    backgroundColor: SUCCESS_COLOR,
                })
            );
        }).catch(ex => {
            dispatch(
                openSnackbar({
                    message: DRAFT_PART_FAILED,
                    backgroundColor: ERROR_COLOR,
                })
            );
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleCreateSection = () => {
        if (newSectionName.trim()) {
            let sectionExists = false;
            sections.map((section: any) => {
                if (!sectionExists && section.name.toLowerCase() == newSectionName.toLowerCase()) {
                    sectionExists = true;
                }
            });
            if (!sectionExists) {
                counter += 1;
                setSections((prevSection: any) => [{
                    id: counter,
                    name: newSectionName,
                    status: 'DRAFT',
                    items: []
                }, ...prevSection]);
                setNewSectionName("");
            } else {
                dispatch(openSnackbar({
                    message: DUPLICATE_PART_NAME,
                    backgroundColor: ERROR_COLOR,
                }))
            }
        }
    };

    const handleRemovePart = async (id: any) => {
        setIsLoading(true);

        api.get(`/procurement/estimation/remove-estimation-summary/${id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response) {
                fetchPartSummaryDetails();
                let filter = sections.filter((section: any) => section.id !== id);
                setSections(filter);
                dispatch(
                    openSnackbar({
                        message: REMOVED_PART_SUCCESS,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
            }
        }).catch(ex => {
            dispatch(
                openSnackbar({
                    message: REMOVED_PART_FAILURE,
                    backgroundColor: ERROR_COLOR,
                })
            );
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const fetchPartSummaryDetails = async () => {
        if (props.revision.id) {
            setIsSummaryLoading(true);

            api.get(`/procurement/estimation/get-summary/${props.revision.id}`).then((response) => {
                return response.data;
            }).then((response: any) => {
                setEstimationSummary(response);
            }).catch(ex => {
                console.log(ex);
            }).finally(() => {
                setIsSummaryLoading(false);
                setIsLoading(false);
            });
        }
    };

    const loadEstimationDetails = () => {
        setEstimations([]);
        setIsLoading(true);

        api.get(`/procurement/estimation/details/${props.revision.id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            const updatedCategories = props.categories.map((category: any) => {
                let estimationTotal: number = 0;
                let estimationTotalCogs: number = 0;
                let estimationTotalSkus = 0;
                const subCategories = category.subCategories.map((subCategory: any) => {
                    const estimation = response?.find((estimation: any) =>
                        estimation.materialSubCategoryId === subCategory.id
                    );
                    if (estimation && estimation.estimationItems.length > 0) {
                        let subCategoryTotalCogs: number = 0;
                        let subCategoryTotal: number = 0;
                        let subCategoryTotalSkus = 0;
                        const updatedEstimations = estimation.estimationItems.map((estimationItem: any) => ({
                            ...estimationItem,
                            estimationItemSkus:
                                estimationItem.estimationItemSkus.length > 0 &&
                                estimationItem.estimationItemSkus.map((sku: any) => {
                                    let targetPrice = parseFloat(sku.targetPrice) + parseFloat(sku.cogsDomainChargesPerUnit?.total);
                                    if (props.showUpdatedCharges && !sku.manuallyEdited) {
                                        if (category.code.toLowerCase() == "ff") {
                                            targetPrice = targetPrice + ((targetPrice * 2) / 100);
                                        } else {
                                            targetPrice = targetPrice + ((targetPrice * 6) / 100);
                                        }
                                    }

                                    let totalCogs = (parseInt(sku.totalQuantity) * targetPrice);
                                    subCategoryTotalCogs += totalCogs;
                                    subCategoryTotal = subCategoryTotal + (parseInt(sku.totalQuantity) * parseFloat(sku.sellingPrice)) + (parseInt(sku.totalQuantity) * parseFloat(sku?.gsvDomainChargesPerUnit?.total));
                                    subCategoryTotalSkus++;
                                    return {...sku, targetPrice: targetPrice};
                                }),
                        }));

                        estimationTotal += subCategoryTotal;
                        estimationTotalCogs += subCategoryTotalCogs;
                        estimationTotalSkus += subCategoryTotalSkus;

                        return {
                            ...subCategory,
                            total: subCategoryTotal,
                            totalCogs: subCategoryTotalCogs,
                            estimationTotalSkus: subCategoryTotalSkus,
                            estimationItems: updatedEstimations,
                        };
                    }
                    return null;
                }).filter((subCategory: any) => subCategory !== null);
                subCategories.sort((a: any, b: any) => a.total - b.total);

                if (subCategories.length > 0) {
                    return {
                        ...category,
                        total: estimationTotal,
                        totalCogs: estimationTotalCogs,
                        estimationTotalSkus,
                        subCategories,
                    };
                }
                return null;
            }).filter((category: any) => category !== null);

            setBoqBreakupDetails(updatedCategories);
            setEstimations(response);
            //setIsLoading(false);
            fetchPartSummaryDetails();
        }).catch(ex => {
            console.log(ex);
            setEstimationError(true);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadEstimationDetails();
    }, [props.revision]);

    useEffect(() => {
        if (estimations.length > 0) {
            estimations.map((estimation: any) => {
                estimation.estimationItems.map((estimationItem: any) => {
                    estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                        if (!estimationItemSku.hasOwnProperty("disabled")) {
                            estimationItemSku.disabled = estimationItemSku.estimationSummaryDetailId != null;
                        }
                    });
                });
            });
        }
    }, [estimations]);

    useEffect(() => {
        setPublishedParts([]);
        setSections([]);
        if (Object.keys(estimationSummary).length > 0) {
            const savedSections: any[] = [];
            const publishedSections: any[] = [];
            estimationSummary.parts.map((section: any) => {
                // if (props.revision.status == 'APPROVED') {
                publishedSections.push({
                    id: section.id,
                    name: section.name,
                    status: section.status,
                    items: section.items,
                    total: section.total,
                    totalSkus: section.totalSkus
                });
                // }
                savedSections.push({
                    id: section.id,
                    name: section.name,
                    status: section.status,
                    items: section.items,
                    total: section.total,
                    totalSkus: section.totalSkus
                });
            });
            setSections(savedSections);
            if (publishedSections.length > 0 && estimations.length > 0) {
                const structuredDetails: any[] = publishedSections.map((part: any) => {
                    let partTotal: number = 0;
                    let partTotalCogs: number = 0;

                    const combinedSubCategories: any[] =
                        props.categories &&
                        props.categories.length > 0 &&
                        props.categories.flatMap((category: any) => {
                            return (
                                category.subCategories &&
                                category.subCategories.length > 0 &&
                                category.subCategories.flatMap((subCategory: any) => {
                                    const estimation: any = estimations.find(
                                        (estimation: any) =>
                                            estimation.materialSubCategoryId === subCategory.id
                                    );

                                    if (estimation) {
                                        let subCategoryTotal: number = 0;
                                        let subCategoryTotalSkus: number = 0;
                                        let totalCogs = 0;
                                        const matchedEstimationItems: any[] =
                                            estimation &&
                                            estimation.estimationItems &&
                                            estimation.estimationItems.length > 0 &&
                                            estimation.estimationItems.flatMap(
                                                (estimationItem: any) => {
                                                    const matchedSkus: any[] =
                                                        estimationItem.estimationItemSkus.filter(
                                                            (sku: any) =>
                                                                part &&
                                                                part.items &&
                                                                part.items.length > 0 &&
                                                                part.items.some(
                                                                    (item: any) => item.skuId === sku.skuId
                                                                )
                                                        );

                                                    if (matchedSkus.length > 0) {
                                                        subCategoryTotalSkus += matchedSkus.length;
                                                        return {
                                                            ...estimationItem,
                                                            estimationItemSkus:
                                                                matchedSkus &&
                                                                matchedSkus.length > 0 &&
                                                                matchedSkus.map((sku: any) => {
                                                                    let targetPrice = parseFloat(sku.targetPrice) + parseFloat(sku.cogsDomainChargesPerUnit?.total);
                                                                    if (props.showUpdatedCharges && !sku.manuallyEdited) {
                                                                        if (category.code.toLowerCase() == "ff") {
                                                                            targetPrice = targetPrice + ((targetPrice * 2) / 100);
                                                                        } else {
                                                                            targetPrice = targetPrice + ((targetPrice * 6) / 100);
                                                                        }
                                                                    }
                                                                    subCategoryTotal += (sku.totalQuantity * sku.sellingPrice) + (sku.totalQuantity * sku.gsvDomainChargesPerUnit.total);
                                                                    totalCogs += (sku.totalQuantity * targetPrice);
                                                                    return {...sku, targetPrice: targetPrice};
                                                                }),
                                                        };
                                                    }

                                                    return [];
                                                }
                                            );

                                        if (matchedEstimationItems.length > 0) {
                                            partTotal += subCategoryTotal;
                                            partTotalCogs += totalCogs;
                                            return {
                                                id: subCategory.id,
                                                name: subCategory.name,
                                                code: subCategory.code,
                                                status: subCategory.status,
                                                total: subCategoryTotal,
                                                totalCogs: totalCogs,
                                                estimationTotalSkus: subCategoryTotalSkus,
                                                estimationItems: matchedEstimationItems,
                                            };
                                        }
                                    }

                                    return [];
                                })
                            );
                        });

                    return {
                        id: part.id,
                        name: part.name,
                        status: part.status,
                        total: parseFloat(String(partTotal)),
                        totalCogs: parseFloat(String(partTotalCogs)),
                        totalSkus: parseInt(part.totalSkus),
                        subCategories: combinedSubCategories,
                    };
                });
                setPartBreakupDetails(structuredDetails);
            }
            setPublishedParts(publishedSections);
        }
    }, [estimationSummary]);

    if (isLoading || isSummaryLoading) {
        return <EstimationLoader estimationError={estimationError}/>
    }

    const getActiveContent = () => {
        if (activeTab.type === 'left') {
            return leftAlignedTabs[activeTab.index].content;
        } else {
            return rightAlignedTabs[activeTab.index].content;
        }
    };

    return (
        <Box>
            <Box>
                <Box display={"flex"} justifyContent="space-between" alignItems="center">
                    <Box padding={"16px 0"}>
                        <Typography
                            sx={{fontSize: "18px", fontWeight: 600, lineHeight: "21px"}}
                        >
                            Estimation Details
                        </Typography>
                    </Box>
                    {props.revision.status == 'PENDING' &&
                        <Box display={"flex"} mb={2}>
                            <TextField
                                variant="outlined"
                                size={"small"}
                                value={newSectionName}
                                onChange={(e) => setNewSectionName(e.target.value)}
                                placeholder=""
                                autoFocus
                                sx={{
                                    "& .MuiInputBase-root": {mr: 2, display: "flex", alignItems: "center"},
                                    "& .MuiInputBase-input": {textAlign: "start", fontSize: "12px"},
                                }}
                            />
                            <Button onClick={handleCreateSection} variant={"contained"} size={"small"}>
                                Add New Part +
                            </Button>
                        </Box>
                    }
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs className={`left-summary-container`} sx={{overflow: 'auto'}}>
                        <Box className={styles.section}>
                            {estimations.length > 0 &&
                                <LeftContainer
                                    showUpdatedCharges={props.showUpdatedCharges}
                                    project={props.project}
                                    categories={props.categories}
                                    estimations={estimations}
                                />
                            }
                        </Box>
                    </Grid>
                    <Grid item xs className={`right-summary-container`} sx={{overflow: 'auto'}}>
                        <Box className={styles.section}>
                            <Box sx={{display: 'block', width: '100%'}}>
                                {estimations.length > 0 && sections.map((section: any, sectionIndex: number) => {
                                    return (
                                        <SummaryContainer
                                            key={"right-container-items" + sectionIndex}
                                            section={section}
                                            index={sectionIndex}
                                            project={props.project}
                                            categories={props.categories}
                                            estimations={estimations}
                                            showUpdatedCharges={props.showUpdatedCharges}
                                            handleRemovePart={handleRemovePart}
                                            handleDrop={handleDrop}
                                            handleRemoveSectionItem={handleRemoveSectionItem}
                                        />
                                    )
                                })}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{margin: "32px 0"}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Box sx={{display: 'flex'}}>
                            {leftAlignedTabs.map((tab, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setActiveTab({type: 'left', index})}
                                    sx={{
                                        borderRadius: '0',
                                        borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderTopRightRadius: index === leftAlignedTabs.length - 1 ? '0.5rem' : '0',
                                        borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderBottomRightRadius: index === leftAlignedTabs.length - 1 ? '0.5rem' : '0',
                                        bgcolor: activeTab.type === 'left' && activeTab.index === index ? 'primary.main' : 'background.default',
                                        color: activeTab.type === 'left' && activeTab.index === index ? 'primary.contrastText' : 'text.primary',
                                        '&:hover': {
                                            bgcolor: activeTab.type === 'left' && activeTab.index === index ? 'primary.dark' : 'background.paper',
                                        },
                                        '&.active': {
                                            fontWeight: 'bold',
                                        },
                                        '&:not(:last-child)': {
                                            marginRight: '1px',
                                        },
                                    }}
                                    variant={activeTab.type === 'left' && activeTab.index === index ? 'contained' : 'outlined'}
                                    className={activeTab.type === 'left' && activeTab.index === index ? 'active' : ''}
                                >
                                    {tab.label}
                                </Button>
                            ))}
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            {rightAlignedTabs.map((tab, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setActiveTab({type: 'right', index})}
                                    sx={{
                                        borderRadius: '0',
                                        borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderTopRightRadius: index === rightAlignedTabs.length - 1 ? '0.5rem' : '0',
                                        borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderBottomRightRadius: index === rightAlignedTabs.length - 1 ? '0.5rem' : '0',
                                        bgcolor: activeTab.type === 'right' && activeTab.index === index ? 'primary.main' : 'background.default',
                                        color: activeTab.type === 'right' && activeTab.index === index ? 'primary.contrastText' : 'text.primary',
                                        '&:hover': {
                                            bgcolor: activeTab.type === 'right' && activeTab.index === index ? 'primary.dark' : 'background.paper',
                                        },
                                        '&.active': {
                                            fontWeight: 'bold',
                                        },
                                        '&:not(:last-child)': {
                                            marginRight: '1px',
                                        },
                                    }}
                                    variant={activeTab.type === 'right' && activeTab.index === index ? 'contained' : 'outlined'}
                                    className={activeTab.type === 'right' && activeTab.index === index ? 'active' : ''}
                                >
                                    {tab.label}
                                </Button>
                            ))}
                            <Box>
                                <Button sx={{p: 0, py: 1, m: 0, minWidth: '20px'}} onClick={(e) => handleVendorMenu(e)}
                                        variant={"text"}
                                        endIcon={<MoreVert/>}/>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={Boolean(anchorEl)}
                                    onClose={handleVendorClose}
                                    onClick={handleVendorClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {width: 32, height: 32, ml: -0.5, mr: 1},
                                            '&::before': {content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0,},
                                        },
                                    }}
                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                >
                                    <MenuItem onClick={handleAssignVendorRule}>
                                        Assign Vendor Rule
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1rem',
                            width: '100%',
                            pr: 1,
                            py: 1,
                            backgroundColor: 'background.paper'
                        }}>
                        {getActiveContent()}
                    </Box>
                </Box>
            </Box>
            {assignVendorPopup && (
                <VendorAssignmentPopup openPopup={assignVendorPopup} project={props.project} revision={props.revision}
                                       handleVendorPopupCallBack={handleVendorPopupCallBack}/>
            )}
        </Box>
    )
}

export default EstimationSummary;