import {getStyle} from "../../../../utils/ModalUtils";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {Box, Modal, Tab, Tabs} from "@mui/material";
import PageHeading from "../../../page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../Loader";
import {api} from "../../../../utils/Axios";
import {a11yProps, CustomTabPanel} from "../../../../utils/TabsUtils";
import VendorAssignmentDetails from "./VendorAssignmentDetails";

const VendorAssignmentPopup = (props: any) => {
    const {revision, project} = props;
    const style = getStyle(70, 80, 0, 0);
    const [openPopup, setOpenPopup] = useState(false);
    const [vendorUpdated, setVendorUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tabValue, setTabValue] = useState("");
    const [categories, setCategories] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState<any>({});

    const updateVendorSelection = () => {
        setVendorUpdated(true);
    }

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        categories.map((category: any) => {
            if (category.id == newValue) {
                setSelectedCategory(category);
            }
        })
        setTabValue(newValue);
    };

    const handleClose = () => {
        setOpenPopup(false);
        props.handleVendorPopupCallBack({vendorUpdated: vendorUpdated});
    }

    const loadEstimationVendorCategories = () => {
        setIsLoading(true);

        api.get(`/procurement/estimation-vendor/categories`).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response) {
                setCategories(response);
                setTabValue(response[0].id);
                setSelectedCategory(response[0]);
            }
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const loadEstimationEntities = (type: string) => {
        setIsLoading(true);

        api.get(`/catalogue/sku/get-entities/${type}/${project.projectStudio}/${project.location}/${project.country}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response.length > 0 || type == 'brand') {
                api.post(`/procurement/${type}/list`, {ids: response}).then((entityRes) => {
                    return entityRes.data;
                }).then((res: any) => {
                    if (type == 'vendor') {
                        setVendors(res);
                    } else {
                        setBrands(res);
                    }
                }).catch(ex => {
                    console.log(ex);
                });
            }
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (props.openPopup) {
            loadEstimationEntities("vendor");
            loadEstimationVendorCategories();
            loadEstimationEntities("brand");
            setOpenPopup(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            open={openPopup}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header} style={{height: '10%'}}>
                        <Box sx={style.headerContainer}>
                            <Box sx={{width: '95%'}}>
                                <PageHeading
                                    title={"Vendor Rule Details"}
                                    // showButtons={true}
                                    // buttons={[
                                    //     {id: 'download', label: 'Download Template'},
                                    // ]}
                                    // handleHeaderButtonClick={handleHeaderButtonClick}
                                />
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            {(isLoading) &&
                                <Loader/>
                            }

                            {categories.length > 0 && (
                                <Box>
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        indicatorColor="secondary"
                                        variant={"fullWidth"}
                                        sx={{
                                            width: "70%",
                                            fontFamily: 'Lato',
                                            "& .Mui-selected": {
                                                backgroundColor: "primary.main",
                                                color: "#fff!important",
                                                fontWeight: 700,
                                            },
                                        }}
                                        aria-label="wrapped label tabs example"
                                        TabIndicatorProps={{
                                            sx: {
                                                backgroundColor: "primary.main",
                                            },
                                        }}
                                    >
                                        {categories.map((category: any, index: number) => (
                                            <Tab
                                                key={category.id + "_" + index}
                                                {...a11yProps(category.id, true)}
                                                value={category.id}
                                                label={category.name}
                                                wrapped
                                                sx={{border: 1, borderLeft: index == 0 ? 1 : 0, borderColor: "divider"}}
                                            />
                                        ))}
                                    </Tabs>

                                    <CustomTabPanel value={tabValue} index={tabValue}>
                                        <VendorAssignmentDetails
                                            vendors={vendors}
                                            brands={brands}
                                            selectedCategory={selectedCategory}
                                            revision={revision}
                                            project={project}
                                            updateVendorSelection={updateVendorSelection}
                                        />
                                    </CustomTabPanel>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default VendorAssignmentPopup;