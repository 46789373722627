import {Box, Button, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SkuTable from "../summay/SkuTable";
import BoqBreakupSubCategoryListingDetails from "./BoqBreakupSubCategoryListingDetails";

const BoqSummarySubCategoryListing = (props: any) => {
    const {category, subCategory, project} = props;
    const [expandedCategory, setExpandedCategory] = useState("");
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [subCategorySku, setSubCategorySku] = useState([]);

    const handleExpandSubCategory = (subCategoryId: any) => {
        if (expandedCategory == subCategoryId) {
            setExpandedCategory("");
            props.handleSelectedSubCategory(false, "");
        } else {
            setExpandedCategory(subCategoryId);
            props.handleSelectedSubCategory(true, subCategoryId);
        }
    };

    const toggleSortOrder = () => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const sortedSubCategories = [...category.subCategories].sort((a: any, b: any) => {
        return sortOrder === 'asc' ? a.total - b.total : b.total - a.total;
    });

    useEffect(() => {
        if (subCategory.hasOwnProperty("id")) {
            const estimationItemsAllSkus: any = [];
            subCategory.estimationItems.map((estimationItem: any) => {
                if (estimationItem.estimationItemSkus && estimationItem.estimationItemSkus.length > 0) {
                    estimationItem.estimationItemSkus.map((sku: any) => {
                        estimationItemsAllSkus.push(sku);
                    });
                }
            });
            setSubCategorySku(estimationItemsAllSkus);
            setExpandedCategory(subCategory.id);
        }
    }, [subCategory]);

    return (
        <Box>
            <Box py={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography sx={{fontSize: "16px", fontWeight: 600}}>
                    {category.name}
                </Typography>
                <Button onClick={toggleSortOrder}
                        startIcon={sortOrder === 'asc' ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}>
                    Sort by Total Price
                </Button>
            </Box>
            <Box>
                {expandedCategory == subCategory.id ? (
                    <Box sx={{py: 1}}>
                        <Box sx={{
                            border: 1,
                            borderColor: 'divider',
                            py: 0.5,
                            px: 2,
                            display: 'flex',
                            alignItems: 'stretch',
                            justifyContent: 'space-between'
                        }}>
                            <Box sx={{width: '100%'}}>
                                <BoqBreakupSubCategoryListingDetails
                                    showUpdatedCharges={props.showUpdatedCharges}
                                    revisionDesignType={props.revisionDesignType}
                                    project={project} category={category} subCategory={subCategory}
                                    totalCogs={subCategory.totalCogs}/>
                            </Box>
                            <Box className={"vertically-center-aligned"}>
                                <Button
                                    onClick={() => handleExpandSubCategory(subCategory?.id)}
                                    sx={{minWidth: "auto", p: 0}}
                                >
                                    {expandedCategory == subCategory?.id ? (
                                        <KeyboardArrowUpIcon/>
                                    ) : (
                                        <ExpandMoreIcon/>
                                    )}
                                </Button>
                            </Box>
                        </Box>
                        {expandedCategory == subCategory?.id && (
                            <Box>
                                <SkuTable skuItems={subCategorySku}
                                          showUpdatedCharges={props.showUpdatedCharges}
                                          categoryCode={category.hasOwnProperty("code") ? category.code : "NA"}/>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <>
                        {category &&
                            sortedSubCategories.length > 0 &&
                            sortedSubCategories.map((subCategory: any, idx: number) => {
                                const estimationItemsAllSkus: any = [];
                                subCategory.estimationItems.map((estimationItem: any) => {
                                    if (estimationItem.estimationItemSkus && estimationItem.estimationItemSkus.length > 0) {
                                        estimationItem.estimationItemSkus.map((sku: any) => {
                                            estimationItemsAllSkus.push(sku);
                                        });
                                    }
                                });
                                if (estimationItemsAllSkus.length > 0) {
                                    return (
                                        <Box key={"boq-breakup-sub-category-" + idx} sx={{py: 1}}>
                                            <Box sx={{
                                                border: 1,
                                                borderColor: 'divider',
                                                py: 0.5,
                                                px: 2,
                                                display: 'flex',
                                                alignItems: 'stretch',
                                                justifyContent: 'space-between'
                                            }}>
                                                <Box sx={{width: '100%'}}>
                                                    <BoqBreakupSubCategoryListingDetails
                                                        showUpdatedCharges={props.showUpdatedCharges}
                                                        revisionDesignType={props.revisionDesignType}
                                                        project={project} category={category} subCategory={subCategory}
                                                        totalCogs={subCategory.totalCogs}/>
                                                </Box>
                                                <Box className={"vertically-center-aligned"}>
                                                    <Button
                                                        onClick={() =>
                                                            handleExpandSubCategory(subCategory?.id)
                                                        }
                                                        sx={{
                                                            minWidth: "auto",
                                                            p: 0
                                                        }}
                                                    >
                                                        {expandedCategory == subCategory?.id ? (
                                                            <KeyboardArrowUpIcon/>
                                                        ) : (
                                                            <ExpandMoreIcon/>
                                                        )}
                                                    </Button>
                                                </Box>
                                            </Box>
                                            {expandedCategory == subCategory?.id && (
                                                <Box>
                                                    <SkuTable skuItems={estimationItemsAllSkus}
                                                              showUpdatedCharges={props.showUpdatedCharges}
                                                              categoryCode={category.hasOwnProperty("code") ? category.code : "NA"}/>
                                                </Box>
                                            )}
                                        </Box>
                                    );
                                }
                            })}
                    </>
                )}
            </Box>
        </Box>
    )
}
export default BoqSummarySubCategoryListing;
