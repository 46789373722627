import React, {useEffect, useState} from "react";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material";
import {Link} from "react-router-dom";
import {getPriceInFormatted} from "../../utils/Common";
import {hasAccess} from "../../utils/Access";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {useAppDispatch} from "../../app/hooks";
import {removeSkuPrices} from "./features/actions";
import {openSnackbar} from "../common/features/snackbarSlice";
import {ERROR_COLOR, REMOVED_SKU_PRICE_FAILED, REMOVED_SKU_PRICE_SUCCESSFULLY, SUCCESS_COLOR,} from "../../constants";
import SearchFilter from "../filter/SearchFilter";
import RemoveFilters from "../filter/RemoveFilters";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

const filters = {
    north: {
        element: "checkbox",
        type: "checkbox",
        label: "North",
        name: "north",
    },
    south: {
        element: "checkbox",
        type: "checkbox",
        label: "South",
        name: "south",
    },
    east: {
        element: "checkbox",
        type: "checkbox",
        label: "East",
        name: "east",
    },
    west: {
        element: "checkbox",
        type: "checkbox",
        label: "West",
        name: "west",
    },
};

const SkuPricesTable = (props: any) => {
    const dispatch = useAppDispatch();
    const [openDialogId, setOpenDialogId] = useState<number | null>(null);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [filteredSkuPrices, setFilteredSkuPrices] = useState(
        props.skuPrices || []
    );

    useEffect(() => {
        setFilteredSkuPrices(filterSkuPrices(props.skuPrices, appliedFilters));
    }, [props.skuPrices, appliedFilters]);

    const filterSkuPrices = (skuPrices: any[], filters: any) => {
        return skuPrices.filter((skuPrice) => {
            const regionFilter = Object.keys(filters).some(
                (region) => {
                    const regions = skuPrice.region.toLowerCase().replaceAll(", ", ",").split(",");
                    return filters[region] && regions.includes(region);
                }
            );
            return regionFilter || Object.keys(filters).length === 0;
        });
    };

    const handleClickOpen = (skuPriceId: number) => {
        setOpenDialogId(skuPriceId);
    };

    const handleClose = () => {
        setOpenDialogId(null);
    };

    const handleRemoveSku = async (skuPrice: any) => {
        try {
            const response = await dispatch(removeSkuPrices(skuPrice.id)).unwrap();
            if (response) {
                dispatch(
                    openSnackbar({
                        message: `${skuPrice.entityObjectName}'s ${REMOVED_SKU_PRICE_SUCCESSFULLY}`,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                props.handlePriceChange();
            }
        } catch (err) {
            dispatch(
                openSnackbar({
                    message: `${skuPrice.entityObjectName}'s ${REMOVED_SKU_PRICE_FAILED}`,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    };

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
    };

    return (
        <Box>
            <Box>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                    <Typography variant={"h5"} sx={{py: 2}}>
                        Sku Prices
                    </Typography>
                    <Box sx={{margin: '1rem 0'}}>
                        <SearchFilter
                            handleFilterChange={handleFilterChange}
                            filters={filters}
                            appliedFilters={appliedFilters}
                            showFilters={true}
                            showSearchInput={true}
                        />
                    </Box>
                </Box>
                <Box>
                    <RemoveFilters
                        handleFilterChange={handleFilterChange}
                        filters={filters}
                        appliedFilters={appliedFilters}
                    />
                </Box>
                {filteredSkuPrices.length > 0 ? (
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow
                                    sx={{
                                        th: {
                                            border: 1,
                                            borderColor: "secondary.light",
                                            backgroundColor: "primary.light",
                                            fontWeight: "700",
                                        },
                                    }}
                                >
                                    <TableCell>Entity Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>City</TableCell>
                                    <TableCell>Region</TableCell>
                                    <TableCell>RC Rate</TableCell>
                                    <TableCell>GSV</TableCell>
                                    <TableCell>NSV</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredSkuPrices.map((skuPrice: any, index: number) => {
                                    let city = skuPrice.city.replaceAll(", ", ",").replaceAll(",",  ", ");
                                    let region = skuPrice.region.replaceAll(", ", ",").replaceAll(",",  ", ");
                                    let url = `/brands/details/${skuPrice.entityId}`;
                                    if (skuPrice?.entityName === "vendor") {
                                        url = `/vendors/details/${skuPrice.entityId}`;
                                    }
                                    const nsvValue = skuPrice.sellingPrice - skuPrice.rcPrice;
                                    return (
                                        <TableRow
                                            key={`sku-pricing-${index}`}
                                            sx={{
                                                td: {border: 1, borderColor: "secondary.light"},
                                            }}
                                        >
                                            <TableCell>
                                                {hasAccess(["view-vendors"]) ? (
                                                    <Link to={url} style={{color: "unset"}}>
                                                        <Typography sx={{color: "info.main"}}>
                                                            {skuPrice?.entityObjectName}
                                                        </Typography>
                                                    </Link>
                                                ) : (
                                                    <Typography>{skuPrice?.entityObjectName}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell sx={{textTransform: "capitalize"}}>
                                                {skuPrice.entityName}
                                            </TableCell>
                                            <TableCell sx={{textTransform: "capitalize"}}>{city}</TableCell>
                                            <TableCell sx={{textTransform: "capitalize"}}>{region}</TableCell>
                                            <TableCell>
                                                {getPriceInFormatted(skuPrice.rcPrice, skuPrice?.country)}
                                            </TableCell>
                                            <TableCell>
                                                {getPriceInFormatted(skuPrice.sellingPrice, skuPrice?.country)}
                                            </TableCell>
                                            <TableCell>
                                                <Grid
                                                    container
                                                    alignItems={"center"}
                                                    justifyContent={"space-between"}
                                                >
                                                    <Grid item xs={6}>
                                                        <Typography sx={{fontSize: "0.97rem"}}>
                                                            {getPriceInFormatted(nsvValue, skuPrice?.country)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    textAlign: "right",
                                                                    fontSize: "0.7rem",
                                                                    color: "primary.main",
                                                                    opacity: "40%",
                                                                }}
                                                            >
                                                                @30%
                                                            </Typography>
                                                            <RemoveCircleOutlineOutlinedIcon
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    color: "red",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => handleClickOpen(skuPrice.id)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            {openDialogId === skuPrice.id && (
                                                <Dialog
                                                    open={true}
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle
                                                        id="alert-dialog-title"
                                                        sx={{color: "red"}}
                                                    >
                                                        {"Confirm Removal"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            Are you sure you want to remove this SKU price?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary">
                                                            No
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleRemoveSku(skuPrice)}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box sx={{textAlign: "center", py: 2}}>
                        <Typography variant="h2">No Vendor found in this Location.</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SkuPricesTable;