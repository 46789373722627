import {getStyle} from "../../../utils/ModalUtils";
import {useAppDispatch} from "../../../app/hooks";
import React, {useEffect, useMemo, useState} from "react";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {ERROR_COLOR} from "../../../constants";
import {debounce} from "@mui/material/utils";
import {api} from "../../../utils/Axios";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../Loader";
import ListPage from "../../list-page/ListPage";
import {publishPackageConfig} from "../../../modules/project/plan/config/PublishPackage";
import CreateEventPopup from "./CreateEventPopup";

interface Vendor {
    id: string;
    name: string;
}

const PublishPackage = (props: any) => {
    const style = getStyle(60, 70, 10, 10);

    const dispatch = useAppDispatch();
    const [size, setSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(1);
    const [open, setOpen] = useState(false);
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [defaultVendorId, setDefaultVendorId] = useState("");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [radioValue, setRadioValue] = useState("");
    const [selectedVendor, setSelectedVendor] = useState<any>({});
    const [vendors, setVendors] = useState([]);
    const [selectedVendors, setSelectedVendors] = useState(
        vendors ? [vendors] : []
    );
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<readonly Vendor[]>([]);
    const [value, setValue] = useState<Vendor | null>(null);
    const [openEventPopup, setOpenEventPopup] = useState(false);

    useEffect(() => {
        setSelectedVendors(vendors ? vendors : []);
    }, [vendors]);

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
            loadVendors();
        }
    }, [props.openPopup]);

    const fetchVendors = useMemo(
        () =>
            debounce(
                (query: string, callback: (results?: readonly Vendor[]) => void) => {
                    api
                        .get(`/procurement/vendor/search?query=${query}`)
                        .then((response) => response.data)
                        .then((data) => callback(data))
                        .catch(() => callback([]));
                },
                400
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }
        if (inputValue.length >= 3) {
            fetchVendors(inputValue, (results?: readonly Vendor[]) => {
                if (active) {
                    let newOptions: readonly Vendor[] = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchVendors]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    const handleVendorChange = (event: SelectChangeEvent) => {
        setDefaultVendorId(event.target.value);
    };

    const handleDirectPr = () => {
        if (selectedVendors.length > 0) {
            let directPrVendor: any = selectedVendors[0];
            setIsLoading(true);
            api.get(
                `/procurement/package/create-direct-pr/vendor/${props.package.id}/${directPrVendor.id}`
            ).then((response) => response.data).then((response: any) => {
                props.handlePopupCallBack({event: "close", prPackage: "created"});
            }).catch((ex) => {
                dispatch(
                    openSnackbar({
                        message: ex.response?.data || "Oops. Something went wrong",
                        backgroundColor: ERROR_COLOR,
                    })
                );
                console.log(ex);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const handleCreatePr = () => {
        if (Object.keys(selectedVendor).length > 0) {
            setIsLoading(true);
            api
                .get(
                    `/procurement/package/create-pr/${selectedVendor.type}/${props.package.id}/${selectedVendor.id}`
                )
                .then((response) => response.data)
                .then((response: any) => {
                    if (response) {
                        props.handlePopupCallBack({event: "close", prPackage: "created"});
                        setIsLoading(false);
                    }
                })
                .catch((ex) => {
                    dispatch(
                        openSnackbar({
                            message: ex.response?.data || "Oops. Something went wrong",
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                    console.log(ex);
                    setIsLoading(false);
                });
        }
    };

    const handleCreateEvent = () => {
        setOpenEventPopup(true);
    }

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallBack({event: "close"});
    };

    const loadVendors = () => {
        setIsLoading(true);
        api
            .get(`/procurement/package/entity-details/${props.package.id}`)
            .then((response) => response.data)
            .then((response: any) => {
                setVendors(response);
                if (props.package.type != null && props.package.type == "auto" && props.package?.autoType != null && props.package.autoType == "brand") {
                    setRadioValue("create-direct-pr")
                } else if (!props.package.event && response && response.length > 0) {
                    setRadioValue("create-pr")
                } else {
                    setRadioValue("create-event")
                }
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (vendors.length > 0) {
            const selectedVendor = vendors.filter((vendor: any) => defaultVendorId == vendor.id)
                .reduce((acc, curr) => {
                    return curr;
                }, {});
            setSelectedVendor(selectedVendor);
        }
    }, [defaultVendorId, vendors]);

    useEffect(() => {
        setSelectedVendors([]);
        setValue(null);
    }, [radioValue]);

    useEffect(() => {
        if (props.package.type == "auto" && props.package?.autoExecutedVendorId != null) {
            setDefaultVendorId(props.package?.autoExecutedVendorId);
        }
    }, [props.package]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Publish Package - {props.package.name}
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            {isLoading &&
                                <Loader/>
                            }
                            <Box>
                                <ListPage
                                    rows={[{...props.package, total: props.packageTotal}]}
                                    columns={publishPackageConfig}
                                    page={page}
                                    size={size}
                                    totalElements={totalElements}
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    handlePagination={handlePagination}
                                    handleSort={handleSort}
                                    hideFooter
                                />
                            </Box>
                            <Box padding={"16px 4px"}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={radioValue}
                                        onChange={handleRadioChange}
                                    >
                                        {!props.package.event && vendors && vendors.length > 0 &&
                                            <FormControlLabel
                                                sx={{mr: 6}}
                                                value="create-pr"
                                                control={<Radio/>}
                                                label="Create PR"
                                            />
                                        }
                                        {props.package.type != null && props.package.type == "auto" && props.package?.autoType != null && props.package.autoType == "brand" && (
                                            <FormControlLabel
                                                value="create-direct-pr"
                                                control={<Radio/>}
                                                label="Create Direct PR"
                                            />
                                        )}
                                        <FormControlLabel
                                            value="create-event"
                                            control={<Radio/>}
                                            label="Create Event"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box>
                                {radioValue === "create-pr" ? (
                                    <Box sx={{maxWidth: '250px', width: '100%'}}>
                                        <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                            Select Vendor/Brand
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name={"entity"}
                                            displayEmpty
                                            onChange={handleVendorChange}
                                            style={{height: '40px'}}
                                            defaultValue={defaultVendorId}
                                        >
                                            {vendors.length > 0 && vendors.map((vendor: Vendor, index: number) => (
                                                <MenuItem key={index} value={vendor.id}>
                                                    {vendor.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                ) : radioValue == "create-direct-pr" ? (
                                    <Box sx={{maxWidth: '250px', width: '100%'}}>
                                        <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                            Select Vendor
                                        </InputLabel>
                                        <Autocomplete
                                            id="vendor-autocomplete"
                                            sx={{width: 300}}
                                            getOptionLabel={(option: Vendor) => option.name}
                                            filterOptions={(x) => x}
                                            options={options}
                                            autoComplete
                                            includeInputInList
                                            filterSelectedOptions
                                            value={value}
                                            noOptionsText="No vendors"
                                            onChange={(event: any, newValue: any) => {
                                                if (newValue) {
                                                    // Check if the selected vendor already exists in the vendors list
                                                    if (
                                                        !selectedVendors.some(
                                                            (vendor: any) =>
                                                                vendor.name === newValue.name
                                                        )
                                                    ) {
                                                        setSelectedVendors([newValue]);
                                                    }
                                                }
                                                setValue(newValue);
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select a vendor"
                                                    fullWidth
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <Box sx={{fontWeight: "regular"}}>
                                                        {option.name}
                                                    </Box>
                                                </li>
                                            )}
                                        />
                                    </Box>
                                ) : (
                                    <Typography sx={{color: '#9D9D9D', fontSize: '12px'}}>
                                        Note : You will be taken to new page to add details
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={style.footer}>
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                            <Grid item>
                                <Box>
                                    <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}} onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        sx={{fontSize: "0.8rem"}}
                                        onClick={
                                            radioValue === "create-pr"
                                                ? handleCreatePr
                                                : radioValue == "create-direct-pr" ? handleDirectPr : handleCreateEvent
                                        }
                                    >
                                        {radioValue === "create-pr" ? "Create PR" : radioValue == "create-direct-pr" ? "Create Direct PR" : "Create Event"}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {openEventPopup && (
                    <CreateEventPopup
                        handlePopupCallBack={props.handlePopupCallBack}
                        openPopup={openEventPopup}
                        package={props.package}
                        project={props.project}
                    />
                )}
            </Box>
        </Modal>
    );
}

export default PublishPackage;