import {Box, Grid, Typography} from "@mui/material";
import TabularRevisionDetails from "./TabularRevisionDetails";
import {useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import ProjectRoomsComparison from "./room-comparison/ProjectRoomsComparison";
import CatalogComparison from "./e-catalog/CatalogComparison";
import SelectCompareRevisions from "./SelectCompareRevisions";
import ParametersComparison from "./ParametersComparison";

const CompareRevisionTabular = (props: any) => {
    const subCategoriesToShow = ["CH", "CA", "MF", "LF"];
    const [catalogSubCategories, setCatalogSubCategories] = useState<any>([]);
    const [isCatalogueLoading, setIsCatalogueLoading] = useState(false);
    const [revisionCatalog, setRevisionCatalog] = useState<any>([]);
    const [isParametersLoading, setIsParametersLoading] = useState(false);
    const [isHeadCountLoading, setIsHeadCountLoading] = useState(false);
    const [revisionParameters, setRevisionParameters] = useState<any>([]);
    const [revisionProjectRooms, setRevisionProjectRooms] = useState<any>([]);

    const getECatalogueForEstimationRevisions = () => {
        setIsCatalogueLoading(true);
        const ids = catalogSubCategories.map((subCategory: any) => subCategory.id);
        let form = {'revisionIds': props.selectedRevisions, 'subCategoryIds': ids};
        api.post(`/procurement/estimation/catalogue-for-revisions/${props.project.id}`, form)
            .then(response => response.data)
            .then(res => {
                if(res.length > 0)
                    setRevisionCatalog(res);
            })
            .catch(ex => {
                console.log(ex);
            })
            .finally(() => {
                setIsCatalogueLoading(false);
            });
    }
    const getParametersForEstimationRevisions = () => {
        setIsParametersLoading(true);
        let form = {'revisionIds': props.selectedRevisions};
        api.post(`/procurement/project/parameters-for-revisions/${props.project.id}`, form)
            .then(response => response.data)
            .then(res => {
                if(res.length > 0)
                    setRevisionParameters(res);
            })
            .catch(ex => {
                console.log(ex);
            })
            .finally(() => {
                setIsParametersLoading(false);
            });
    }
    const getProjectRoomsForEstimationRevisions = () => {
        setIsHeadCountLoading(true);
        let form = {'revisionIds': props.selectedRevisions};
        api.post(`/procurement/project/room-count-for-revisions/${props.project.id}`, form)
            .then(response => response.data)
            .then(res => {
                if(res.length > 0)
                    setRevisionProjectRooms(res);
            })
            .catch(ex => {
                console.log(ex);
            })
            .finally(() => {
                setIsHeadCountLoading(false);
            });
    }

    useEffect(() => {
        if (props.revisionEstimations.length > 0) {
            if(props.project.estimationType !== "scratch") {
                getParametersForEstimationRevisions();
                getProjectRoomsForEstimationRevisions();
            }
            props.categories.map((category: any) => {
                const subCategories = category.subCategories.filter((subCategory: any) => {
                    return subCategoriesToShow.includes(subCategory.code);
                });
                if (subCategories.length > 0)
                    setCatalogSubCategories(subCategories);
            });
        }
    }, [props.revisionEstimations]);

    useEffect(() => {
        if (catalogSubCategories.length > 0 && props.project.estimationType !== "scratch") {
            getECatalogueForEstimationRevisions();
        }
    }, [catalogSubCategories]);

    return (
        <Grid container >
            <Grid item xs={2.5}>
                <Box sx={{height: '70px'}}>
                    <SelectCompareRevisions
                        selectedRevisions={props.selectedRevisions}
                        revisions={props.revisions}
                        handleSave={props.handleSave}
                    />
                </Box>
                {(props.tab == 'table' && props.selectedRevisions.length > 0) &&
                    <Box>
                        <Box sx={{height: '70px', my: 2}}/>
                        <Box>
                            <Box
                                sx={{
                                    py: 1.5,
                                    px: 1,
                                    backgroundColor: '#E9EBF9',
                                    border: 1,
                                    borderBottom: 0,
                                    borderColor: '#E9EBF9'
                                }}>
                                <Typography>Work Contracts</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{px: 1, py: 2, border: 1, borderColor: '#E9EBF9'}}>MEP</Typography>
                                <Typography sx={{px: 1, py: 2, border: 1, borderTop: 0, borderColor: '#E9EBF9'}}>Civil &
                                    Interior</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Supply</Typography>
                        </Box>
                        <Box sx={{px: 1, py: 2, border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>FF&E</Typography>
                        </Box>
                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Design & Consultancy Charges</Typography>
                        </Box>
                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Prelim Charges</Typography>
                        </Box>

                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Local Authority Charges</Typography>
                        </Box>

                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>BOCW Charges</Typography>
                        </Box>

                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Taxes @ 18%</Typography>
                        </Box>

                        <Box sx={{mt: 2, py: 1.5, px: 1, border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Total</Typography>
                        </Box>
                    </Box>
                }
            </Grid>
            <Grid item xs={9.5} className={"revision-comparison"}>
                <Box sx={{overflowX: 'auto', pb: 5, whiteSpace: 'nowrap'}}>
                    {props.revisionEstimations.map((revision: any, index: number) => {
                        let bgColor = '#fff';
                        if ((index + 1) % 2 == 0) {
                            bgColor = '#E9EBF9';
                        }
                        return (
                            <Box sx={{width: '530px', marginRight: '20px', display: 'inline-block'}}
                                 key={"tabular-revision-compare-" + index}>
                                <TabularRevisionDetails
                                    bgColor={bgColor}
                                    tab={props.tab}
                                    revision={revision}
                                    project={props.project}
                                    categories={props.categories}
                                    showUpdatedCharges={props.showUpdatedCharges}
                                />
                            </Box>
                        )
                    })}
                </Box>
            </Grid>
            {revisionParameters.length > 0 &&
                <Grid item xs={12} sx={{position: 'relative'}}>
                    <ParametersComparison revisionParameters={revisionParameters}
                                          isParametersLoading={isParametersLoading}/>
                </Grid>
            }
            {revisionProjectRooms.length > 0 &&
                <Grid item xs={12} sx={{position: 'relative'}}>
                    <ProjectRoomsComparison revisionProjectRooms={revisionProjectRooms}
                                            isHeadCountLoading={isHeadCountLoading}/>
                </Grid>
            }

            {revisionCatalog.length > 0 &&
                <Grid item xs={12} sx={{position: 'relative'}}>
                    <CatalogComparison revisionCatalog={revisionCatalog} catalogSubCategories={catalogSubCategories}
                                            isCatalogueLoading={isCatalogueLoading}/>
                </Grid>
            }
        </Grid>
    )
}

export default CompareRevisionTabular;