export const USER_ROLES = "v0.0_scopes"

export const SUCCESS_COLOR = 'green'

export const ERROR_COLOR = 'red'

// Constants for Event Details Popup
export const ACCEPT_BID_SUCCESS = "Bid accepted succesffuly";
export const ACCEPT_BID_FAILED = "Failed to accept the bids!";


// Constants for Event Details Popup
export const SAVE_BIDS_SUCCESS_MESSAGE = "Successfully saved the bids.";
export const SAVE_BIDS_FAILURE_MESSAGE = "Failed to save the bids!";
export const SELECT_ALL_VALUES_ERROR_MESSAGE = "Please select all values.";


// Constants for ESTIMATE details
export const REMOVED_ITEM_CATEGORY_SUCCESSFULLY = "Item Category deleted";
export const REMOVED_ITEM_CATEGORY_FAILED = "Error! Couldn't delete Item category";

// Constants for Save Draft details
export const DRAFT_SAVED_SUCCESSFULLY = "Draft Saved Successfully";
export const DRAFT_SAVED_FAILED = "Error! Draft couldn't be saved!";


// Constants for Approve Purchase Request  details
export const APPROVE_PR_SUCCESS = "Purchase Request Approved";
export const APPROVE_PR_FAILED = "Error! Purchase Request Couldn't be Approved!";

// Constants for Save Publishing details
export const PART_PUBLISHED_SUCCESSFULLY = "Part Successfully Published";
export const PART_PUBLISHED_FAILED= "Error! Part cannot be published!";

// Constants for Save Part Draft details
export const DRAFT_PART_SUCCESSFULLY = "Part Successfully Drafted";
export const DRAFT_PART_FAILED= "Error! Part cannot be drafted!";
export const DUPLICATE_PART_NAME= "Error! Part name already exist!";

// Constants for Save Part Draft details
export const REMOVED_ITEM_SUCCESS = "Item Successfully removed";
export const REMOVED_PART_SUCCESS = "Part Successfully removed";
export const REMOVED_ITEM_FAILURE= "Error! Item couldn't be removed!";
export const REMOVED_PART_FAILURE= "Error! Part couldn't be removed!";

// SKU Price Removal
export const REMOVED_SKU_PRICE_SUCCESSFULLY = "SKU price removed successfully!";
export const REMOVED_SKU_PRICE_FAILED = "SKU price removal failed!";

export const COGS_UPDATED_SUCCESSFULLY = 'COGS Price updated successfully.'
export const COGS_UPDATED_FAILURE  = 'COGS Price updated failure.'

export const RC_PRICE_UPDATED_SUCCESSFULLY = 'Rate Contract Price updated successfully.'
export const RC_PRICE_UPDATED_FAILURE  = 'Rate Contract Price updated failure.'

export const APPROVE_ESTIMATION_SUCCESS = "Estimation published successfully";
export const APPROVE_ESTIMATION_ERROR = "Estimation publish failed";

export const CLONE_ESTIMATION_REVISION_SUCCESS = "Estimation revision created successfully";
export const CLONE_ESTIMATION_REVISION_ERROR = "Estimation revision creation failed";

export const ESTIMATION_RANGE = {
    "BA": {ranges: {costEffective: [525,560], standard: [530,650], strong: [750,950]}},
    "GP": {ranges: {costEffective: [90,100], standard: [100,125], strong: [120,165]}},
    "CE": {ranges: {costEffective: [65,85], standard: [95,135], strong: [110,165]}},
    "BL": {ranges: {costEffective: [45577], standard: [45580], strong: [25,32]}},
    "GS": {ranges: {costEffective: [45516], standard: [45520], strong: [15,35]}},
    "TW": {ranges: {costEffective: [85,100], standard: [85,120], strong: [110,135]}},
    "DO": {ranges: {costEffective: [90,100], standard: [100,125], strong: [120,165]}},
    "WA": {ranges: {costEffective: [525,560], standard: [530,650], strong: [750,950]}},
    "AD": {ranges: {costEffective: [525,560], standard: [530,650], strong: [750,950]}},
    "PT": {ranges: {costEffective: [525,560], standard: [530,650], strong: [750,950]}},
    "MS": {ranges: {costEffective: [525,560], standard: [530,650], strong: [750,950]}},
    "KH": {ranges: {costEffective: [525,560], standard: [530,650], strong: [750,950]}},
    "FL": {ranges: {costEffective: [525,560], standard: [530,650], strong: [750,950]}},
    "VI": {ranges: {costEffective: [525,560], standard: [530,650], strong: [750,950]}},
    "FU": {ranges: {costEffective: [525,560], standard: [530,650], strong: [750,950]}},
    "EL": {ranges: {costEffective: [310,320], standard: [310,335], strong: [370,370]}},
    "IU": {ranges: {costEffective: [45519], standard: [45580], strong: [15,20]}},
    "UP": {ranges: {costEffective: [25,32], standard: [25,32], strong: [25,32]}},
    "HL": {ranges: {costEffective: [160,170], standard: [160,175], strong: [175,185]}},
    "HW": {ranges: {costEffective: [160,170], standard: [160,175], strong: [175,185]}},
    "VA": {ranges: {standard: [45,55], strong: [60,75]}},
    "HV": {ranges: {costEffective: [45,72], standard: [45,65], strong: [72,85]}},
    "FA": {ranges: {costEffective: [35,45], standard: [35,45], strong: [45,60]}},
    "PA": {ranges: {costEffective: [20,25], standard: [20,25], strong: [25,35]}},
    "RO": {ranges: {costEffective: [45325], standard: [45326], strong: [45326]}},
    "WL": {ranges: {costEffective: [45325], standard: [45326], strong: [45326]}},
    "VE": {ranges: {costEffective: [45418], standard: [45418], strong: [45418]}},
    "AC": {ranges: {costEffective: [45,55], standard: [45,55], strong: [45,58]}},
    "CC": {ranges: {costEffective: [45,55], standard: [45,55], strong: [45,58]}},
    "FB": {ranges: {costEffective: [45,55], standard: [45,55], strong: [45,58]}},
    "TU": {ranges: {costEffective: [45,55], standard: [45,55], strong: [45,58]}},
    "BM": {ranges: {strong: [80]}},
    "SK": {ranges: {costEffective: [45,58], standard: [45,58], strong: [55,65]}},
    "FE": {ranges: {costEffective: [45520], standard: [45520], strong: [45641]}},
    "PH": {ranges: {costEffective: [85,90], standard: [85,90], strong: [90,135]}},
    "IT": {ranges: {costEffective: [85,110], standard: [85,115], strong: [115,165]}}
};