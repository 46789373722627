import {Box, Button, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {api} from "../../utils/Axios";
import {useNavigate} from "react-router-dom";
import UploadCatalogue from "./upload-catalogue/UploadCatalogue";
import SummaryTab from "./tabs/SummaryTab";
import RcCatalogTab from "./tabs/RcCatalogTab";
import BrandCatalogTab from "./tabs/BrandCatalogTab";
import Loader from "../../components/Loader";
import {hasAccess} from "../../utils/Access";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import UploadCogs from "./upload-catalogue/UploadCogs";

const CatalogueMain = (props: any) => {
    const [openUploadPopup, setOpenUploadPopup] = useState(false);
    const [openUploadCogsPopup, setOpenUploadCogsPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [categories, setCategories] = useState([]);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const navigate = useNavigate();

    const handleConfirmationDialogClose = () => {
        setOpenConfirmationDialog(false);
    }

    const loadCategories = () => {
        setIsLoading(true);
        api.get("/catalogue/material-master/categories").then((response) => {
            return response.data;
        }).then((response: any) => {
            response.forEach((category: any) => {
                loadSubCategories(category);
            });
            setCategories(response);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const loadSubCategories = (category: any) => {
        setIsLoading(true);
        api.get("/catalogue/material-master/sub-categories", {
            params: {
                "category_id": category.id
            }
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            category.subCategories = response;
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleUploadPopupCallBack = () => {
        setOpenUploadPopup(false);
    };

    const handleUploadCogsPopupCallBack = () => {
        setOpenUploadCogsPopup(false);
    };

    const tabs = [
        {label: "Summary", id: "summary", content: <SummaryTab categories={categories}/>},
        {label: "Catalogue", id: "rc", content: <RcCatalogTab categories={categories}/>},
        {label: "Brand Catalogue", id: "brand", content: <BrandCatalogTab categories={categories}/>}
    ]
    const handleHeaderButtonClick = (data: any) => {
        if (data == "upload") {
            setOpenUploadPopup(true);
        }
        if (data == "sync") {
            setOpenConfirmationDialog(true);
        }
        if (data == "cogs") {
            setOpenUploadCogsPopup(true);
        }
        if (data == "skus") {
            navigate("/catalogue/skus");
        }
    };

    const syncCatalogue = () => {
        setOpenConfirmationDialog(false);
        setIsLoading(true);
        api
            .get("/catalogue/sync")
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                console.log(response);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadCategories();
    }, []);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Box className={"common-space-container"}>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                margin={"32px 0"}
            >
                <Box
                    width={"700px"}
                    sx={{
                        display: "ruby",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{marginRight: "32px", marginTop: "8px"}}>
                        <Typography sx={{fontSize: "20px", fontWeight: 700, lineHeight: "24px"}}>
                            Catalogue
                        </Typography>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                        <Box sx={{display: "flex", marginTop: "1rem"}}>
                            {tabs.map((tab: any, index: number) => {
                                let backgroundColor = "background.default";
                                let onHoverBackgroundColor = "background.paper";
                                let color = "text.primary";
                                if (activeIndex === index) {
                                    backgroundColor = "primary.main";
                                    color = "primary.contrastText";
                                    onHoverBackgroundColor = "primary.dark";
                                }
                                return (
                                    <Button
                                        key={index}
                                        onClick={() => setActiveIndex(index)}
                                        sx={{
                                            borderRadius: "0",
                                            borderTopLeftRadius: index === 0 ? "0.2rem" : "0",
                                            borderTopRightRadius:
                                                index === tabs.length - 1 ? "0.2rem" : "0",
                                            borderBottomLeftRadius: index === 0 ? "0.2rem" : "0",
                                            borderBottomRightRadius:
                                                index === tabs.length - 1 ? "0.2rem" : "0",
                                            bgcolor: backgroundColor,
                                            color: color,
                                            "&:hover": {
                                                bgcolor: onHoverBackgroundColor
                                            },
                                            "&.active": {
                                                fontWeight: "bold",
                                            },
                                            "&:not(:last-child)": {
                                                marginRight: "1px",
                                            },
                                        }}
                                        variant={activeIndex === index ? "contained" : "outlined"}
                                        className={activeIndex === index ? "active" : ""}
                                    >
                                        {tab.label}
                                    </Button>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {hasAccess(["view-skus", "edit-skus"]) &&
                        <Button variant="outlined" onClick={() => handleHeaderButtonClick("skus")}
                                sx={{mr: 1}}>
                            View All Skus
                        </Button>
                    }
                    {hasAccess(["sync-catalog"]) &&
                        <Button variant="outlined" onClick={() => handleHeaderButtonClick("sync")} sx={{mr: 1}}>
                            Sync Catalogue
                        </Button>
                    }
                    {hasAccess(["super-admin", "upload-prices"]) &&
                        <Button variant="outlined" onClick={() => handleHeaderButtonClick("cogs")} sx={{mr: 1}}>
                            Upload Cogs
                        </Button>
                    }
                    {hasAccess(["super-admin", "upload-prices"]) &&
                        <Button variant="contained" onClick={() => handleHeaderButtonClick("upload")}>
                            Price Bulk Upload
                        </Button>
                    }
                </Box>
            </Box>
            {categories.length > 0 &&
                <Box
                    sx={{
                        marginTop: "1rem",
                        width: "100%",
                        padding: "1rem",
                        backgroundColor: "background.paper",
                    }}
                >
                    {tabs[activeIndex].content}
                </Box>
            }
            {openUploadPopup && (
                <UploadCatalogue
                    openPopup={true}
                    handleUploadPopupCallBack={handleUploadPopupCallBack}
                />
            )}
            {openUploadCogsPopup && (
                <UploadCogs
                    openPopup={true}
                    handleUploadPopupCallBack={handleUploadCogsPopupCallBack}
                />
            )}
            {openConfirmationDialog &&
                <Dialog
                    open={true}
                    onClose={handleConfirmationDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Material Master Sync With BF"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to sync Material Master?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmationDialogClose} color="primary">
                            No
                        </Button>
                        <Button
                            variant={"contained"}
                            onClick={syncCatalogue}
                            color="primary"
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </Box>
    )
}

export default CatalogueMain;