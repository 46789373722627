import {Box, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getPriceInFormatted} from "../../../utils/Common";
import {getChartOptionsWithinDisplay} from "../../../utils/ChartUtils";
import {Doughnut} from "react-chartjs-2";

const TabularRevisionDetails = (props: any) => {
    const [boqBreakupDetails, setBoqBreakupDetails] = useState<any[]>([]);
    const [summaryDetails, setSummaryDetails] = useState<any[]>([]);

    const [chartData, setChartData] = useState<any>({});
    const [chartOptions, setChartOptions] = useState<any>({});
    const backgroundColors: string[] = [
        "#fbd474", "#A74462", "#048cdc", "#3F3B4E", "#fdb67a", "#5E33FF", "#33FF6B", "#FF5733"
    ];
    const [mainText, setMainText] = useState("Overall");

    useEffect(() => {
        const charges: any[] = [];

        const updatedChartData: any[] = [];

        let overAllTotal = 0;
        let overAllTotalCogs = 0;
        let subTotalForTax = 0;

        let workContractTotal = 0;
        let supplyTotal = 0;
        let workContractTotalCogs = 0;
        let supplyTotalCogs = 0;
        let mepTotal = 0;
        let mepTotalCogs = 0;
        let civilTotal = 0;
        let civilTotalCogs = 0;

        if (boqBreakupDetails.length > 0) {
            boqBreakupDetails.map((boqDetails: any) => {
                if (boqDetails.code.toLowerCase() == "ff") {
                    supplyTotal += boqDetails.total;
                    supplyTotalCogs += boqDetails.totalCogs;
                } else {
                    if (boqDetails.code.toLowerCase() == 'me') {
                        mepTotal += boqDetails.total;
                        mepTotalCogs += boqDetails.totalCogs;
                    }
                    if (boqDetails.code.toLowerCase() == 'ci') {
                        civilTotal += boqDetails.total;
                        civilTotalCogs += boqDetails.totalCogs;
                    }

                    workContractTotal += boqDetails.total;
                    workContractTotalCogs += boqDetails.totalCogs;
                }

                updatedChartData.push({
                    name: boqDetails.name,
                    total: boqDetails.total,
                });
                overAllTotal += boqDetails.total;
                overAllTotalCogs += boqDetails.totalCogs;
                subTotalForTax += boqDetails.total;
            });
        }

        charges.push({
            id: 'wc',
            isCategory: true,
            py: 1.5,
            mt: 0,
            nsv: workContractTotal - workContractTotalCogs,
            cogs: workContractTotalCogs,
            gsv: workContractTotal,
            nsvPercentage: ((workContractTotal - workContractTotalCogs) / workContractTotal) * 100,
        });

        charges.push({
            id: 'mep',
            isCategory: true,
            py: 2.5,
            mt: 0,
            nsv: mepTotal - mepTotalCogs,
            cogs: mepTotalCogs,
            gsv: mepTotal,
            nsvPercentage: ((mepTotal - mepTotalCogs) / mepTotal) * 100,
        });

        charges.push({
            id: 'civil',
            isCategory: true,
            py: 2,
            mt: 0,
            nsv: civilTotal - civilTotalCogs,
            cogs: civilTotalCogs,
            gsv: civilTotal,
            nsvPercentage: ((civilTotal - civilTotalCogs) / civilTotal) * 100,
        });

        charges.push({
            id: 'supply',
            isCategory: true,
            py: 2,
            mt: 2,
            nsv: supplyTotal - supplyTotalCogs,
            cogs: supplyTotalCogs,
            gsv: supplyTotal,
            nsvPercentage: ((supplyTotal - supplyTotalCogs) / supplyTotal) * 100,
        });

        charges.push({
            id: 'ff',
            isCategory: true,
            py: 1.9,
            mt: 0,
            nsv: supplyTotal - supplyTotalCogs,
            cogs: supplyTotalCogs,
            gsv: supplyTotal,
            nsvPercentage: ((supplyTotal - supplyTotalCogs) / supplyTotal) * 100,
        });

        let dactotal = props.project.carpetArea * props.project.projectDetails?.designConsultancyRate;
        charges.push({
            id: 'dcc',
            isCategory: false,
            py: 1.7,
            cogs: dactotal,
            nsv: "NA",
            gsv: dactotal,
            nsvPercentage: 0
        });

        updatedChartData.push({
            name: "Design and Consultancy Charges",
            total: dactotal,
        });

        overAllTotal += dactotal;
        overAllTotalCogs += dactotal;
        subTotalForTax += dactotal;

        let prelimTotal = props.project.carpetArea * props.project.projectDetails?.prelimsRate;

        overAllTotal += prelimTotal;
        overAllTotalCogs += prelimTotal;
        subTotalForTax += prelimTotal;

        charges.push({
            id: 'pr',
            isCategory: false,
            py: 1.6,
            nsv: "NA",
            cogs: prelimTotal,
            gsv: prelimTotal,
            nsvPercentage: 0
        });

        updatedChartData.push({name: "Prelims Charges", total: prelimTotal});

        let localAuthorityCharges = 0;
        if (
            props.project.projectDetails?.isAuthorityApprovalCharges &&
            props.project.projectDetails?.authorityApprovalCharges > 0
        ) {
            localAuthorityCharges = props.project.projectDetails?.authorityApprovalCharges;
            overAllTotal += localAuthorityCharges;
            overAllTotalCogs += localAuthorityCharges;
            subTotalForTax += localAuthorityCharges;
            updatedChartData.push({
                name: "Local Authority Charges",
                total: localAuthorityCharges,
            });
        }

        charges.push({
            id: 'local',
            isCategory: false,
            py: 1.7,
            mt: 2.1,
            nsv: "NA",
            cogs: localAuthorityCharges,
            gsv: localAuthorityCharges,
            nsvPercentage: 0
        });

        let bocwTotal = 0;

        if (
            props.project.projectDetails?.bocwApplicable &&
            props.project.projectDetails?.bocwApplicableRate > 0
        ) {
            bocwTotal = (overAllTotal * props.project.projectDetails?.bocwApplicableRate) / 100;
            overAllTotal += bocwTotal;
            overAllTotalCogs += bocwTotal;

            updatedChartData.push({
                name:
                    "BOCW Charges @ " +
                    props.project.projectDetails?.bocwApplicableRate +
                    "%",
                total: bocwTotal,
            });
        }

        charges.push({
            id: 'bocw',
            isCategory: false,
            py: 1.7,
            nsv: "NA",
            cogs: bocwTotal,
            gsv: bocwTotal,
            nsvPercentage: 0
        });

        let taxTotal = (subTotalForTax * 18) / 100;
        overAllTotal += taxTotal;
        overAllTotalCogs += taxTotal;
        updatedChartData.push({name: "Total Taxes @ 18%", total: taxTotal});

        charges.push({
            id: 'tax',
            isCategory: false,
            py: 1.7,
            nsv: "NA",
            cogs: taxTotal,
            gsv: taxTotal,
            nsvPercentage: 0
        });

        charges.push({
            id: 'total',
            isCategory: false,
            py: 1.7,
            nsv: overAllTotal - overAllTotalCogs,
            cogs: overAllTotalCogs,
            gsv: overAllTotal,
            nsvPercentage: ((overAllTotal - overAllTotalCogs) / overAllTotal) * 100,
        });

        setChartData({
            labels: updatedChartData.map((chartData: any) => chartData.name),
            datasets: [
                {
                    data: updatedChartData.map((chartData: any) =>
                        (
                            ((chartData.total as number) / (overAllTotal as number)) *
                            100
                        ).toFixed(1)
                    ),
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 0.2,
                },
            ],
        });
        setSummaryDetails(charges);
    }, [boqBreakupDetails]);

    useEffect(() => {
        setChartOptions(getChartOptionsWithinDisplay());
    }, [chartData]);

    useEffect(() => {
        if (props.categories.length > 0) {
            const updatedCategories = props.categories.map((category: any) => {
                let estimationTotal: number = 0;
                let estimationTotalCogs: number = 0;
                let estimationTotalSkus = 0;
                const subCategories = category.subCategories.map((subCategory: any) => {
                    const estimation = props.revision.estimations?.find(
                        (estimation: any) =>
                            estimation.materialSubCategoryId === subCategory.id
                    );
                    if (estimation && estimation.estimationItems.length > 0) {
                        let subCategoryTotalCogs: number = 0;
                        let subCategoryTotal: number = 0;
                        let subCategoryTotalSkus = 0;
                        const updatedEstimations = estimation.estimationItems.map((estimationItem: any) => ({
                            ...estimationItem,
                            estimationItemSkus:
                                estimationItem.estimationItemSkus.length > 0 &&
                                estimationItem.estimationItemSkus.map((sku: any) => {
                                    let targetPrice = parseFloat(sku.targetPrice) + parseFloat(sku.cogsDomainChargesPerUnit?.total);
                                    if (props.showUpdatedCharges && !sku.manuallyEdited) {
                                        if (category.code.toLowerCase() == "ff") {
                                            targetPrice = targetPrice + ((targetPrice * 2) / 100);
                                        } else {
                                            targetPrice = targetPrice + ((targetPrice * 6) / 100);
                                        }
                                    }

                                    let totalCogs = (parseInt(sku.totalQuantity) * targetPrice);
                                    subCategoryTotalCogs += totalCogs;
                                    subCategoryTotal = subCategoryTotal + (parseInt(sku.totalQuantity) * parseFloat(sku.sellingPrice)) + (parseInt(sku.totalQuantity) * parseFloat(sku?.gsvDomainChargesPerUnit?.total));
                                    subCategoryTotalSkus++;
                                    return {...sku, targetPrice: targetPrice};
                                }),
                        }));

                        estimationTotal += subCategoryTotal;
                        estimationTotalCogs += subCategoryTotalCogs;
                        estimationTotalSkus += subCategoryTotalSkus;

                        return {
                            ...subCategory,
                            total: subCategoryTotal,
                            totalCogs: subCategoryTotalCogs,
                            estimationTotalSkus: subCategoryTotalSkus,
                            estimationItems: updatedEstimations,
                        };
                    }
                    return null;
                }).filter((subCategory: any) => subCategory !== null);
                subCategories.sort((a: any, b: any) => a.total - b.total);

                if (subCategories.length > 0) {
                    return {
                        ...category,
                        total: estimationTotal,
                        totalCogs: estimationTotalCogs,
                        estimationTotalSkus,
                        subCategories,
                    };
                }
                return null;
            }).filter((category: any) => category !== null);

            setBoqBreakupDetails(updatedCategories);
        }
    }, [props.revision, props.categories]);

    return (
        <>
            <Box>
                <Box sx={{border: 1, borderColor: '#E9EBF9', height: '70px', backgroundColor: props?.bgColor}}>
                    <Typography className={"vertically-center-aligned"} sx={{px: 2, fontWeight: 600, height: '100%'}}>
                        {props.revision.name}
                    </Typography>
                </Box>
                {props.tab == 'table' ? (
                    <Box>
                        <Box sx={{height: '70px', my: 2}}>
                            <Grid container sx={{border: 1, borderColor: '#E9EBF9', py: 2}}>
                                {!props.showUpdatedCharges &&
                                    <Grid item xs={3} sx={{px: 1}}>
                                        <Typography sx={{fontWeight: 600, fontSize: '10px'}}>Total COGS</Typography>
                                    </Grid>
                                }
                                <Grid item xs={3} sx={{px: 1}}>
                                    <Typography sx={{fontWeight: 600, fontSize: '10px'}}>Total NSV</Typography>
                                </Grid>
                                <Grid item xs={3} sx={{px: 1}}>
                                    <Typography sx={{fontWeight: 600, fontSize: '10px'}}>Total GSV</Typography>
                                </Grid>
                                <Grid item xs={!props.showUpdatedCharges ? 1.5 : 3} sx={{px: 1}}>
                                    <Typography sx={{fontWeight: 600, fontSize: '10px'}}>GSV/SQFT</Typography>
                                </Grid>
                                <Grid item xs={!props.showUpdatedCharges ? 1.5 : 3} sx={{px: 2}}>
                                    <Typography sx={{fontWeight: 600, fontSize: '10px'}}>NSV %age</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        {summaryDetails.map((summaryDetail: any, summaryIndex: number) => {
                            return (
                                <Box key={"revision-charges-" + props.revision.id + "-" + summaryIndex}>
                                    <Grid container
                                          sx={{
                                              py: summaryDetail.py,
                                              mt: summaryDetail.hasOwnProperty("mt") ? summaryDetail.mt : (!summaryDetail.isCategory || summaryDetail.id == 'supply') ? 2 : 0,
                                              border: 1,
                                              borderColor: summaryDetail.isCategory ? 'transparent' : '#E9EBF9',
                                              backgroundColor: props.bgColor,
                                          }}
                                          className={summaryDetail.id}>
                                        {!props.showUpdatedCharges &&
                                            <Grid item xs={3} sx={{px: 1}}>
                                                <Typography sx={{
                                                    fontWeight: ['wc', 'supply'].includes(summaryDetail.id) ? 600 : 'inherit',
                                                    fontSize: '10px'
                                                }}>
                                                    {getPriceInFormatted(summaryDetail.cogs, props.project.country)}
                                                </Typography>
                                            </Grid>
                                        }
                                        <Grid item xs={3} sx={{px: 1}}>
                                            <Typography sx={{
                                                fontWeight: ['wc', 'supply'].includes(summaryDetail.id) ? 600 : 'inherit',
                                                fontSize: '11px'
                                            }}>
                                                {!isNaN(summaryDetail.nsv) ? getPriceInFormatted(summaryDetail.nsv, props.project.country) : summaryDetail.nsv}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{px: 1}}>
                                            <Typography sx={{
                                                fontWeight: ['wc', 'supply'].includes(summaryDetail.id) ? 600 : 'inherit',
                                                fontSize: '11px'
                                            }}>
                                                {getPriceInFormatted(summaryDetail.gsv, props.project.country)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={!props.showUpdatedCharges ? 1.5 : 3} sx={{px: 1}}>
                                            <Typography sx={{
                                                fontWeight: ['wc', 'supply'].includes(summaryDetail.id) ? 600 : 'inherit',
                                                fontSize: '11px'
                                            }}>
                                                {getPriceInFormatted(summaryDetail.gsv / props.project?.carpetArea, props.project.country)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={!props.showUpdatedCharges ? 1.5 : 3} sx={{px: 2}}>
                                            <Typography sx={{
                                                fontWeight: ['wc', 'supply'].includes(summaryDetail.id) ? 600 : 'inherit',
                                                fontSize: '11px'
                                            }}>
                                                {!isNaN(summaryDetail.nsvPercentage) ? Number.parseFloat(summaryDetail.nsvPercentage).toFixed(2) : Number.parseFloat("0").toFixed(2)}%
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        })}
                    </Box>
                ) : (
                    <Box>
                        {Object.keys(chartData).length > 0 && (
                            <Box
                                className="chart-container"
                                sx={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "0px solid lightgray",
                                    borderRadius: "2px",
                                    padding: "32px",
                                    m: 0,
                                    height: "95%",
                                }}
                            >
                                <Typography
                                    style={{
                                        textAlign: "center",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    <Typography
                                        component={"span"}
                                        sx={{
                                            fontSize: "20px",
                                            fontWeight: 600,
                                            display: "inline-block",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        {mainText}
                                    </Typography>
                                </Typography>
                                <Doughnut
                                    id={"sm-doughnut-chart-project"}
                                    data={chartData}
                                    options={chartOptions}
                                />
                            </Box>
                        )}
                    </Box>
                )
                }
            </Box>
        </>
    )
}

export default TabularRevisionDetails;