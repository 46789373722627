import React, {useState} from "react";
import {ItemTypes} from "../../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import {Box, Button} from "@mui/material";
import CategoryBlock from "./CategoryBlock";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import SummarySubCategoryWidget from "./SummarySubCategoryWidget";

const SummaryCategoryWidget = (props: any) => {
    const [expandCategory, setExpandCategory] = useState(false);

    const handleExpand = () => {
        setExpandCategory(!expandCategory);
    }

    return (
        <Box sx={{width: '100%'}}>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{backgroundColor: "info.contrastText", borderRadius: 1, cursor: "move", width: '100%', p: 1.5}}
            >
                {props.section.status == 'DRAFT' &&
                    <Button
                        onClick={() => props.handleRemoveSectionItem({
                            type: ItemTypes.CATEGORY,
                            id: props.category?.id
                        })}
                        sx={{minWidth: "auto", padding: "4px"}}
                    >
                        <DoDisturbOnOutlinedIcon
                            sx={{fontSize: "16px", color: "#CB5A42", cursor: "pointer"}}
                        />
                    </Button>
                }
                <CategoryBlock category={props.category} project={props.project}
                               showUpdatedCharges={props.showUpdatedCharges}/>
                <Button
                    onClick={() => handleExpand()} sx={{py: 0}}
                >
                    {expandCategory ? (
                        <KeyboardArrowUpIcon/>
                    ) : (
                        <ExpandMoreIcon/>
                    )}
                </Button>
            </Box>
            {expandCategory && props.category.subCategories.map((subCategory: any, subCategoryIndex: number) => (
                <Box sx={{p: 1}} key={"summary-sub-category-widget-" + subCategoryIndex + "-" + subCategory.id}>
                    <SummarySubCategoryWidget
                        subCategory={subCategory}
                        project={props.project}
                        section={props.section}
                        estimations={props.selectedItems}
                        showUpdatedCharges={props.showUpdatedCharges}
                        categoryCode={props.category.code}
                        handleRemoveSectionItem={props.handleRemoveSectionItem}
                    />
                </Box>
            ))}
        </Box>
    )
}

export default SummaryCategoryWidget;