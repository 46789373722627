import ParameterFormTab from "./ParameterFormTab";

const deepClone = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
};

const CiPlusCommonTab = (props: any) => {
    const {formState, setFormState } = props;
    let buttons = [
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects'],
        },
    ];
    const handleNextButton = () => {
        props.handleNext(props.categoryName)
    }

    return (
        <ParameterFormTab project={props.project} formFields={formFields} categoryName={props.categoryName} allParameters={props.allParameters}
        formState={formState} setFormState={setFormState} buttons={buttons} handleNextButton={handleNextButton} updateFieldChange={props.updateFieldChange} buttonRef={props.buttonRef}/>
    );
};

const formFields = [
    {
        groupName: 'DX Details',
        fields: [
            {
                element: 'select',
                label: 'Type of DX details to be considered',
                name: 'type-of-dx-details-to-be-considered',
                required: true,
                value: 'Design Strong Option',
                mainContainerWidth: 12,
                width: 4,
                options: [
                    {
                        id: 'Cost Effective Design Option',
                        label: 'Cost Effective Design Option',
                    },
                    {id: 'Standard Design Option', label: 'Standard Design Option'},
                    {id: 'Design Strong Option', label: 'Design Strong Option'},
                    // {id: 'Laptop', label: 'Laptop'},
                ],
            },
            {
                element: 'select',
                label:
                    'Type of Base Flooring (Screeding / False Floor/ Screeding already done at site by Builder / client)',
                name: 'type-of-base-flooring',
                required: true,
                mainContainerWidth: 12,
                width: 4,
                value: 'False floor across office Bare cement finish tiles-  upto 150 mm height Below carpet area',
                options: [
                    {
                        id: 'Screeding already done at site. Dismantling of existing cement screed. New cement Screeding for Hardfloor area. Raceways filling and repair',
                        label:'Screeding already done at site. Dismantling of existing cement screed. New cement Screeding for Hardfloor area. Raceways filling and repair',
                    },
                    {
                        id: 'Cement Screed  upto 75mm height with M15 grade concrete',
                        label:'Cement Screed  upto 75mm height with M15 grade concrete',
                    },
                    {
                        id: 'False floor across office Bare cement finish tiles (upto 150 mm height Below carpet area & Cement screeding for Hard floor area)',
                        label:'False floor across office Bare cement finish tiles (upto 150 mm height Below carpet area & Cement screeding for Hard floor area)',
                    }
                    /*{
                        id: 'Screeding already done at site',
                        label:'Screeding already done at site',
                    },
                    {
                        id: 'Dismantling of existing cement screed',
                        label:'Dismantling of existing cement screed',
                    },
                    {
                        id: 'New cement Screeding for Hardfloor area',
                        label:'New cement Screeding for Hardfloor area',
                    },
                    {
                        id: 'Raceways filling and repair',
                        label:'Raceways filling and repair',
                    },
                    {
                        id: 'Cement Screed  upto 75mm height',
                        label:'Cement Screed  upto 75mm height',
                    },
                    {
                        id: 'with M15 grade concrete.',
                        label:'with M15 grade concrete.',
                    },
                    {
                        id: 'False floor across office Bare cement finish tiles-  upto 150 mm height Below carpet area',
                        label:'False floor across office Bare cement finish tiles-  upto 150 mm height Below carpet area',
                    },
                    {
                        id: 'Cement screeding for Hard floor area.',
                        label:'Cement screeding for Hard floor area.',
                    },*/
                ],
            },
            {
                element: 'select',
                label: 'Cement Screed thickness in mm',
                name: 'cement-screed-thickness',
                required: true,
                value: '100',
                mainContainerWidth: 12,
                width: 4,
                options: [
                    {id: '50', label: '50'},
                    {id: '75', label: '75'},
                    {id: '100', label: '100'},
                    {id: '150', label: '150'},
                ],
            },
            {
                element: 'select',
                label: 'Type of ceiling in workhall',
                name: 'type-of-ceiling-in-workhall',
                required: true,
                value: 'Partially open and closed ceiling',
                mainContainerWidth: 12,
                width: 4,
                options: [
                    {id: 'Open Ceiling', label: 'Open Ceiling'},
                    {id: 'Closed Ceiling', label: 'Closed Ceiling'},
                    {id: 'Partially open and closed ceiling', label: 'Partially open and closed ceiling'},
                    {id: 'Open ceiling with Acoustic Paint', label: 'Open ceiling with Acoustic Paint'},
                ],
            },
            {
                element: 'select',
                label: 'Type of Doors',
                name: 'type-of-doors',
                required: true,
                value: 'Glazed stile doors',
                mainContainerWidth: 12,
                width: 4,
                options: [
                    {id: 'Laminated Flush doors with aluminium door frame', label: 'Laminated Flush doors with aluminium door frame'},
                    {id: 'Glazed stile doors', label: 'Glazed stile doors'},
                ],
            }
        ]
    },
    {
        groupName: "Toilets Provided By Landlord",
        fields: [
            {
                element: 'select',
                label: 'Toilets provided by the landlord',
                name: 'toilets-provided-by-the-landlord',
                mainContainerWidth: 12,
                width: 4,
                disableField: ["core-cuts-required-for-toilets-and-pantry", "total-number-of-toilet-blocks", "total-toilet-area-in-sqm", "total-toilet-periphery-in-rmt"],
                disableOnValue: "YES",
                value: 'No',
                options: [
                    {id: 'YES', label: 'YES'},
                    {id: 'NO', label: 'NO'},
                ],
            },
            {
                element: 'select',
                label: 'Core cuts required for toilets and pantry',
                name: 'core-cuts-required-for-toilets-and-pantry',
                mainContainerWidth: 12,
                width: 4,
                disableOn: true,
                value: 'YES',
                options: [
                    {id: 'YES', label: 'YES'},
                    {id: 'NO', label: 'NO'},
                ],
            },
            {
                element: 'input',
                label: 'Total number of toilet blocks (male,female and other)',
                name: 'total-number-of-toilet-blocks',
                required: true,
                disableOn: true,
                type: 'number',
                mainContainerWidth: 12,
                width: 4,
            },
            {
                element: 'input',
                label: 'Total toilet area in Sqm',
                name: 'total-toilet-area-in-sqm',
                required: true,
                disableOn: true,
                type: 'number',
                mainContainerWidth: 12,
                width: 4,
            },
            {
                element: 'input',
                label: 'Total toilet periphery in Rmt',
                name: 'total-toilet-periphery-in-rmt',
                required: true,
                disableOn: true,
                type: 'number',
                mainContainerWidth: 12,
                width: 4,
            },
        ]
    },
    {
        groupName: "Lift Count",
        fields: [
            {
                element: 'select',
                label: 'Lift Lobby finishes provided by the landlord',
                name: 'liftLobbyFinishesProvidedByTheLandlord',
                mainContainerWidth: 12,
                width: 4,
                disableField: "number-of-lifts",
                disableOnValue: "No",
                value: 'No',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ],
            },
            {
                element: 'input',
                label: 'Number of lifts (for calculating lobby finishes if lift lobby is not in Landlord scope)',
                name: 'number-of-lifts',
                required: true,
                type: 'number',
                disableOn: true,
                mainContainerWidth: 12,
                width: 4,
            },
        ]
    },
    {
        groupName: "Ceiling & Glass Partition Height",
        fields: [
            {
                element: 'input',
                label: 'Floor to Floor height (Mtr)',
                name: 'floor-to-floor-height',
                required: true,
                value: '',
                type: 'text',
                mainContainerWidth: 12,
                width: 4,
            },
            {
                element: 'input',
                label: 'Glass partition Height (Mtr)',
                name: 'glass-partition-height',
                required: true,
                value: '',
                type: 'text',
                mainContainerWidth: 12,
                width: 4,
            },
        ]
    },
    /*{
        groupName: "False Ceiling Height",
        fields: [
            {
                element: 'input',
                label: 'False Ceiling/Cill Height',
                name: 'false-ceiling-cill-height',
                required: true,
                value: '',
                type: 'text',
                mainContainerWidth: 12,
                width: 4,
            },
        ]
    },*/
    {
        groupName: "Sill Height & Other Information",
        fields: [
            {
                element: 'select',
                label: 'Sill height above floor level (mm)',
                name: 'sill-height-above-floor-level',
                required: true,
                value: '600',
                mainContainerWidth: 12,
                width: 4,
                options: [
                    {id: 'Standard', label: 'Standard'},
                    {id: '600', label: '600'},
                    {id: '900', label: '900'}
                ],
            },
        ]
    },
    {
        groupName: "Fire exit doors",
        fields: [
            {
                element: 'select',
                label: 'Fire exits door in SM scope',
                name: 'fireExitsDoorInSMScope',
                mainContainerWidth: 12,
                width: 4,
                disableField: "fire_exit_door_plus_main_entry_count",
                disableOnValue: "No",
                value: 'Yes',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ],
            },
            {
                element: 'input',
                label: 'No of fire exit door/ Including main Entry',
                name: 'fire_exit_door_plus_main_entry_count',
                required: true,
                disableOn: true,
                type: 'number',
                mainContainerWidth: 12,
                width: 4,
            },
        ]
    },
    {
        groupName: "SEZ Zone",
        fields: [
            {
                element: 'select',
                label: 'SEZ Zone',
                name: 'sez-zone',
                mainContainerWidth: 12,
                width: 4,
                value: 'NO',
                options: [
                    {id: 'YES', label: 'YES'},
                    {id: 'NO', label: 'NO'},
                ],
            },
        ]
    },
    {
        groupName: "Chairs & Furnitures",
        fields: [
            {
                element: 'select',
                label: 'Any Specific Make for Chairs',
                name: 'any-specific-make-for-chairs',
                required: true,
                value: 'Imported Brand',
                mainContainerWidth: 12,
                width: 4,
                options: [
                    {id: 'Local Brand', label: 'Local Brand'},
                    {id: 'Imported Brand', label: 'Imported Brand'},
                ],
            },
            /*{
                element: 'input',
                label: 'Task Chair Rate',
                name: 'taskChairs',
                required: true,
                value: '',
                mainContainerWidth: 12,
                width: 4
            },
            {
                element: 'input',
                label: 'Meeting Rooms Chair Rate',
                name: 'meetingRoomsChairs',
                required: true,
                value: '',
                mainContainerWidth: 12,
                width: 4
            },
            {
                element: 'input',
                label: 'Cabin Head Chair Rate',
                name: 'cabinHeadChairs',
                required: true,
                value: '',
                mainContainerWidth: 12,
                width: 4
            },
            {
                element: 'input',
                label: 'Cabin Visitor Chair Rate',
                name: 'cabinVisitorChairs',
                required: true,
                value: '',
                mainContainerWidth: 12,
                width: 4
            },
            {
                element: 'input',
                label: 'Board room chair Rate',
                name: 'boardRoomChairs',
                required: true,
                value: '',
                mainContainerWidth: 12,
                width: 4
            },
            {
                element: 'input',
                label: 'CFO/CEO/MD cabin Head Chair Rate',
                name: 'cfoMDHeadChairs',
                required: true,
                value: '',
                mainContainerWidth: 12,
                width: 4
            },
            {
                element: 'input',
                label: 'Training Room Chair Rate',
                name: 'trainingRoomChairs',
                required: true,
                value: '',
                mainContainerWidth: 12,
                width: 4
            },*/
        ]
    },
    {
        groupName: "Measurable Arm",
        fields: [
            {
                element: 'input',
                label: 'Quantity Buffer in measurable items (%)',
                name: 'quantity-buffer-in-measurable-items',
                required: true,
                value: 3,
                type: 'number',
                width: 4,
                mainContainerWidth: 12
            },
        ]
    },
    /*{
        groupName: "Chair Quantity Buffer",
        fields: [
            {
                element: 'input',
                label: 'Chair Quantity Buffer (%)',
                name: 'chairs',
                required: true,
                value: 15,
                type: 'number',
                width: 4,
                mainContainerWidth: 12
            },
        ]
    }*/
];

export default CiPlusCommonTab;
