import {getFormattedDate} from "../../utils/Common";

export const itemCategoryPageConfig = {
    "columns": [
        {field: 'name', headerName: 'Name', headerClassName: 'data-grid-header', flex: 1},
        {field: 'code', headerName: 'Code', headerClassName: 'data-grid-header', flex: 1},
        {field: 'totalRcSkus', headerName: 'Total RC Skus', headerClassName: 'data-grid-header', flex: 1, maxWidth: 200},
        {field: 'totalSkus', headerName: 'Total Skus', headerClassName: 'data-grid-header', flex: 1, maxWidth: 200},
        {
            field: 'createdAt',
            headerName: 'Created On',
            headerClassName: 'data-grid-header',
            flex: 1,
            valueGetter: (params: any) => getFormattedDate(params.row?.createdAt)
        },
        {field: 'status', type: 'status', headerName: 'Active?', headerClassName: 'data-grid-header', flex: 1},
    ]
}